import styled from '@mui/material/styles/styled'

export const FooterContainer = styled('footer')({
  justifyContent: '',
  padding: '10px',
  backgroundColor: '#0088CC',
  color: '#ffffff',
  bottom: 0,
  width: '100%',
  left: 0
})


export const FooterContainerSecond = styled('footer')({
  justifyContent: '',
  backgroundColor: '#ffffff',
  color: '#ffffff',
  bottom: 0,
  width: '100%',
  left: 0
})