import type { FC } from 'react'
import { createContext, useEffect, useRef, useState } from 'react'
import '../Common.scss'
import { useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import { useOdds } from '../useOdds/UseOdds'
import { tableIdtoUrl, titleById, vCasinotitleById } from '../Constant/Constant'
import CasinoModal from '../CasinoBetSlip/Modal/CasinoModal'
import Video from '../Video/Video'
import T20 from '../T20/T20'
import AndarBaharKarna from '../andarBahar/AndarBaharKarna'
import DT20 from '../DT20/DT20'
import Aaa from '../aaa/Aaa'
import BTable from '../bollywoodTable/Btable'
import {
  Box,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Luck7B from '../Luck7B/Luck7B'
import DTL20 from '../DTL20/DTL20'
import snackBarUtil from '../../utils/snackbar'
import CasinoHeading from '../CasinoHeading/CasinoHeading'
import Mybet from '../LiveTennPatti/Mybet/Mybet'
import CasinoBetPlace from '../LiveTennPatti/CasinoBetPlace'
import LastResult from '../LastResult/LastResult'
import { useIpQuery } from '../../services/global'
import { useGetCasinoBetPlacedMutation } from '../../app/apis/casinoApi/casinoApiSlice'
import VideoTwo from '../Video/VideoTwo'
import LoadingSpinner from '../../component/common/Loading'
import GameDetailTopTabs from '../../component/common/GameDetail/GameDetailTopTabs'
import DisconnectionMouse from '../../component/common/DisconnectionMouse'

const style = {
  position: 'absolute' as 'absolute',
  top: '24%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  // border: '2px solid #fff',
  boxShadow: 24,
}
const style1 = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  // border: '2px solid #fff',
  boxShadow: 24,
}

interface Props {
  // getUserBalance: () => void;
}

export const globalContext = createContext({
  matchId: '',
  betDetails: null,
  setBetDetails: null,
  setBetPlace: null,
})

const CasinoMainPage: FC<Props> = () => {
  const divRef = useRef<any>(null)
  const [openModals, setOpenModals] = useState(false)
  const theme = useTheme()
  const [openRulesModal, setOpenRulesModal] = useState(false)
  const [showBetSection, setShowBetSection] = useState(false)
  const [updated, setUpdated] = useState(0)
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) // Matches xs and down
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md')) // Matches sm to md
  const isLarge = useMediaQuery(theme.breakpoints.up('md')) // Matches md and up
  const [value, setValue] = useState(0)

  const { id, tableId } = useParams<string>()
  if (!id) {
    return
  }
  const { odds } = useOdds(tableIdtoUrl[id])
  const t1 = odds?.t1?.[0]
  var curr = new Date()
  curr.setDate(curr.getDate() + 3)
  console.log(odds, 'kkkkk')
  const pTime = moment(curr).format('YYYY-MM-DD HH:mm:ss.SSS')
  const [casinoname, setCasinoName] = useState(0)

  const [betState, setBetState] = useState({
    nation: '',
    casinoName: 0,
    isBack: true,
    odds: null,
    marketId: '',
    placeTime: pTime,
    selectionId: null,
    colorName: '',
    stake: 0,
    matchId: tableId,
    deviceInfo: {
      userAgent:
        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36',
      browser: 'Chrome',
      device: 'Macintosh',
      deviceType: 'desktop',
      os: 'Windows',
      os_version: 'windows-10',
      browser_version: '108.0.0.0',
      orientation: 'landscape',
    },
  })

  const handleClose = () => {
    setOpenModals(false)
    setBetState((prev) => ({
      ...prev,
      stake: 0,
    }))
  }

  const [trigger, { data, isLoading }] = useGetCasinoBetPlacedMutation()

  const { data: userIp } = useIpQuery()

  const { pathname } = useLocation()

  useEffect(() => {
    setShowBetSection(false)
  }, [pathname])

  useEffect(() => {
    if (!divRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      window.parent?.postMessage(
        {
          type: 'height',
          message: divRef.current?.clientHeight,
        },
        '*'
      )
    })
    resizeObserver.observe(divRef.current)
    return () => resizeObserver.disconnect()
  }, [divRef])

  useEffect(() => {
    setBetState((prev) => ({
      ...prev,
      marketId: t1?.mid || odds?.data?.t1[0]?.mid,
    }))
  }, [t1?.mid])

  useEffect(() => {
    if (data) {
      // getUserBalance();
      if (!data?.status) {
        snackBarUtil.error(data?.message)
      } else {
        snackBarUtil.success(data?.message)
      }
    }
  }, [data])


  const [isInactive, setIsInactive] = useState(false)

  useEffect(() => {
    let timeoutId: any

    const resetTimer = () => {
      setIsInactive(false)
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setIsInactive(true)
      }, 30000)
    }

    const handleMouseMove = () => {
      resetTimer()
    }

    window.addEventListener('mousemove', handleMouseMove)

    resetTimer()

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <>
      <CasinoModal
        size="sm"
        title="Rules"
        handleClose={() => setOpenRulesModal(false)}
        open={openRulesModal}
      >
        <img
          width="100%"
          className="rules_image"
          src={`/img/${id}-rules.jpg`}
          alt=""
        />
      </CasinoModal>

      <div className="mob-view-casino"></div>
      {isLoading && <LoadingSpinner />}

      <Grid container columnSpacing={1}>
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid
              item
              sx={{
                background: '#2c3e50',
                width: '100%',
                height: '30px',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: '5px 10px',
              }}
            >
              <Typography sx={{ fontSize: '12px' }} component={'span'}>
                {titleById[id]}
              </Typography>
              {!isSmall && (
                <Typography sx={{ fontSize: '12px' }} component={'span'}>
                  RoundId: {t1?.mid}
                </Typography>
              )}
            </Grid>
          </Grid>
          {!isLarge && (
            <GameDetailTopTabs
              value={value}
              setValue={setValue}
              tableId={tableId}
              roundid={t1?.mid}
            />
          )}
          {value === 0 && (
            <div
              className={`col-md-9 featured-box-detail sports-wrapper m-b-10`}
            >
              <div className="app_container">
                <div>
                  {/* <CasinoHeading
                        HeadingName={{
                          // name: `${titleById[id]}`,
                          roundId: ` ${t1?.mid}`,
                        }}
                        vCasinoHeading={{
                          vcasinoname: `${vCasinotitleById[id]}`,
                          roundId: `${odds?.data?.t1[0]?.mid}`,
                        }}
                        id={id}
                      /> */}

                  {/* {odds?.t1 && (
                        <Video
                          t3={odds && odds?.length !== 0 && odds.t3}
                          t1={odds && odds?.length !== 0 && odds?.t1?.[0]}
                        />
                      )} */}

                  {id == '71' ||
                  id == '72' ||
                  id == '73' ||
                  id == '74' ||
                  id == '75' ||
                  id == '76' ||
                  id == '77' ||
                  odds?.data?.t1 ? (
                    <VideoTwo
                      t1={odds && odds?.length !== 0 && odds?.data?.t1?.[0]}
                    />
                  ) : (
                    <>
                      {odds?.t1 && (
                        <Video
                          t3={odds && odds?.length !== 0 && odds.t3}
                          t1={odds && odds?.length !== 0 && odds?.t1?.[0]}
                        />
                      )}
                    </>
                  )}

                  {id == '51' && odds && odds?.length !== 0 && (
                    <T20
                      setOpen={setOpenModals}
                      setShowBetSection={setShowBetSection}
                      t1={t1}
                      odds={odds}
                      setBetState={setBetState}
                      setUpdated={setUpdated}
                      showPnl={true}
                    />
                  )}
                  {id === '60' && odds && odds?.length !== 0 && (
                    <AndarBaharKarna
                      setOpen={setOpenModals}
                      setShowBetSection={setShowBetSection}
                      setBetState={setBetState}
                      odds={odds}
                      setUpdated={setUpdated}
                    />
                  )}
                </div>

                {id == '52' && odds && odds?.length !== 0 && (
                  <DT20
                    setShowBetSection={setShowBetSection}
                    setBetState={setBetState}
                    odds={odds}
                    setOpen={setOpenModals}
                    setUpdated={setUpdated}
                  />
                )}
                {id === '54' && odds && odds?.length !== 0 && (
                  <Aaa
                    setShowBetSection={setShowBetSection}
                    setBetState={setBetState}
                    odds={odds}
                    setOpen={setOpenModals}
                    setUpdated={setUpdated}
                  />
                )}
                {id === '55' && odds && odds?.length !== 0 && (
                  <BTable
                    setShowBetSection={setShowBetSection}
                    setBetState={setBetState}
                    odds={odds}
                    setOpen={setOpenModals}
                    setUpdated={setUpdated}
                  />
                )}
                {id === '53' && odds && odds?.length !== 0 && (
                  <Luck7B
                    setShowBetSection={setShowBetSection}
                    setBetState={setBetState}
                    odds={odds}
                    setOpen={setOpenModals}
                    setUpdated={setUpdated}
                  />
                )}
                {id === '61' && odds && odds?.length !== 0 && (
                  <DTL20
                    setShowBetSection={setShowBetSection}
                    setBetState={setBetState}
                    odds={odds}
                    setOpen={setOpenModals}
                    setUpdated={setUpdated}
                  />
                )}

                {id == '71' && odds && odds?.length !== 0 && (
                  <T20
                    setOpen={setOpenModals}
                    setShowBetSection={setShowBetSection}
                    t1={t1}
                    odds={odds}
                    setBetState={setBetState}
                    setUpdated={setUpdated}
                    showPnl={true}
                  />
                )}
                {id == '72' && odds && odds?.length !== 0 && (
                  <AndarBaharKarna
                    setOpen={setOpenModals}
                    setShowBetSection={setShowBetSection}
                    setBetState={setBetState}
                    odds={odds}
                    setUpdated={setUpdated}
                  />
                )}

                {id == '73' && odds && odds?.length !== 0 && (
                  <DT20
                    id={id}
                    setOpen={setOpenModals}
                    setShowBetSection={setShowBetSection}
                    t1={t1}
                    odds={odds}
                    setBetState={setBetState}
                    setUpdated={setUpdated}
                    showPnl={true}
                  />
                )}

                {id == '74' && odds && odds?.length !== 0 && (
                  <Aaa
                    setShowBetSection={setShowBetSection}
                    setBetState={setBetState}
                    odds={odds}
                    setOpen={setOpenModals}
                    setUpdated={setUpdated}
                  />
                )}

                {id === '75' && odds && odds?.length !== 0 && (
                  <BTable
                    setShowBetSection={setShowBetSection}
                    setBetState={setBetState}
                    odds={odds}
                    setOpen={setOpenModals}
                    setUpdated={setUpdated}
                  />
                )}

                {id === '76' && odds && odds?.length !== 0 && (
                  <DTL20
                    setShowBetSection={setShowBetSection}
                    setBetState={setBetState}
                    odds={odds}
                    setOpen={setOpenModals}
                    setUpdated={setUpdated}
                  />
                )}

                {id === '77' && odds && odds?.length !== 0 && (
                  <Luck7B
                    setShowBetSection={setShowBetSection}
                    setBetState={setBetState}
                    odds={odds}
                    setOpen={setOpenModals}
                    setUpdated={setUpdated}
                  />
                )}

                <div className="mt-2">
                  {/* <LastResult matchId={t1?.mid[1]} /> */}
                  <LastResult
                    matchId={t1?.mid[1]}
                    casinoName={tableIdtoUrl[id]}
                  />

                  {/* {id === "51" && <T20Rule />} */}
                </div>
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={
              {
                // marginTop: '-20px',
              }
            }
          >
            {isLarge && <Mybet tableId={tableId} />}
          </Box>
        </Grid>
      </Grid>

      <div
        id="sidebar-right"
        className="col-md-3 sidebar-right desk-view-casino"
        style={{ position: 'relative', top: 0, right: 0, width: '25.5%' }}
      ></div>
      <Modal
        open={openModals}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="casino_betmodals">
          <CasinoBetPlace
            handleClose={handleClose}
            betState={betState}
            setBetState={setBetState}
            userIp={userIp?.ip}
            setOpenModals={setOpenModals}
            trigger={trigger}
            data={data}
            isLoading={isLoading}
          />
        </Box>
      </Modal>

      <Modal
        open={isInactive}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} className="casino_betmodals1">
          <DisconnectionMouse setOpenModals={setIsInactive} />
        </Box>
      </Modal>
    </>
  )
}

export default CasinoMainPage
