import { Button, Typography, DialogActions } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const RuleTypography = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontFamily: 'Roboto condensed',
  fontSize: '12px',
  color: '#2c3e50',
  textAlign: 'left',
  fontWeight: 600,
  marginBottom: '16px',

  span: {
    fontSize: '12px',
    marginRight: '5px',
    fontWeight: 600,
    color: '#2c3e50',
  },
})

export const MuiDialogActions = styled(DialogActions)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  marginTop: '10px',
})

export const RuleButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0',
  fontSize: '14px',
  textTransform: 'capitalize',
  backgroundColor: '#0088cc',
  padding: '4px 40px',
  fontFamily: 'Roboto Condensed',
})
