import { Box, Button, TextField, Typography } from '@mui/material'
import styled from '@mui/material/styles/styled'
import { theme } from '../../theme'

export const CustomTypoParent = styled(Box)({
    backgroundColor: theme.palette.primary.main,
    padding: '8px 15px',
    textAlign: 'left',
})

export const CustomTypography = styled(Typography)({
    fontSize: '16px', color: '#fff', textTransform: 'capitalize'
})

export const CustomButton = styled(Button)({
    backgroundColor: theme.palette.dark.main,
    color: '#fff',
    borderRadius: 0,
    width: '100%',
    '&:hover': {
        backgroundColor: theme.palette.dark.main
    }
})

export const CustomTextFieldLeft = styled(TextField)({
    width: '100%',

    '& .MuiInputBase-root': {
        borderRadius: 0
    }
})
