import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FaTrophy } from 'react-icons/fa'
import { result } from '../../services/types'
import CardComp from './CardComp'

interface props {
    casinoResultModal: result | null
}
const Virtualcasinobollywoodmodal = ({
    casinoResultModal,
}:
    props) => {
    const [trueTrue, setTrue] = useState(false)
    useEffect(() => {
        setTrue(true)
    }, [])
    return (
        <Box sx={{ mt: 3 }}>
            <Box
                p={5}
                textAlign={'center'}
                sx={{
                    transform: 'scale(1.5)',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <CardComp shown={trueTrue} card={casinoResultModal?.C1 || ''} />
            </Box>
            <Typography sx={{ marginBottom: '16px' }}>
                <Typography component={'b'} color={'#0dce84'} sx={{ fontSize: '12px', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Result: <Typography color="#0dce84" component='p' sx={{ marginLeft: '3px', fontSize: '12px', fontWeight: '600' }}>
                        {casinoResultModal?.winner == 1 && 'Don'}
                        {casinoResultModal?.winner == 2 && 'Amar Akbar Anthony'}
                        {casinoResultModal?.winner == 3 && 'Sahib Bibi Aur Ghulam'}
                        {casinoResultModal?.winner == 4 && 'Dharam Veer'}
                        {casinoResultModal?.winner == 5 && 'Kis KisKo Pyaar Karoon'}
                        {casinoResultModal?.winner == 6 && 'Ghulam'}
                    </Typography>
                </Typography>
                <Typography component={'b'} color={'#0dce84'} sx={{ fontSize: '12px', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography component='p' sx={{ marginLeft: '3px', fontSize: '12px', color: '#000', fontWeight: '600' }}>
                        {casinoResultModal?.detail}
                    </Typography>
                </Typography>
            </Typography>
        </Box >
    )
}

export default Virtualcasinobollywoodmodal
