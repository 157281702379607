import { Box, FormControl, FormHelperText, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Pagination from '../../component/common/Pagination/Pagination'
import { useGetallactivecasinoApiQuery, useLazyCasinoResultApiQuery } from '../../app/apis/mainApi/mainApiSlice'
// import { useGetallactivecasinoApiQuery, useLazyCasinoResultApiQuery } from '../../services/casinoresult'
import { CustomButton, CustomTextFieldLeft, CustomTypography, CustomTypoParent } from './casinoresult.styled'
import { useLocation } from 'react-router'
import VirtualCasinoTabs from '../VirtualCasinoPage/VirtualCasinoTabs'

const CasinoResult = () => {
    const { state } = useLocation()



    const [allresult, setAllResult] = useState(5041)
    // const [defaultactive, setDefaultActive] = useState(0)
    const [dateChange, setDateChange] = useState(moment().format("YYYY-MM-DD"))
    const [limit, setLimit] = useState(20);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState<any>([]);

    const handlerChange = (e: any) => {
        setAllResult(e.target.value)
    }

    const handlerDateChange = (e: any) => {
        setDateChange(e.target.value)
    }

    const handlerLimitChange = (e: any) => {
        setCurrentPage(1);
        setLimit(e.target.value);
    }



    const { data: casinoresultall, } = useGetallactivecasinoApiQuery('all');

    const [trigger, { data: casinoresult, }] = useLazyCasinoResultApiQuery()


    // useEffect(() => {
    //     if (casinoresultall?.data?.[0]?.eventid) {
    //         setAllResult(casinoresultall?.data[0].eventid.toString())
    //     }

    // }, [casinoresultall])

    useEffect(() => {
        trigger({
            casinoId: allresult,
            eventDate: dateChange
        })
    }, [casinoresult])

    useEffect(() => {
        if (casinoresult?.data?.length) setTotalPages(Math.ceil(casinoresult.data.length / limit));
    }, [casinoresult, limit]);

    useEffect(() => {
        if (casinoresult?.data?.length) setCurrentData(casinoresult.data.slice(limit * (currentPage - 1), limit * (currentPage)));
    }, [casinoresult, totalPages, currentPage]);


    useEffect(() => {
        if (state?.id) {
            setAllResult(state?.id.toString())

        }

    }, [state])

    return (
        <>
            {/* <VirtualCasinoTabs /> */}
            <Box sx={{ padding: '5px' }}>
                <CustomTypoParent>
                    <CustomTypography>
                        casino result
                    </CustomTypography>
                </CustomTypoParent>

                <Grid container sx={{ margin: '10px 0px 10px' }} gap={{ md: 2, xs: 1 }} >
                    <Grid item xs={5.8} md={5.9} >
                        <CustomTextFieldLeft value={dateChange} onChange={handlerDateChange} type='date' fullWidth size="small" InputLabelProps={{ sx: { color: "#000 !important" } }} />
                    </Grid>
                    <Grid item xs={5.8} md={5.9}  >
                        <Select value={allresult} onChange={handlerChange} size="medium" sx={{ width: '100%', height: '40px', textAlign: 'left', borderRadius: 0 }}>
                            {casinoresultall?.data?.map((resultall) => (
                                <MenuItem value={resultall?.eventid} sx={{ color: '#000' }} key={resultall?.id}>
                                    {resultall?.eventname}
                                </MenuItem>
                            ))}

                        </Select>
                    </Grid>
                </Grid>

                <CustomButton onClick={() => trigger({ eventDate: dateChange, casinoId: allresult })}>
                    submit
                </CustomButton>

                <FormControl sx={{ my: 1, minWidth: 120, width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <FormHelperText sx={{ textAlign: 'left', m: 0 }}>
                        Show
                    </FormHelperText>
                    <Select
                        sx={{ borderRadius: 0, fontSize: '0.875rem' }}
                        size='small'
                        value={limit}
                        onChange={handlerLimitChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value={20} sx={{ padding: '20px' }}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                    </Select>
                    <FormHelperText sx={{ m: 0, textAlign: 'left' }}>entries</FormHelperText>
                </FormControl>

                <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '10px', borderRadius: 0 }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table" >
                            <TableHead>
                                <TableRow sx={{ borderRadius: 0 }}>
                                    <TableCell sx={{ width: '200px', borderRight: '1px solid #ccc', backgroundColor: '#0088cc', color: '#fff', padding: '8px 10px', whiteSpace: 'nowrap', textAlign: 'left' }}>
                                        <Typography component='span' sx={{ textTransform: 'capitalize', fontWeight: 600, fontSize: '14px' }}>
                                            round ID
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#0088cc', color: '#fff', padding: '8px 10px', whiteSpace: 'nowrap', textAlign: 'left' }}>
                                        <Typography component='span' sx={{ textTransform: 'capitalize', fontWeight: 600, fontSize: '14px' }}>
                                            winner
                                        </Typography>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentData.length > 0 && currentData.map((casinoresultdata: any, index: any) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index + 1} >
                                        <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'left', color: '#000', padding: '8px 10px', whiteSpace: 'nowrap' }}>
                                            <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                {casinoresultdata?.mid}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'left', color: '#000', padding: '8px 10px', whiteSpace: 'nowrap' }}>
                                            <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                {casinoresultdata?.winner}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Pagination
                    current={currentPage}
                    total={totalPages}
                    next={() => setCurrentPage(currentPage + 1)}
                    prev={() => setCurrentPage(currentPage - 1)}
                    first={() => setCurrentPage(1)}
                    last={() => setCurrentPage(totalPages)}
                />
            </Box>
        </>

    )
}

export default CasinoResult