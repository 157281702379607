import './video.scss'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CardOnVideo from '../CardOnVideo/CardOnVideo'
import SingleCardOnVideo from '../aaa/SingleCardOnVideo'
import AndarBaharCardOnVideo from '../andarBahar/AndarBaharCardOnVideo'
import Card2OnVideo from '../DT20/Card2OnVideo/Card2OnVideo'

const VideoTwo = ({ t1, t3 }: any) => {
  const { id, tableId } = useParams<any>()
  const [first, setFirst] = useState(0)

  if (!id) {
    return
  }

  console.log(t1, 'lkjfglkajflkg')

  useEffect(() => {
    if (first === 0 && Number(t1?.autotime)) {
      setFirst(new Date().getTime() + Number(t1?.autotime) * 1000)
    } else if (t1?.autotime == '0' && first !== 0) {
      setFirst(0)
    }
    return () => {}
  }, [t1?.autotime])

  console.log(id, '3e23eqedqwdw')

  const imgData: any = {
    '71': '/vcasinoimg/l20.png',
    '72': '/vcasinoimg/ab.png',
    '73': '/vcasinoimg/dt.png',
    '74': '/vcasinoimg/aaa.png',
    '75': '/vcasinoimg/bwdc.png',
    '76': '/vcasinoimg/dtl.png',
    '77': '/vcasinoimg/l7a.png',
  }

  const cardOnVideoById: any = {
    '71': <CardOnVideo t1={t1} />,
    '72': <Card2OnVideo t1={t1} />,
    '73': <SingleCardOnVideo t1={t1} />,
    '74': <AndarBaharCardOnVideo t3={t3} />,
    '75': <Card2OnVideo t1={t1} />,
    '76': <SingleCardOnVideo t1={t1} />,
    '77': <SingleCardOnVideo t1={t1} />,
    '57': <CardOnVideo t1={t1} />,
  }

  return (
    <div className="video_block_container">
      <div className="video_iframe_container">
        {(id == '71' ||
          id == '72' ||
          id == '73' ||
          id == '74' ||
          id == '75' ||
          id == '76' ||
          id == '77') && (
          <img
            src={imgData[id]}
            alt=""
            style={{
              width: '100%',
              height: '200px',
              objectFit: 'contain',
            }}
          />
        )}
      </div>
      <p className="auto_Time">{t1?.autotime}</p>

      {t1 && id && cardOnVideoById[id]}
    </div>
  )
}

export default VideoTwo
