import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useHomePageApiQuery } from '../../../app/apis/oddsApi/oddsApiSlice'
import LoadingSpinner from '../Loading'
import './upcoming.css'
import { useNavigate } from 'react-router'

const UpComingMatch = () => {
  const { data: activematch, isLoading } = useHomePageApiQuery('4')
  const navigate = useNavigate()

  const handlerNavigate = (routetopage: string) => {
    navigate(routetopage)
  }

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) // Matches xs and down
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md')) // Matches sm to md
  const isLarge = useMediaQuery(theme.breakpoints.up('md')) // Matches md and up

  console.log(activematch, 'adslkfjalksdfjlasdf')
  return (
    <Box
      className="upcomingmatch-parent"
      sx={{
        backgroundColor: '#0088cc',
        display: 'flex',
        alignItems: 'center',
        overflowX: 'scroll',
        // justifyContent: 'space-between',
        // gap: '10px',
        px: 1,
      }}
    >
      {activematch?.data &&
        activematch?.data?.map(
          (match: any, idx) =>
            match?.inPlay === false && (
              <Button
                disableElevation
                disableFocusRipple
                onClick={() =>
                  handlerNavigate(`/gamedetail/${match?.matchId}?sportId=${4}`)
                }
                key={idx}
                sx={{
                  borderRadius: '0',
                  overflowX: 'hidden',
                  backgroundColor: '#2C3E50',
                  textWrap: 'nowrap',
                  color: '#fff',
                  minWidth: { xs: ' 170px' },
                  maxWidth: { md: '100%' },
                  width: { xs: '100%', md: 'auto' },
                  height: '100%',
                  m: { xs: '0 5px 5px 0' },
                  p: '5px',
                  '&:hover': {
                    backgroundColor: '#2C3E50',
                  },
                }}
              >
                <Typography
                  sx={{ ml: 2, mr: 1 }}
                  className="d-icon icon-4"
                  component="span"
                ></Typography>
                <Typography
                  component="span"
                  className="blink_me"
                  sx={{
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                >
                  {isSmall && match?.matchName?.length >= 15
                    ? match?.matchName.substr(0, 15) + '...'
                    : match?.matchName}
                </Typography>
              </Button>
            )
        )}
    </Box>
  )
}

export default UpComingMatch
