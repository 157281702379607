const allrules = [
    {
        sno: 1,
        text: ' If you not accept this agreement do not place any bet.'
    },
    {
        sno: 2,
        text: 'Cheating bets deleted automatically or manual. No Claim.'
    },
    {
        sno: 3,
        text: 'Our exchange decision is final and no claim on it.'
    },
    {
        sno: 4,
        text: 'If any case wrong rate has been given in fancy that particular bets will be cancelled.'
    },
    {
        sno: 5,
        text: ' We do not accept manual bet.'
    },
    {
        sno: 6,
        text: 'In case of cheating and betting in unfair rates we will cancel the bet even after settling.'
    },
    {
        sno: 7,
        text: ' Local fancy are based on Haar - Jeet.'
    },
    {
        sno: 8,
        text: 'In next men out fancy if player is injured particular fancy will be deleted.'
    },
    {
        sno: 9,
        text: 'Once our exchange give username and password its your responsibility to change a password. '
    },
    {
        sno: 10,
        text: ' Incomplete session will be cancelled but complete session will be settled.'
    },
    {
        sno: 11,
        text: ' In case of match abandoned, cancelled, no result etc. completed sessions will be settled.'
    },
    {
        sno: 12,
        text: '  In case of a Tie match, only match odds will be cancelled , completed sessions will be continue.'
    },
    {
        sno: 13,
        text: '  Advance Lambi 1\'st Inning Valid Only.'
    },
    {
        sno: 14,
        text: ' Total Match Four ,Total Match Sixes,Total Match Runs,Total Match Wides,Total Match Wicket. If Over Gets Less Then All BET Will Cancel This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game. '
    },
    {
        sno: 15,
        text: '  Batsman Runs (In-Play) Over/Under (back/lay) runs bets will stand after batsman has faced one ball or is given out before first ball is faced. Score counts if batsman is Not-Out including if innings is declared. In case of rain, match abandoned etc. settled bets will be valid. '
    },
    {
        sno: 16,
        text: ' Current/Next Innings Runs Odd/Even Extras and Penalty runs will be included for settlement purposes.'
    },
    {
        sno: 17,
        text: ' Runs at Fall of 1st Wicket At least one delivery must be bowled, if no wickets fall bets will be void unless settlement is already determined. '
    },
    {
        sno: 18,
        text: '  Runs at Fall of Next Wicket The total innings runs scored by a team before the fall of the specified wicket determines the result of the market. If a team declares or reaches their target then the total amassed will be the result of the market. Bets will be void should no more play take place following the intervention of rain, or any other delay, as the ability to reach previous quotes offered will have been removed . In case of rain, match abandoned etc. settled bets will be valid.'
    },
    {
        sno: 19,
        text: '  Lambi Paari : In 20-20 match entire twenty overs should be bowled, in case of rain or any delay if even one over is deducted the bets will be cancelled. In One Day match entire 50 overs should be bowled, in case of rain or any delay if even one over is deducted the bets will be cancelled. '
    },
    {
        sno: 20,
        text: ' 1st Over Total Runs Prices will be offered for the total runs scored during the 1st over of the match. Extras and penalty runs will be included. The over must be completed for bets to stand unless settlement is already determined.'
    },
    {
        sno: 21,
        text: ' Limited Overs Matches - Individual Batsmen Runs or Partnerships In a limited overs match where an individual batsman or partnership runs are traded in-play and the innings is curtailed or subject to any reduction in overs, then these markets will be settled at the midpoint of the last available quote before the overs were reduced. If the innings resumes at a later time, a new market may be formed. If a client wants a position in the new market they are required to place a new trade. If there are any subsequent reductions in overs, exactly the same rules will continue to apply i.e. the market is settled at the midpoint of the last available quote before the overs were reduced and a new market may be formed. '
    },
    {
        sno: 22,
        text: ' Test Matches - Individual Batsmen Runs / Partnerships All bets, open or closed, on an individual batsman or partnership runs shall be void if 50 full overs are not bowled unless one team has won, is dismissed or declares prior to that point. Bets on partnership totals make up when the next wicket falls. If a batsman in the relevant partnership retires hurt, the partnership is treated as continuing between the remaining batsman and the batsman who comes to the wicket. A partnership is also treated as being ended by the end of an innings.'
    },
    {
        sno: 23,
        text: '  (TEST MATCH) 1st Day Total Run markets will only be valid if a minimum of 80 overs are bowled on this day. Otherwise all bets in this market will be void. '
    },
    {
        sno: 24,
        text: ' Due to any technical issue software not work properly at that time we are not responsible for any loss. '
    },
]

export default allrules