import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { Box } from '@mui/system'
import { CircularProgress, Grid, styled, Typography } from '@mui/material'
import { ImCross } from 'react-icons/im'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import StackButton from './Button'
import snackBarUtil from '../../../utils/snackbar'
import {
  fancyBetPayload,
  FancyOddsInterface,
  gameDetailApiTypes,
  RunnerssData,
} from '../../../services/types'
import {
  useBookmarkerApiMutation,
  useOddsbetApiMutation,
  useUserStackQuery,
} from '../../../app/apis/mainApi/mainApiSlice'
import { useFancybetMutation } from '../../../app/apis/mainApi/mainApiSlice'
import { postNegValue } from '../../../utils/postNegVal'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
      width: '100%',
    },
  },
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
    marginTop: 0,
    maxWidth: 564,
    margin: 'auto',
    padding: 0,
  },
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />
})

interface props {
  placeBetTime: number
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  betDetailData: fancyBetPayload | null
  OddsBetFairApi: gameDetailApiTypes
  setBetDetailData: React.Dispatch<React.SetStateAction<fancyBetPayload | null>>
  runnersData: RunnerssData[]
  profit: any
  setProfits: any
  oddsPnl: any

}

const BetModal: React.FC<props> = ({
  open,
  setOpen,
  betDetailData,
  setBetDetailData,
  OddsBetFairApi,
  placeBetTime,
  runnersData,
  profit,
  setProfits,
  oddsPnl
}) => {
  const {
    isError: err,
    isLoading: loading,
    isSuccess: success,
    data: userstack,
  } = useUserStackQuery()

  const [marketName, setMarketName] = React.useState(betDetailData?.marketName)
  const [updated, setUpdated] = React.useState('')
  const [betVal, setBetVal] = React.useState('')

  const handleClose = () => {
    setOpen(false)
    setUpdated('')
    setBetVal('')
    setProfits(null)
    setBetDetailData(null)
  }

  const handleChange = (e: any) => {
    setBetVal(e.target.value)
    setBetDetailData((prev) => prev && { ...prev, stake: e.target.value })
  }

  const handlerOnSelect = (e: any) => {
    setBetVal(e)
    setUpdated(e)
    setBetDetailData((prev) => prev && { ...prev, stake: e, liability: e })
  }

  const [fancytrigger, { isError, isLoading, isSuccess, data: fancyBet }] =
    useFancybetMutation()
  const [oddstrigger, { data: oddsbetData, isLoading: isloaded }] =
    useOddsbetApiMutation()
  const [bookmakertrigger, { data: bookMakertrigger, isLoading: yesweloaded }] =
    useBookmarkerApiMutation()
  const getmessageWarn = (message: string, title: string) => {
    snackBarUtil.warning(
      <Box sx={{ textAlign: 'left' }}>
        <Typography sx={{ fontSize: '14px' }}>{title}</Typography>
        <Typography sx={{ fontSize: '12px' }}> {message}</Typography>
      </Box>
    )
  }

  // console.log(bookMakertrigger,'akdfjakjsd')

  const getmessagesucc = (message: string, title: string) => {
    snackBarUtil.success(
      <Box sx={{ textAlign: 'left' }}>
        <Typography sx={{ fontSize: '14px' }} component="h5" variant="h5">
          {title}
        </Typography>
        <Typography sx={{ fontSize: '12px' }} component="p" variant="subtitle1">
          {' '}
          {message}
        </Typography>
      </Box>
    )
  }


  const calcProfit = () => {
    if (betDetailData) {
      if (
        ['Fancy2Market', 'Fancy3Market', 'OddEven'].includes(
          betDetailData?.type
        )
      ) {
        return (
          ((betDetailData?.isBack ? 1 : -1) *
            (Number(betDetailData.stake) * Number(betDetailData.priceValue))) /
          100
        ).toFixed(2)
      } else if (['Bookmaker'].includes(betDetailData?.type)) {
        return (
          ((betDetailData?.isBack ? 1 : -1) *
            (Number(betDetailData.stake) * Number(betDetailData.odds))) /
          100
        ).toFixed(2)
      } else {
        return (
          Number(betDetailData.stake) *
          (Number(betDetailData.odds) - 1)
        ).toFixed(2)
      }
    }
  }


  // (
  //   (profit?.value || 0) +
  //   ((betDetailData?.isBack ? 1 : -1) *
  //     Number(betDetailData?.odds) *
  //     Number(updated)) /
  //     100
  // ).toFixed(2)

  // const oddsPnlObj:any = {
  //   [oddsPnl?.[0]?.selection1]: oddsPnl?.[0].pnl1,
  //   [oddsPnl?.[0]?.selection2]: oddsPnl?.[0].pnl2,
  //   [oddsPnl?.[0]?.selection3]: oddsPnl?.[0].pnl3,
  // }

  const calcLoss = (isSelected: boolean) => {
    if (betDetailData) {
    

      if (isSelected) {
        if (['Bookmaker'].includes(betDetailData?.type)) {
          return (
           (oddsPnl?.[betDetailData?.selectionId] || 0) +(betDetailData.isBack ? 1 : -1) *
            ((Number(betDetailData.stake) * Number(betDetailData?.odds)) / 100)
          )?.toFixed(2)
        } else {
          return (
            (betDetailData.isBack ? 1 : -1) *
            (Number(betDetailData.stake) * Number(betDetailData.odds) - 1)
          )?.toFixed(2)
        }
      } else {
        if (['Bookmaker'].includes(betDetailData?.type)) {
          return (
            (betDetailData.isBack ? -1 : 1) * Number(betDetailData?.stake)
          )?.toFixed(2)
        } else {
          return (
            // Number(betDetailData.odds) +
            (
              (betDetailData.isBack ? -1 : 1) * Number(betDetailData.stake)
            )?.toFixed(2)
          )
        }
      }
    }
  }


  const handlerSubmit = (e: any) => {
    setBetDetailData((prev) => prev && { ...prev, liability: e.target.value })
    if (betDetailData) {
      if (
        ['Fancy2Market', 'Fancy3Market', 'OddEven'].includes(
          betDetailData?.type
        )
      ) {
        fancytrigger(betDetailData)
      } else if (['Bookmaker'].includes(betDetailData?.type)) {
        bookmakertrigger(betDetailData)
      } else {
        oddstrigger(betDetailData)
      }
    }

    setOpen(false)
    setBetDetailData(null)
    setBetVal('')
    setUpdated('')
    setProfits(null)
  }

  React.useEffect(() => {
    if (oddsbetData?.responseCode === 201) {
      getmessageWarn(
        oddsbetData?.data?.message?.toString(),
        oddsbetData?.data?.title?.toString()
      )
    }

    if (oddsbetData?.responseCode === 200) {
      getmessagesucc(
        oddsbetData?.data?.message?.toString(),
        oddsbetData?.data?.title?.toString()
      )
    }
  }, [oddsbetData])

  React.useEffect(() => {
    if (bookMakertrigger?.responseCode === 201) {
      getmessageWarn(
        bookMakertrigger?.data?.message?.toString(),
        bookMakertrigger?.data?.title?.toString()
      )
    }

    if (bookMakertrigger?.responseCode === 200) {
      getmessagesucc(
        bookMakertrigger?.data?.message?.toString(),
        bookMakertrigger?.data?.title?.toString()
      )
    }
  }, [bookMakertrigger])

  React.useEffect(() => {
    if (fancyBet?.responseCode === 201) {
      getmessageWarn(
        fancyBet?.data?.message?.toString(),
        fancyBet?.data?.title?.toString()
      )
    }

    if (fancyBet?.responseCode === 200) {
      getmessagesucc(
        fancyBet?.data?.message?.toString(),
        fancyBet?.data?.title?.toString()
      )
    }
  }, [fancyBet])
  return (
    <Box sx={{ position: 'relative' }}>
      {isloaded || yesweloaded || isLoading ? (
        <Box
          sx={{
            backgroundColor: '#32323282',
            zIndex: '213487',
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            left: 0,
            top: 0,
            width: '100%',
            height: '100vh',
          }}
        >
          <CircularProgress
            sx={{ marginRight: 1 }}
            color="info"
            size={30}
            thickness={1.6}
          />
        </Box>
      ) : (
        ''
      )}
      <BootstrapDialog
        open={open}
        TransitionComponent={Transition}
        sx={{ overflow: 'hidden', width: '100%' }}
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            maxWidth: '500px',
            backgroundColor: 'dark.main',
            padding: 1.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              textTransform: 'capitalize',
              fontFamily: 'roboto condensed',
              fontSize: '18px',
              fontWeight: '600',
              color: '#fff',
            }}
          >
            place bet ({placeBetTime})
          </Typography>

          <ImCross
            size="16px"
            color="#fff"
            onClick={handleClose}
            style={{ cursor: 'pointer' }}
          />
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: betDetailData?.isBack ? 'back.sky' : 'lay.pink',
            padding: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                color: '#000',
                fontWeight: '600',
                maxWidth: '300px',
                textAlign: 'left',
              }}
            >
              {`${betDetailData?.selectionName} / ${
                marketName != 'Session' && betDetailData?.odds
              }`}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '28px',
                maxWidth: '134px',
                width: '100%',
                marginTop: '10px',
              }}
            >
              <AiOutlineMinus
                style={{
                  fontSize: '12px',
                  backgroundColor: '#0088cc',
                  height: '28px',
                  width: '28px',
                  padding: '4px',
                  color: '#fff',
                }}
              />
              <input
                type="text"
                readOnly
                value={betDetailData?.odds}
                style={{
                  padding: '6px 12px',
                  fontWeight: '500',
                  fontSize: '16px',
                  textAlign: 'center',
                  maxWidth: '80px',
                  width: '100%',
                  height: '100%',
                  outline: 'none',
                  border: '1px solid #ccc',
                }}
              />
              <AiOutlinePlus
                style={{
                  fontSize: '12px',
                  backgroundColor: '#0088cc',
                  height: '28px',
                  width: '28px',
                  padding: '4px',
                  color: '#fff',
                }}
              />
            </Box>
          </Box>
          <Box>
            <Box sx={{ marginBlock: '10px' }}>
              <Grid
                container
                gap={0.2}
                sx={{ justifyContent: 'space-between' }}
              >
                <Grid
                  item
                  xs={5.9}
                  sx={{
                    minHeight: '28px',
                    height: '100%',
                    padding: '4px 20px',
                    textTransform: 'capitalize',
                    backgroundColor: 'dark.main',
                    color: '#fff',
                    fontSize: '14px',
                    fontWeight: '600',
                    cursor: 'not-allowed',
                  }}
                >
                  Min Stack {betDetailData?.minValue}
                </Grid>
                <Grid
                  item
                  xs={5.9}
                  sx={{
                    minHeight: '28px',
                    height: '100%',
                    fontSize: '14px',
                    padding: '4px 20px',
                    color: '#fff',
                    textTransform: 'capitalize',
                    backgroundColor: 'dark.main',
                    fontWeight: '600',
                    cursor: 'not-allowed',
                  }}
                >
                  Max Stack {betDetailData?.maxValue}
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Grid container gap={1.5}>
              <Grid item xs={3.5}>
                <input
                  onChange={handleChange}
                  type="number"
                  value={betDetailData?.stake}
                  placeholder={'00'}
                  min="0"
                  style={{
                    padding: '6px 12px',
                    width: '100%',
                    fontWeight: '500',
                    fontSize: '16px',
                    textAlign: 'left',
                    outline: 'none',
                    border: '1px solid #ccc',
                  }}
                />
              </Grid>
              <Grid item xs={3.5}>
                <Button
                  onClick={handlerSubmit}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'dark.main',
                    },
                    textTransform: 'capitalize',
                    backgroundColor: 'dark.main',
                    borderRadius: '0',
                    color: '#fff',
                    fontSize: '14px',
                    padding: { md: '4px 40px', xs: '4px 20px' },
                  }}
                >
                  submit
                </Button>
              </Grid>
              <Grid item xs={3.5}>
                <Typography
                  component="div"
                  className="profit"
                  sx={{
                    color: '#000',
                    textAlign: 'center',
                    width: '100%',
                    height: '100%',
                    padding: '4px 5px 0px',
                  }}
                >
                  {!betDetailData?.isBack && Number(betVal) > 0 &&  Number(betVal) < 0 ? '-' : ''}
                  {calcProfit()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              marginBlock: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              xs={12}
              gap={1.5}
              sx={{ justifyContent: 'space-between' }}
            >
              {userstack?.data &&
                Object.values(userstack?.data)?.map((stackbtn, index) => (
                  <Grid item xs={3.6} md={3.8} key={index}>
                    <StackButton
                      stackbtn={stackbtn}
                      handlerOnSelect={handlerOnSelect}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
          <Box
            className="matchdata"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {!['Fancy2Market', 'Fancy3Market', 'OddEven'].includes(
              betDetailData?.type || ''
            ) && (
              <Grid container>
                {runnersData?.map((runnermatch: any, index: any) => {
                  console.log(oddsPnl,betDetailData?.selectionId, "oddsPnlObj?.[betDetailData?.selectionId]")
                  return (
                    <Grid container key={index + 1}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: 'dark.main',
                            textAlign: 'left',
                          }}
                        >
                          {runnermatch?.name || runnermatch?.nation}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          sx={{ fontSize: '12px', textAlign: 'left' }}
                        >
                          {runnermatch?.selectionId ===
                            betDetailData?.selectionId ||
                          runnermatch?.sid == betDetailData?.selectionId ? (
                            <Typography sx={{ fontSize: '12px'}}>
                              {oddsPnl && betDetailData && postNegValue(oddsPnl?.[betDetailData?.selectionId])}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                color: betDetailData?.isBack ? 'red' : 'green',
                                fontSize: '12px',
                              }}
                            >
                              {oddsPnl && betDetailData && postNegValue(oddsPnl?.[runnermatch?.selectionId]) || oddsPnl && betDetailData && postNegValue(oddsPnl?.[runnermatch?.sid])}
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          sx={{ fontSize: '12px', textAlign: 'left' }}
                        >
                          {runnermatch?.selectionId ===
                            betDetailData?.selectionId ||
                          runnermatch?.sid == betDetailData?.selectionId ? (
                            <Typography sx={{ fontSize: '12px', color: !betDetailData?.isBack ? 'red' : 'green', }}>
                              {/* {postNegValue()} */}
                              {!betDetailData?.isBack && Number(betVal) > 0 ? '-' : ''}
                             { calcProfit()}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                color: betDetailData?.isBack ? 'red' : 'green',
                                fontSize: '12px',
                              }}
                            >
                              {betDetailData?.isBack && Number(betVal) > 0 ? '-' : ''}
                              {betVal || 0}
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      
                    </Grid>
                  )
                })}
              </Grid>
            )}
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  )
}

export default BetModal
