import { CustomMenu } from './RightMenu.styled'
import { FaMoneyBill } from 'react-icons/fa'
import { theme } from '../../../theme'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
// import Home from '../../../screens/Home/Home'
// import { balanceApi } from '../../../services'
import { postNegValue } from '../../../utils/postNegVal'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { logout } from '../../../app/features/auth/authSlilce'
import { useBalanceApiQuery } from '../../../app/apis/mainApi/mainApiSlice'
import { balanceposneg } from '../../../utils/balanceCalculation'

interface RightMenuProps {
  handleOpenUserMenu: any
  MenuOpen: boolean
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const RightMenu = (props: RightMenuProps) => {
  const dispatch = useAppDispatch()
  const { handleOpenUserMenu, MenuOpen, setMenuOpen } = props
  const navigate = useNavigate()

  const handlerOnClick = () => {
    setMenuOpen(false)
  }

  const settings = [
    {
      title: 'home',
      func: () => {
        navigate('')
        handlerOnClick()
      },
    },
    {
      title: 'Account statement',
      func: () => {
        navigate('/accountstatement')
        handlerOnClick()
      },
    },
    {
      title: 'profit loss report',
      func: () => {
        navigate('/profitandloss')
        handlerOnClick()
      },
    },
    {
      title: 'bet history',
      func: () => {
        navigate('/bethistory')
        handlerOnClick()
      },
    },
    {
      title: 'unsetteled bet',
      func: () => {
        navigate('/Unsetledbet')
        handlerOnClick()
      },
    },
    {
      title: 'casino report history',
      func: () => {
        navigate('/casinoresult')
        handlerOnClick()
      },
    },
    {
      title: 'set button values',
      func: () => {
        navigate('/changebuttonValue')
        handlerOnClick()
      },
    },
    {
      title: 'rules',
      func: () => {
        navigate('/rules')
        handlerOnClick()
      },
    },
    {
      title: 'change password',
      func: () => {
        navigate('/changepassword')
        handlerOnClick()
      },
    },
  ]

  const signout = ['sign out']

  const handlerLogout = () => {
    dispatch(logout())
    handlerOnClick
  }

  const { data: balanceData } = useBalanceApiQuery(undefined, {
    pollingInterval: 500,
  })

  return (
    <>
      <CustomMenu>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6px 16px',
            maxWidth: '160px',
            width: '100%',
            borderBottom: '1px solid #ccc',
          }}
        >
          <Typography
            component="span"
            sx={{
              color: '#000',
              fontSize: '14px',
              fontWeight: '400',
              fontFamily: 'Roboto Condensed',
            }}
          >
            {'Pnl:'}
          </Typography>

          <Typography
            component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
              marginLeft: 0.5,
              fontWeight: '600',
              color: balanceposneg(balanceData?.data?.uplineAmount ?? ''),
            }}
            color={theme.palette.status.danger}
          >
            {balanceData?.data && (
              <FaMoneyBill
                style={{ marginRight: '5px' }}
                color={balanceData?.data?.uplineAmount < 0 ? 'red' : 'green'}
              />
            )}
            {balanceData?.data?.uplineAmount}
          </Typography>
        </Box>

        <Box>
          {settings.map((setting) => (
            <Typography key={setting.title}>
              <Button
                onClick={setting.func}
                variant="text"
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#cccccc77',
                  },
                  borderRadius: 0,
                  padding: ' 2px 0px 2px 10px',
                  textAlign: 'left',
                  fontSize: '16px',
                  fontFamily: 'Roboto Condensed',
                  textTransform: 'capitalize',
                  width: '100%',
                  display: 'block',
                  color: '#000',
                  fontWeight: '400',
                }}
              >
                {setting.title}
              </Button>
            </Typography>
          ))}

          <Box
            sx={{
              borderTop: '1px solid rgba(0,0,0,.15)',
              padding: '12px 12px 0',
            }}
          >
            {signout.map((item) => (
              <Typography
                onClick={handlerLogout}
                key={item}
                sx={{
                  '&:hover': {
                    backgroundColor: '#cccccc77',
                  },
                  fontFamily: 'Roboto Condensed',
                  cursor: 'pointer',
                  textAlign: 'left',
                  fontSize: '16px',
                  fontWeight: '400',
                  textTransform: 'capitalize',
                }}
              >
                {item}
              </Typography>
            ))}
          </Box>
        </Box>
      </CustomMenu>
    </>
  )
}

export default RightMenu
