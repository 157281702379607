import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { theme } from '../../../../theme'
import { StyledBox } from './LiveCasino.styled'
import { useCasinoListQuery } from '../../../../app/apis/casinoApi/casinoApiSlice'
import LoadingSpinner from '../../Loading'

const LiveCasino = () => {
  const { data, isLoading } = useCasinoListQuery({
    casinoType: 'LIVE',
  })
  const nav = useNavigate()

  const muitheme = useTheme()
  const isSmall = useMediaQuery(muitheme.breakpoints.down('sm')) // Matches xs and down
  const isMedium = useMediaQuery(muitheme.breakpoints.between('sm', 'md')) // Matches sm to md
  const isLarge = useMediaQuery(muitheme.breakpoints.up('md')) // Matches md and up

  // const [data, setData] = useState([])
  // const [gameid, setGameId] = useState('')

  // async function logJSONData() {
  //   const response = await fetch(
  //     'https://admin-api-banners-new.s3.ap-south-1.amazonaws.com/diamond.json'
  //   )
  //   const cardData = await response.json()
  //   setData(cardData?.data)
  // }

  // useEffect(() => {
  //   logJSONData()
  // }, [])

  // const handleNavigate = (sportid: string) => {
  //   console.log(sportid, 'ddddddd')
  //   nav(`/casino/:id/:${sportid}`)
  // }

  const handleNavigate = (item: any) => {
    const link = item?.name?.split(' ').join('').toLowerCase()
    const name: any = {
      teenpatti: 51,
      andarbahar: 60,
      dragontiger: 52,
      amarakbaranthony: 54,
      bollywoodcasino: 55,
      lucky7a: 53,
      dragontigerlion: 61,
    }
    if (link && name[link]) {
      nav(`/casino/${name[link]}/${item?.tableId}`)
    } else {
      console.error('Invalid item name or link')
    }
  }

  const {pathname} = useLocation()

  return (
    <>
      {isLoading ? <LoadingSpinner /> : ''}
      {data && (
        <Box mt={0} sx={{ width: '100%' }}>
          <StyledBox>
            {/* <Typography
              component="h4"
              fontSize="16px"
              fontWeight={600}
              textAlign={'left'}
              color={theme.palette.dark.main}
            >
              LIVE CASINO
            </Typography> */}

            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Grid
                container
                columnSpacing={{ md: 1, xs: 0.4 }}
                rowSpacing={{ md: 0, xs: 0.4 }}
              >
                {data?.data?.map((card, idx: number) => (
                  <Grid
                    key={idx}
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    onClick={() => handleNavigate(card)}
                    sx={{
                      position: 'relative',
                      marginInline: { md: '0px' },
                      padding: { xs: '1px', md: '0' },
                      cursor: 'pointer',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      position={'relative'}
                      sx={{
                        width: { xs: '100%', md: '100%' },
                        height: isSmall ? '70px' : '',
                      }}
                    >
                      <img
                        style={{
                          height: isSmall ? '100%' : '',
                          width: '100%',
                        }}
                        src={card?.image}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </StyledBox>
        </Box>
      )}
      {pathname == '' || pathname == '/livecasino' ? !data && (
        <Box
          sx={{
            minHeight: 'calc(100vh - 145px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '700',
              fontFamily: 'Roboto Condensed',
            }}
          >
            No Data Found
          </Typography>
        </Box>
      ):''}
    </>
  )
}

export default LiveCasino
