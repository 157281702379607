import { Box, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useGetBetListByMatchResQuery } from '../../app/apis/mdkalyanExchApi/mdkalyanExchApiSlice'

const VirtualMatchedBet = () => {
    const [searchparams, setSearchparams] = useSearchParams()
    const eventId = searchparams.get('id')

    const table_data = ['nation', 'market', 'odds', 'amount']
    const namecss = {
        textAlign: 'center',
        paddingInline: '8px',
        color: '#000',
        fontWeight: '600',
        fontSize: '13px',
        textTransform: 'capitalize',
        fontFamily: `Roboto Condensed, sans-serif`,
    }

    const mapdatacss = {
        fontSize: '12px',
        paddingInline: '8px',
        color: '#000',
        textTransform: 'capitalize',
        fontFamily: 'Roboto Condensed'
    }

    const datanotfound = {
        textAlign: 'center',
        // padding: '4px',
        fontSize: '12px',
        fontFamily: 'Roboto Condensed,sans-serif',
        color: '#212529',
        marginBlock: '2px',
        fontWeight: '600',
    }


    const { data: getBetlistmatch } = useGetBetListByMatchResQuery({ eventId: Number(eventId) });
    
    return (
        <Box>
            <Grid
                container
                xs={12}
                sx={{ marginTop: '5px', borderTop: '1px solid #000', borderBottom: '1px solid #323232' }}
            >
                {table_data.map((name: string, index) => (
                    <Grid
                        key={index + 1}
                        item
                        xs={3}
                        sx={{
                            borderLeft: '1px solid #000',
                            borderRight: name === 'amount' ? '1px solid #323232' : '',
                        }}
                    >
                        <Typography component="p" sx={{ ...namecss }}>
                            {name}
                        </Typography>
                    </Grid>
                ))}

            </Grid>
            <Grid container
                sx={{}}
            >
                {Array.isArray(getBetlistmatch?.data) && getBetlistmatch?.data?.map((betlistmatch, index) => (
                    <Grid container sx={{ backgroundColor: betlistmatch?.isback ? 'back.sky' : 'lay.pink', }}>
                        <Grid
                            key={index + 1}
                            item
                            xs={3}
                            sx={{
                                borderLeft: '1px solid #000',
                                borderBottom: '1px solid #000'
                            }}
                        >
                            <Typography component="p" sx={{ ...datanotfound }}>
                                {betlistmatch?.selectionname}
                            </Typography>
                        </Grid>
                        <Grid
                            key={index + 1}
                            item
                            xs={3}
                            sx={{
                                borderLeft: '1px solid #000',
                                borderRight: '1px solid #000',
                                borderBottom: '1px solid #000'
                            }}
                        >
                            <Typography component="p" sx={{ ...datanotfound }}>
                                {betlistmatch?.marketname}
                            </Typography>
                        </Grid>
                        <Grid
                            key={index + 1}
                            item
                            xs={3}
                            sx={{
                                borderRight: '1px solid #000',
                                borderBottom: '1px solid #000'
                            }}
                        >
                            <Typography component="p" sx={{ ...datanotfound }}>
                                {betlistmatch?.odds}
                            </Typography>
                        </Grid>
                        <Grid
                            key={index + 1}
                            item
                            xs={3}
                            sx={{
                                borderRight: '1px solid #000',
                                borderBottom: '1px solid #000'
                            }}
                        >
                            <Typography component="p" sx={{ ...datanotfound }}>
                                {betlistmatch?.stake}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}

                {!getBetlistmatch?.data && <Grid item xs={12} sx={{ border: '0.5px solid #ccc' }}>
                    <Typography component="p" sx={{ ...datanotfound }}>
                        No records Found
                    </Typography>
                </Grid>}
            </Grid>
        </Box >
    )
}

export default VirtualMatchedBet
