import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { TransitionProps } from '@mui/material/transitions'
import Slide from '@mui/material/Slide'
import { ImCross } from 'react-icons/im'
import { Box, Grid } from '@mui/material'
import { useGetBetListByMarketApiQuery } from '../../app/apis/mdkalyanExchApi/mdkalyanExchApiSlice'
import { postNegValue } from '../../utils/postNegVal'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
      width: '100%',
    },
  },
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
    marginTop: 0,
    maxWidth: 500,
    margin: 'auto',
    padding: 0,
  },
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />
})

interface Props {
  handleClose: () => void
  open: boolean
  marketId: string | number
}

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const AccountBetListModal: React.FC<Props> = ({
  open,
  handleClose,
  marketId,
}) => {
  const userData = localStorage.getItem('data')
  let parseval = userData && JSON.parse(userData)

  const { data: betlistbymarket } = useGetBetListByMarketApiQuery({
    userId: parseval.userid,
    marketId,
  })

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            maxWidth: '500px',
            backgroundColor: 'dark.main',
            padding: 1.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              whiteSpace: { md: 'nowrap', xs: '' },
              textAlign: 'left',
              textTransform: 'capitalize',
              fontFamily: 'roboto condensed',
              fontSize: '18px',
              fontWeight: '600',
              color: '#fff',
            }}
          >
            {`Bet List (${betlistbymarket?.data?.[0]?.matchname}/${betlistbymarket?.data?.[0].type}[${betlistbymarket?.data?.[0].resultname}])`}
          </Typography>

          <ImCross
            size="16px"
            color="#fff"
            onClick={handleClose}
            style={{ cursor: 'pointer' }}
          />
        </DialogTitle>
        <DialogContent dividers sx={{ padding: '10px 3px' }}>
          <Box>
            {betlistbymarket &&
              betlistbymarket?.data?.map((listData, index) => (
                <Grid
                  container
                  key={index + 1}
                  sx={{
                    backgroundColor:
                      listData?.isback === true ? 'back.sky' : 'lay.pink',
                    alignItems: 'flex-start',
                    padding: '5px',
                  }}
                >
                  <Grid item xs={4}>
                    <Box>
                      <Typography
                        component="p"
                        sx={{
                          fontWeight: 600,
                          textTransform: 'capitalize',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                      >
                        {listData?.matchname}
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          textTransform: 'capitalize',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                      >
                        <Typography
                          component="span"
                          sx={{ fontWeight: 600, fontSize: '12px' }}
                        >
                          Nation:
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            textTransform: 'capitalize',
                            fontSize: '12px',
                            textAlign: 'left',
                          }}
                        >
                          {listData?.selectionname}
                        </Typography>
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          textTransform: 'capitalize',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                      >
                        <Typography
                          component="span"
                          sx={{ fontWeight: 600, fontSize: '12px' }}
                        >
                          Bet Type:
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            textTransform: 'capitalize',
                            fontSize: '12px',
                            textAlign: 'left',
                          }}
                        >
                          {listData?.isback ? 'Back (lagai)' : 'Lay (khai)'}
                        </Typography>
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          fontWeight: 600,
                          textTransform: 'capitalize',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                      >
                        <Typography
                          component="span"
                          sx={{ fontWeight: 600, fontSize: '12px' }}
                        >
                          Matched Date:
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            marginLeft: '2px',
                            textTransform: 'capitalize',
                            fontSize: '12px',
                            textAlign: 'left',
                          }}
                        >
                          {listData?.matchedtime}
                        </Typography>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        fontSize: '12px',
                        fontWeight: 600,
                      }}
                    >
                      user rate
                    </Typography>
                    <Typography
                      sx={{ textTransform: 'capitalize', fontSize: '12px' }}
                    >
                      {listData?.odds}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        fontSize: '12px',
                        fontWeight: 600,
                      }}
                    >
                      amount
                    </Typography>
                    <Typography
                      sx={{ textTransform: 'capitalize', fontSize: '12px' }}
                    >
                      {listData?.stake}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        fontSize: '12px',
                        fontWeight: 600,
                      }}
                    >
                      p&l
                    </Typography>
                    <Typography
                      sx={{ textTransform: 'capitalize', fontSize: '12px' }}
                    >
                      {postNegValue(listData?.pnl)}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}

export default AccountBetListModal
