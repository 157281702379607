import { Box, Container } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const InplayHeading = styled(Box)({
    width: '140px',
    fontWeight: 600,
    color: '#fff',

})

export const BeforeAfter = styled(Box)({
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'visible',
    '&:after': {
        content: "''",
        position: 'absolute',
        top: 0,
        width: 0,
        height: 0,
        right: 0,
        borderLeft: '50px solid #333',
        borderBottom: '75px solid #fff'
    }
})

export const StyledContainer = styled(Container)(({ theme }) => ({
    // backgroundColor: theme.palette.tablebg.main,
    maxHeight: 'calc(100vh - 284px)',
    overflow: 'auto ',
    margin: '10px auto 0px',
    [theme.breakpoints.up('lg')]: {
        padding: 0,
    },
    [theme.breakpoints.down('md')]: {
        padding: 0,
        margin: 0,
        width: '100%',
    },
}))
