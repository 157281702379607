import { Box, Typography } from '@mui/material'
import allrules from './rules'
import React from 'react'

const RulesPage = () => {
    return (
        <Box sx={{ padding: '0px 10px' }}>
            <Typography component='h4' sx={{ fontSize: '24px', fontWeight: 500, textAlign: 'left', margin: '10px 0 8px' }}>
                Rules
            </Typography>
            <hr style={{
                marginTop: '1rem',
                marginBottom: '1rem',
                border: 0,
                borderTop: '1px solid rgba(51,51,51,.1)'
            }} />

            <Box sx={{}}>
                {allrules?.map((rules, index) => (
                    <Typography key={index + 1} component='p' sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', margin: '0 0 16px' }}>
                        <Typography component='span' sx={{ fontSize: '12px', color: 'dark.main', textAlign: 'left', marginRight: '5px' }}>
                            {`${rules.sno}.`}
                        </Typography>
                        <Typography component='span' sx={{ fontSize: '12px', color: 'dark.main', textAlign: 'left' }}>
                            {rules.text}
                        </Typography>
                    </Typography>
                ))}
            </Box>

        </Box>
    )
}

export default RulesPage