import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

export const globalApi = createApi({
  reducerPath: 'globalApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://oddsapi.247idhub.com/betfair_api/',
  }),
  endpoints: (build) => {
    return {
      ip: build.query<any, void>({
        query: () => 'my-ip',
      }),
    }
  },
})

export const { useIpQuery } = globalApi
