import { Box, Grid, Typography } from '@mui/material'
import React, { SyntheticEvent } from 'react'
// import { useMatchNameQuery } from '../../services/matchname'
import { Tab } from '../../component/common/Header/ThirdHeader.styled'
import { Tabs } from '../../component/common/Header/ThirdHeader.styled'
import { useInplayApiQuery } from '../../app/apis/oddsApi/oddsApiSlice'
import { useNavigate } from 'react-router'
import InPlayHeading from './InPlayHeading'
import { StyledContainer } from './inplay.styled'
import { theme } from '../../theme'
import { TbPointFilled } from 'react-icons/tb'
import LiveCasino from '../../component/common/Table/LIveCasino/LiveCasino'
import VirtualCasino from '../../component/common/Table/VirtualCasino/VirtualCasino'
import { useMatchNameQuery } from '../../app/apis/mainApi/mainApiSlice'
import { useSearchParams } from 'react-router-dom'
import { FiTv } from 'react-icons/fi'
interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
  className: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography sx={{ p: 0 }}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const InPlayPage = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const sportid = searchParams.get('sportId')
  const [value, setValue] = React.useState(0)

  // const { data: sportWiseData } = useMatchNameQuery('')

  const { data: inplayallsports } = useInplayApiQuery()
  const itemBack = {
    item: true,
    xs: 2,
    bgcolor: 'back.sky',
    sx: { cursor: 'pointer' },
  }

  const itemLay = {
    item: true,
    xs: 2,
    bgcolor: 'lay.pink',
    sx: { cursor: 'pointer' },
  }

  const container = {
    fontSize: '12px',
    color: 'dark.main',
    fontWeight: 600,
  }

  const handleGameDetail = (id: number) => {
    navigate(`/gamedetail/${id}?sportId=${sportid || 4}`)
  }

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        width: { md: 'xl' },
      }}
    >
      <Tabs
        sx={{ height: 35 }}
        aria-activedescendant="active"
        value={value}
        selectionFollowsFocus
        // onChange={handleChange}
        textColor="inherit"
        variant="standard"
        aria-label="full width tabs example"
        indicatorColor="secondary"
      >
        <Tab
          value={0}
          style={{
            backgroundColor: value === 0 ? '#0088ccA5' : '#2C3E50',
          }}
          key={0}
          onClick={() => setValue(0)}
          label={'All Sports'}
        />
        {inplayallsports?.data?.map((sport, index) => (
          <Tab
            key={index + 1}
            value={sport.sportid}
            label={sport.name}
            onClick={() => setValue(sport.sportid)}
            style={{
              backgroundColor:
                value === sport.sportid ? '#0088ccA5' : '#2C3E50',
            }}
          />
        ))}
      </Tabs>

      {inplayallsports?.data &&
        inplayallsports?.data?.map((allSportsData, idx) => (
          <Box
            key={idx}
            sx={{
              display:
                value == allSportsData.sportid || value === 0
                  ? 'block'
                  : 'none',
            }}
          >
            <Box sx={{ marginBlock: '10px ' }}>
              {value === 0 && <InPlayHeading name={allSportsData?.name} />}
              {allSportsData?.matchList &&
              allSportsData?.matchList.length === 0 ? (
                <StyledContainer
                  sx={{
                    color: '#2c3e50',
                    fontSize: '12px',
                    backgroundColor: '#e4e4e4',
                    margin: '4px auto',
                    padding: '3px !important',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  No real-time records found
                </StyledContainer>
              ) : (
                <>
                  {allSportsData?.matchList.map((matchListData, idx) => (
                    <StyledContainer key={idx}>
                      <Box
                        className="allsports"
                        sx={{
                          padding: { md: '4px 10px', xs: '4px 10px' },
                        }}
                      >
                        {allSportsData?.matchList && (
                          <Box
                            onClick={() => {
                              handleGameDetail(matchListData?.matchId)
                            }}
                            className="parent"
                            sx={{
                              backgroundColor: '#f7f7f7',
                              borderBottom: {
                                md: `1px solid ${theme.palette.border.lightgray2}`,
                                xs: `1px solid ${theme.palette.border.lightgray2}`,
                              },
                              padding: { md: '4px 0px', xs: '4px 0px' },
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Box
                                textAlign="left"
                                fontSize="14px"
                                fontWeight="bold"
                                sx={{ px: { xs: 1 } }}
                              >
                                {matchListData.matchName}
                                <Typography textAlign="left" fontSize="12px">
                                  {matchListData.openDate}
                                </Typography>
                              </Box>

                              <Box
                                className="icon-holder"
                                sx={{
                                  px: '12px',
                                  gap: '4px',
                                  display: { md: 'flex', xs: 'flex' },
                                  alignItems: 'flex-end',
                                  justifyContent: 'space-between',
                                }}
                              >
                                {matchListData?.inPlay === true ? (
                                  <TbPointFilled
                                    color="#14805e"
                                    style={{
                                      transform: 'scale(2)',
                                      width: '10px',
                                      height: '10px',
                                    }}
                                  />
                                ) : (
                                  <></>
                                )}
                                <FiTv />
                                {matchListData?.F ? (
                                  <img
                                    style={{
                                      height: '10px',
                                      width: '15px',
                                    }}
                                    src="/img/ic_fancy.png"
                                    alt="icon-fancy"
                                  />
                                ) : (
                                  ''
                                )}
                                {!(
                                  allSportsData?.name === 'Football' ||
                                  allSportsData?.name === 'Tennis'
                                ) ? (
                                  matchListData?.bm ? (
                                    <img
                                      style={{
                                        height: '10px',
                                        width: '15px',
                                      }}
                                      src="/img/ic_bm.png"
                                      alt="icon-bookmaker"
                                    />
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  ''
                                )}
                              </Box>
                            </Box>
                            <Grid container {...container}>
                              <Grid item xs={4}>
                                1
                              </Grid>
                              <Grid item xs={4}>
                                X
                              </Grid>
                              <Grid item xs={4}>
                                2
                              </Grid>
                            </Grid>
                            <Grid container fontSize="14px" fontWeight={600}>
                              <Grid {...itemBack}>
                                {matchListData?.team1Back}
                              </Grid>
                              <Grid {...itemLay}>
                                {matchListData?.team1Lay}
                              </Grid>
                              <Grid {...itemBack}>
                                {matchListData?.drawBack}
                              </Grid>
                              <Grid {...itemLay}>{matchListData?.drawLay}</Grid>
                              <Grid {...itemBack}>
                                {matchListData?.team2Back}
                              </Grid>
                              <Grid {...itemLay}>
                                {matchListData?.team2Lay}
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                      </Box>
                    </StyledContainer>
                  ))}
                </>
              )}
            </Box>
          </Box>
        ))}

      <LiveCasino />
      {/* <VirtualCasino /> */}
    </Box>
  )
}

export default InPlayPage
