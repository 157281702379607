import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
// import { TopTab } from './GameDetail.styled'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
// import { TopTabs } from './GameDetail.styled'
import { Outlet, useOutletContext, useParams } from 'react-router'
// import GameDetail from './GameDetail'
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
// import MatchedBet from './MatchedBet'
// import { AiOutlineDesktop } from 'react-icons/ai'
import { TopTab, TopTabs } from '../../component/common/GameDetail/GameDetail.styled'
// import MatchedBet from '../../component/common/GameDetail/MatchedBet'
import VirtualCasinoHeader from '../../component/common/VirtualAndLiveCasino/VirtualCasinoHeader'
// import VirtualMatchedBet from './VirtualMatchedBet'
import { CardSize, ParentWrapper, Virtuacasinoltable } from './virtualpattiopen.styled'
import { FiArrowRight } from 'react-icons/fi'
import moment from 'moment'
import { database } from '../../firebase'
import VirtualCasinoModal from '../../component/common/VirtualCasinoModal/VirtualCasinoModal'
import { BsFillPlayFill } from 'react-icons/bs'
import VirtualMatchedBet from '../VteenPatti20/VirtualMatchedBet'
import Suspendedlock from '../../component/common/Suspendedlock'
import { useSearchParams } from 'react-router-dom'
import { result, vopenteenpattidata } from '../../services/types'
import CardComp from '../../component/common/CardComp'
import Lastresult from '../../component/common/Lastresult'
import { useVirtualcasinoliabilityQuery } from '../../app/apis/mainApi/mainApiSlice'
import { useGetBetListByMatchResQuery } from '../../app/apis/mdkalyanExchApi/mdkalyanExchApiSlice'

const CardTypeByAbrv: { [x: string]: string } = {
  "DD": "eat",
  "HH": "pan",
  "CC": "cdee",
  "SS": "hukum"
}
interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
  className: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Box sx={{ p: 0 }}>{children}</Box>
        </Box>
      )}
    </div>
  )
}

const VteenPattiOpen = () => {
  const [openTeenPattiData, setOpenTeenPattiData] = useState<vopenteenpattidata>()
  const [openTeenPattiDataResult, setOpenTeenPattiDataResult] =
    useState<result[]>()
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get('id')



  const gettingsplittingimgdata = openTeenPattiData?.t1?.[0]?.C1.split(',') || []

  const { data: vopenteenpattipayload } = useVirtualcasinoliabilityQuery({
    eventid: id || '', roundid: openTeenPattiData?.t2?.[0]?.mid || ''
  })


  const theme = useTheme()
  const [value, setValue] = React.useState(0)
  const [virtualModal, setVirtualModal] = useState(false)
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue)
  }
  const virtual2020teenpatti = () => {
    setVirtualModal(true)
  }

  const { handleClickBetModalOpen
  } = useOutletContext<any>()

  const { data: getBetlistmatch } = useGetBetListByMatchResQuery({ eventId: Number(id) });

  const arrrr = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ]
  const arrrr2 = [1, 2, 3, 4, 5, 6, 7, 8]

  useEffect(() => {
    const betex = database.ref('betex')
    betex.on('value', (snapshot) => {
      setOpenTeenPattiData(snapshot.val()?.otp?.data.data)
      setOpenTeenPattiDataResult(snapshot.val()?.otp?.result)
    })
  }, [])

  const autotimeFunc = () => {
    const autotime = openTeenPattiData?.t1?.[0]?.autotime
    if (autotime === '0') {
      return (
        <Typography
          component="p"
          sx={{ fontSize: '12px', fontWeight: '600' }}
        >
          GO!!
        </Typography>
      )
    } else {
      return <Typography>{autotime}</Typography>
    }
  }

  return (
    <Box sx={{ width: { md: 'xl' } }}>
      <VirtualCasinoModal
        virtualModal={virtualModal}
        setVirtualModal={setVirtualModal}
      />
      <Paper
        elevation={10}
        sx={{
          bgcolor: 'tabsbgclr.main',
          height: 35,
          borderRadius: 0,
        }}
      >
        <TopTabs
          sx={{
            '& .MuiButtonBase-root': {
              width: '100%',
              maxWidth: '50%',
            },
          }}
          aria-activedescendant="active"
          value={value}
          onChange={handleChange}
          variant="standard"
          aria-label="full width tabs example"
          indicatorColor="secondary"
        >
          <TopTab
            disableRipple
            label={`game`.toUpperCase()}
            onClick={() => setValue(0)}
          />
          <TopTab
            disableRipple
            label={`Matched bet (${getBetlistmatch?.data && getBetlistmatch?.data?.length > 0 ? getBetlistmatch?.data?.length : 0})`.toUpperCase()}
            onClick={() => setValue(1)}
          />
        </TopTabs>
      </Paper>

      <TabPanel
        value={value}
        index={0}
        dir={theme.direction}
        className="tabpanell"
      >
        <ParentWrapper>
          <VirtualCasinoHeader className="some">
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="header-title header-title-left"
                >
                  V 20-20 TEENPATTI
                </Typography>
                <Typography component="p" className=" header-title-left">
                  <Typography
                    component="span"
                    className="pointer title"
                    onClick={virtual2020teenpatti}
                  >
                    <FiArrowRight /> Rules
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="title header-title-right"
                >
                  Round ID: {openTeenPattiData?.t1?.[0]?.mid}
                </Typography>
                <Typography
                  component="p"
                  className="title header-title-right"
                >
                  Time: {moment().format('hh:mm:ss A')}
                </Typography>
              </Grid>
            </Grid>
          </VirtualCasinoHeader>

          <Virtuacasinoltable>
            <Box className="teenpattiheadertext">
              <Typography className="heading">Open Teenpatti</Typography>
            </Box>

            <Grid container>
              <Grid item xs={4} md={5}></Grid>
              <Grid item xs={4} md={2} className="flex" sx={{ mt: 2 }}>
                <Grid container>
                  {[
                    ...(openTeenPattiData?.t1?.[0]
                      ? openTeenPattiData?.t1?.[0].C1.split(',').filter(
                        (_, index) => !((index + 1) % 9)
                      )
                      : []),
                    ...arrrr,
                  ]
                    .splice(0, 3)
                    .map((data) => (
                      <Grid
                        item
                        xs={4}
                        md={4}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CardComp shown={!!data} card={data} />
                      </Grid>
                    ))}

                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 2,
                    }}
                  >
                    <Button
                      className="playerbutton"
                      disableRipple
                      disabled
                      sx={{ textAlign: 'center' }}
                    >
                      <Typography component="p" sx={{ fontSize: '14px' }}>
                        dealer
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} md={5}></Grid>
            </Grid>

            <Grid
              className="grid-container-for-24-card"
              container
              maxWidth={600}
              m={'auto'}
              sx={{ border: '1px solid #fff', mt: 2 }}
            >
              {openTeenPattiData?.t1?.[0] &&
                [
                  ...openTeenPattiData?.t1?.[0].C1.split(',').filter(
                    (_, index) => (index + 1) % 9
                  ),
                  ...arrrr,
                ]
                  .splice(0, 24)
                  ?.map((data) => (
                    <Grid
                      item
                      xs={1.5}
                      sx={{
                        display: 'flex',
                        alignItem: 'center',
                        justifyContent: 'center',
                        padding: '2px',
                        backgroundColor: '#faa9ba',
                      }}
                    >
                      <CardComp shown={!!data} card={data} />
                    </Grid>
                  ))}
              {/* {arrrr2.map((i)) => (
               
              ))} */}

              {arrrr2.map((num) => (
                <Grid
                  item
                  xs={1.5}
                  sx={{
                    borderRight: '1px solid #fff',
                    fontSize: '14px',
                    color: '#fff',
                    backgroundColor: '#1a850B80',
                  }}
                >
                  {num}
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box className="_timer">
                <Typography component="p">{autotimeFunc()}</Typography>
              </Box>
            </Grid>
          </Virtuacasinoltable>

          <Grid container sx={{ borderBlock: '1px solid #999' }}>
            <Grid item xs={6} md={6} sx={{ padding: ' 5px' }}>
              <Typography
                component="p"
                sx={{ textAlign: 'left', fontSize: '12px' }}
              >
                Min: {openTeenPattiData?.t1?.[0]?.min} | Max:
                {openTeenPattiData?.t1?.[0]?.max}
              </Typography>
            </Grid>

            <Grid
              item
              xs={3}
              md={2}
              bgcolor={theme.palette.back.sky}
              sx={{ padding: '2px', borderLeft: '1px solid #999' }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  color: '#000',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                Back
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              md={2}
              bgcolor={theme.palette.back.sky}
              sx={{ padding: '2px', borderLeft: '1px solid #999' }}
            ></Grid>
            <Grid item md={2} />
          </Grid>

          {openTeenPattiData?.t2.map((opendata, index) => (
            <Grid container sx={{ borderBottom: '1px solid #999' }}>
              <Grid
                item
                xs={6}
                md={6}
                sx={{ display: 'flex', alignItems: 'center', padding: '5px' }}
              >
                <Typography
                  component="p"
                  sx={{
                    marginRight: '16px',
                    textAlign: 'left',
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {opendata?.nat}
                </Typography>
                <Typography
                  component="b"
                  sx={{ fontSize: '13px', marginLeft: '4px' }}
                >
                  {gettingsplittingimgdata[index]?.substring(0, gettingsplittingimgdata[index]?.length - 2)}

                </Typography>
                {gettingsplittingimgdata?.length && CardTypeByAbrv[gettingsplittingimgdata?.[index + 0]?.slice(-2)] && <img
                  src={`http://admin.kalyanexch.com/images/cards/${gettingsplittingimgdata && CardTypeByAbrv[gettingsplittingimgdata?.[index]?.slice(-2)]}.png`}
                  alt=""
                  style={{
                    marginLeft: '2px',
                    width: '10px',
                    height: '11.7px',
                  }}
                />}
                <Typography
                  component="b"
                  sx={{ fontSize: '13px', marginLeft: '4px' }}
                >
                  {gettingsplittingimgdata[index + 9]?.substring(0, gettingsplittingimgdata[index + 9]?.length - 2)}
                </Typography>
                {gettingsplittingimgdata?.length && CardTypeByAbrv[gettingsplittingimgdata?.[index + 9]?.slice(-2)] && <img
                  src={`http://admin.kalyanexch.com/images/cards/${gettingsplittingimgdata && CardTypeByAbrv[gettingsplittingimgdata?.[index + 9]?.slice(-2)]}.png`}

                  alt=""
                  style={{
                    marginLeft: '2px',
                    width: '10px',
                    height: '11.7px',
                  }}
                />}
                <Typography
                  component="b"
                  sx={{ fontSize: '13px', marginLeft: '4px' }}
                >
                  {gettingsplittingimgdata[index + 18]?.substring(0, gettingsplittingimgdata[index + 18]?.length - 2)}
                </Typography>

                {gettingsplittingimgdata?.length && CardTypeByAbrv[gettingsplittingimgdata?.[index + 18]?.slice(-2)] && <img
                  src={`http://admin.kalyanexch.com/images/cards/${gettingsplittingimgdata && CardTypeByAbrv[gettingsplittingimgdata?.[index + 18]?.slice(-2)]}.png`}
                  alt=""
                  style={{
                    marginLeft: '2px',
                    width: '10px',
                    height: '11.7px',
                  }}
                />}
              </Grid>
              <Grid
                item
                xs={3}
                md={2}
                bgcolor={theme.palette.back.sky}
                sx={{
                  borderLeft: '1px solid #999',
                  cursor: 'pointer',
                  position: 'relative',
                }}
                onClick={() => {
                  if (openTeenPattiData?.t2?.[0]?.gstatus === '0') {
                    return <></>
                  }
                  handleClickBetModalOpen({
                    betType: 'back',
                    deviceInfo: {
                      browser: "Chrome",
                      browser_version: "114.0.0.0",
                      device: "Macintosh",
                      deviceType: "desktop",
                      orientation: "landscape",
                      os: "Mac",
                      os_version: "mac-os-x-15",
                      userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                      userIp: "180.151.224.106"
                    },
                    isBetfair: false,
                    isBack: true,
                    isLay: false,
                    isbetfair: false,
                    liability: 0,
                    marketId: openTeenPattiData?.t1?.[0]?.mid,
                    matchId: Number(id),
                    marketName: openTeenPattiData?.t1?.[0]?.gtype,
                    matchName: location,
                    odds: openTeenPattiData?.t2?.[0]?.rate,
                    placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                    priceValue: opendata?.rate,
                    selectionId: opendata?.sid,
                    selectionIds: opendata?.sid,
                    selectionName: opendata?.nat,
                    stake: 0,
                    type: openTeenPattiData?.t1?.[0]?.gtype,
                    sportId: 504,
                  })
                }}
              >
                {openTeenPattiData?.t2?.[0]?.gstatus === '0' ? <Suspendedlock /> : ''}
                <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                  {opendata?.rate}
                </Typography>
                <Typography sx={{ fontSize: '12px' }}>
                  {vopenteenpattipayload?.dataObj?.[opendata.sid]?.total_pnl || 0}
                  {/* {opendata?.sid.toString() === vopenteenpattipayload?.data?.[0]?.selectionid.toString() ? vopenteenpattipayload?.data?.[0]?.total_pnl : 0} */}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                md={2}
                bgcolor={theme.palette.back.sky}
                sx={{
                  borderLeft: '1px solid #999',
                  cursor: 'pointer',
                  position: 'relative',
                }}
              >
                {openTeenPattiData?.t2?.[0]?.gstatus === '0' ? <Suspendedlock /> : ''}
                <Typography
                  sx={{ fontSize: '12px', textTransform: 'capitalize' }}
                >
                  {`pair plus${index + 1}`}
                </Typography>
                <Typography sx={{ fontSize: '12px' }}>0</Typography>
              </Grid>
              <Grid item md={2} />
            </Grid>
          ))}

          <Box>
            <VirtualCasinoHeader className="some m-5 p-0">
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    component="p"
                    className="header-title header-title-left"
                  >
                    News
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    component="p"
                    className="title header-title-right"
                  >
                    <Typography
                      component="span"
                      sx={{
                        textTransform: 'capitalize',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 'auto',
                        maxWidth: '100px',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      <span>play</span>
                      <span>
                        <BsFillPlayFill
                          size={22}
                          style={{ marginTop: '5px' }}
                        />
                      </span>
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </VirtualCasinoHeader>
          </Box>

          {openTeenPattiDataResult && (
            <Lastresult teenTwentyResult={openTeenPattiDataResult} />
          )}
        </ParentWrapper>
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        dir={theme.direction}
        className="tabpanell"
      >
        <VirtualMatchedBet />
      </TabPanel>
    </Box >
  )
}

export default VteenPattiOpen
