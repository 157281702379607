import { Box } from '@mui/material'
import React, { AllHTMLAttributes, HtmlHTMLAttributes } from 'react'


interface props extends React.HTMLAttributes<HTMLDivElement> { }
const VirtualCasinoHeader = ({ children, className, ...props }: props) => {
    return (
        <Box className={className} {...props}>
            {children}
        </Box>
    )
}

export default VirtualCasinoHeader