import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useVirtualCasinoQuery } from '../../app/apis/mainApi/mainApiSlice'

import LiveCasino from '../../component/common/Table/LIveCasino/LiveCasino'
import VirtualCasino from '../../component/common/Table/VirtualCasino/VirtualCasino'
import { CasinoCard } from '../../component/common/VirtualAndLiveCasino/VirtualCard'
import { CustomGrid } from '../../component/common/VirtualAndLiveCasino/virtualcard.styled'
import { imgdata } from './cardimgdata'
import { useCasinoListQuery } from '../../app/apis/casinoApi/casinoApiSlice'
import { StyledBox } from '../../component/common/Table/LIveCasino/LiveCasino.styled'

const VirtualCasinoPage = () => {
  const theme = useTheme()
  const mediamatches = useMediaQuery(theme.breakpoints.up('sm'))
  const pathname = useLocation()
  const navigate = useNavigate()

  const { data: virtualcasinodata } = useVirtualCasinoQuery({ type: 'virtual' })

  const navigatetopage = (e: string, id: string) => {
    navigate(`/vcasino/${id}/${id}`)
  }

  const { data: virtualData } = useCasinoListQuery({ casinoType: 'VIRTUAL' })

  console.log(virtualData, 'virtual')

  return (
    <>
      <Box mt={2} sx={{ width: '100%' }}>
        <StyledBox>
          <Typography
            component="h4"
            fontSize="16px"
            fontWeight={600}
            textAlign={'left'}
            color={theme.palette.dark.main}
          >
            VIRTUAL CASINO
          </Typography>

          <Grid container>
            {virtualData?.data?.map((card, idx: number) => (
              <Grid
                key={idx}
                item
                my={1}
                lg={3}
                xs={6}
                onClick={() => navigatetopage(card?.name, card?.tableId)}
                sx={{
                  position: 'relative',
                  marginInline: { md: '0px' },
                  padding: { xs: '5px' },
                }}
              >
                <Box position={'relative'}>
                  <img
                    style={{
                      width: '130px',
                      height: '170px',
                      margin: 'auto',
                      maxWidth: '100%',
                    }}
                    src={card?.image}
                  />

                  <Button
                    sx={{
                      bottom: 0,
                      display: 'block',
                      position: 'absolute',
                      textTransform: 'uppercase',
                      fontSize: '8px',
                      padding: '4px 10px',
                      color: 'info.main',
                      width: '100%',
                      boxShadow: '0px 7px 8px -8px #000',
                      backgroundImage: `linear-gradient(${'#0088cc'}, ${'#2c3e50'})`,
                    }}
                  >
                    {card?.name}
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </StyledBox>
      </Box>
      {/* <CustomGrid
        container
        sx={{ justifyContent: { md: 'unset', xs: 'space-between' } }}
      >
        {virtualData?.data?.map((card, cardId) => (
          <CasinoCard
            variation={mediamatches ? 'lg_card' : 'sm_card'}
            item
            xs={6}
            md={3}
            key={cardId}
            onClick={() => navigatetopage(card?.name, card?.tableId)}
          >
            <img src={`${card.image}`} alt={card?.name} />
          </CasinoCard>
        ))}
      </CustomGrid> */}

      {/* <LiveCasino />

            <VirtualCasino /> */}
    </>
  )
}

export default VirtualCasinoPage
