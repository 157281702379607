import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Dispatch, FC, SetStateAction } from 'react'
import { fancyBetPayload, FancyOddsInterface } from '../../../services/types'
import FancyRow from './FancyRow'
import { IoMdInformationCircle } from 'react-icons/io'

interface props {
  FancyOdds: FancyOddsInterface[]
  handleClickBetModalOpen: (args: fancyBetPayload) => void
  marketname: string
  matchName: string
  setSessionModal: Dispatch<SetStateAction<string>>
}

export const layDetail = {
  backgroundColor: 'lay.pink',
  padding: '2px 0',
  fontSize: '12px',
  fontWeight: 'bold',
  borderBottom: '1px solid #999',
  borderLeft: '1px solid #999',
  color: 'dark.main',
}

export const backDetail = {
  backgroundColor: 'back.sky',
  padding: '4px 0',
  fontSize: '12px',
  fontWeight: 'bold',
  borderBottom: '1px solid #999',
  borderLeft: '1px solid #999',
  color: 'dark.main',
}

export const backClr = {
  backgroundColor: 'back.sky',
}

export const layClr = {
  backgroundColor: 'lay.pink',
}

const TabsDataTable: FC<props> = ({
  FancyOdds,
  matchName,
  handleClickBetModalOpen,
  marketname,
  setSessionModal,
}) => {
  console.log(marketname, 'asdkfjslkdfjlksdjfs')
  const oddsComp = [
    <Grid item xs={6} {...backDetail}>
      {marketname !== 'Fancy3' ? 'YES' : 'Lay'}
    </Grid>,
    <Grid item xs={6} {...layDetail}>
      {marketname !== 'Fancy3' ? 'NO' : 'Back'}
    </Grid>,
  ]

  return (
    <>
      <Box>
        <Grid container>
          <Grid
            xs={8}
            item
            sx={{
              display: 'flex',
              alignItems: 'center !important',
              justifyContent: 'space-between',
              // backgroundColor: 'tabsbgclr.main',
              backgroundColor: '#2C3E50D9',
              color: 'secondary.main',
              fontSize: '12px',
              padding: '4px 5px',
            }}
          >
            {['OddEven', 'Fancy3', 'Fancy2'].includes(marketname)
              ? 'Normal'
              : marketname}

            {/* Market */}
            <IoMdInformationCircle color={'#fff'} size={'18px'} />
          </Grid>
          <Grid item xs={4}>
            <Grid container>
              {marketname === 'Fancy3' ? oddsComp : oddsComp.reverse()}
            </Grid>
          </Grid>
        </Grid>
        {FancyOdds?.map((FancyTwoData, index) => (
          <FancyRow
            key={index}
            setSessionModal={setSessionModal}
            matchName={matchName}
            marketname={marketname}
            FancyTwoData={FancyTwoData}
            handleClickBetModalOpen={handleClickBetModalOpen}
          />
        ))}
      </Box>
    </>
  )
}

export default TabsDataTable
