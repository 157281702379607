import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { Tab } from './ThirdHeader.styled'
import { Tabs } from './ThirdHeader.styled'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, createSearchParams } from 'react-router-dom'
// import { useActiveSportQuery } from '../../../services/activeSports'
import { useActiveSportQuery } from '../../../app/apis/mainApi/mainApiSlice'
import { vteenpatti20Payload } from '../../../services/types'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
  className: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography sx={{ p: 0 }}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    'aria-controls': `full-width-tabpanel-${index}`,
    id: `full-width-tab-${index}`,
  }
}

const ThirdHeader = () => {
  const theme = useTheme()
  const [value, setValue] = React.useState(4)

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue)
  }

  const {
    data: activeSport,
    isError,
    isLoading,
    isSuccess,
  } = useActiveSportQuery()

  const navigate = useNavigate()

  const iconBasedOnIdWithName = (sport: any) => {
    console.log(sport, 'asdkfjalskdfjs')
    if (sport?.sportid === 4) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            component={'p'}
            sx={{ fontSize: '14px', mr: 0.4 }}
            className={`d-icon ${sport.sportid === 4 && 'icon-4'}`}
          />
          <Typography component="span" sx={{ fontSize: '14px' }}>
            {sport?.name}
          </Typography>
        </Box>
      )
    } else if (sport?.sportid === 1) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            component={'p'}
            sx={{ fontSize: '14px', mr: 0.4 }}
            className={`d-icon ${sport.sportid === 1 && 'icon-62'}`}
          />
          <Typography component="span" sx={{ fontSize: '14px' }}>
            {sport?.name}
          </Typography>
        </Box>
      )
    } else if (sport?.sportid === 2) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            component={'p'}
            sx={{ fontSize: '14px', mr: 0.4 }}
            className={`d-icon ${sport.sportid === 2 && 'icon-2'}`}
          />
          <Typography component="span" sx={{ fontSize: '14px' }}>
            {sport?.name}
          </Typography>
        </Box>
      )
    }
  }

  return (
    <Box sx={{ bgcolor: 'background.paper', width: { md: 'xl' } }}>
      <AppBar position="static" sx={{ height: 35 }}>
        <Tabs
          aria-activedescendant="active"
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="standard"
          aria-label="full width tabs example"
          indicatorColor="secondary"
        >
          {activeSport?.data?.map((sport, index) => (
            <Tab
              key={index}
              value={sport.sportid}
              label={iconBasedOnIdWithName(sport)}
              sx={{
                backgroundColor:
                  value === sport.sportid
                    ? '#0088ccA5 !important'
                    : '#2C3E50 !important',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => {
                navigate({
                  pathname: '',
                  search: `?${createSearchParams({
                    sportId: sport.sportid.toString(),
                  })}`,
                })
                setValue(sport.sportid)
              }}
              {...a11yProps(0)}
              // style={{}}
            />
          ))}
        </Tabs>
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          className="tabpanell"
        />
      </AppBar>
      <Outlet />
    </Box>
  )
}

export default ThirdHeader
