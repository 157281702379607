import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { LoginRes } from '../../../services/types';

const initialAuthState: AuthState = {
    // user: JSON.parse(localStorage.getItem('user') ?? 'null'),
    username: JSON.parse(localStorage.getItem('username') ?? 'null'),
    userid: JSON.parse(localStorage.getItem('userid') ?? 'null'),
    isLoggedIn: !!JSON.parse(localStorage.getItem('user') ?? 'null'),
    token: localStorage.getItem('token'),
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        login: (state, action: PayloadAction<LoginRes>) => {
            localStorage.setItem('token', action.payload.data.token)
            localStorage.setItem('data', JSON.stringify(action.payload.data))

            state.userid = action.payload.data.userid;
            state.username = action.payload.data.username;
            state.isLoggedIn = true;
            state.token = action.payload.token;

            return state;
        },
        logout: state => {
            const win: Window = window
            win.location.replace('/login')
            localStorage.clear();
            state.userid = '';
            state.username = '';
            state.token = null;
            state.isLoggedIn = false;

            return state;
        },
    },
});

export default authSlice.reducer;

export const { login, logout } = authSlice.actions;

/* SELECTORS */
const authSelector = (state: RootState) => state.auth;

export const selectUserIsLoggedIn = createSelector(authSelector, auth => auth.isLoggedIn);
export const selectToken = createSelector(authSelector, auth => auth.token);
export const selectUsername = createSelector(authSelector, auth => auth.username);
export const selectUserId = createSelector(authSelector, auth => auth.userid);