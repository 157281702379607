import { Box, Grid, Typography } from '@mui/material'
import { theme } from '../../../theme'
import { TbPointFilled } from 'react-icons/tb'
import { StyledContainer } from './MatchTable.styled'
import { useNavigate } from 'react-router-dom'
import { useHomePageApiQuery } from '../../../app/apis/oddsApi/oddsApiSlice'
import { useSearchParams } from 'react-router-dom'
import { FiTv } from 'react-icons/fi'

const MatchTable = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const sportid = searchParams.get('sportId')

  const navigate = useNavigate()

  const { data: oddBetData } = useHomePageApiQuery(sportid || '4')

  const handleGameDetail = (id: number) => {
    navigate(`/gamedetail/${id}?sportId=${sportid || 4}`)
  }

  const itemBack = {
    item: true,
    xs: 2,
    bgcolor: 'back.sky',
    sx: { cursor: 'pointer' },
  }

  const itemLay = {
    item: true,
    xs: 2,
    bgcolor: 'lay.pink',
    sx: { cursor: 'pointer' },
  }

  const container = {
    fontSize: '12px',
    color: 'dark.main',
    fontWeight: 600,
  }

  return (
    <StyledContainer>
      <Box
        sx={{
          padding: { md: '4px 10px', xs: '4px 10px' },
        }}
      >
        {oddBetData?.data?.map((matchData) => (
          <Box
            onClick={() => handleGameDetail(matchData?.matchId)}
            key={matchData?.matchId}
            className="parent"
            sx={{
              backgroundColor: '#f7f7f7',

              borderBottom: {
                md: `1px solid ${theme.palette.border.lightgray2}`,
                xs: `
        1px solid ${theme.palette.border.lightgray2}
            `,
              },
              padding: { md: '4px 0px', xs: '4px 0px' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                textAlign="left"
                fontSize="14px"
                fontWeight="bold"
                sx={{ px: { xs: 1 } }}
              >
                {matchData.matchName}
                <Typography textAlign="left" fontSize="12px">
                  {matchData.openDate}
                </Typography>
              </Box>

              <Box
                className="icon-holder"
                sx={{
                  px: '12px',
                  gap: '4px',
                  display: { md: 'flex', xs: 'flex' },
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                {matchData?.inPlay === true ? (
                  <TbPointFilled
                    color="#14805e"
                    style={{
                      transform: 'scale(2)',
                      width: '10px',
                      height: '10px',
                    }}
                  />
                ) : (
                  <></>
                )}
                <FiTv />
                {matchData?.F ? (
                  <img
                    style={{
                      height: '10px',
                      width: '15px',
                    }}
                    src="/img/ic_fancy.png"
                    alt="icon-fancy"
                  />
                ) : (
                  ''
                )}
                {sportid === '4' ? (
                  matchData?.bm ? (
                    <img
                      style={{
                        height: '10px',
                        width: '15px',
                      }}
                      src="/img/ic_bm.png"
                      alt="icon-bookmaker"
                    />
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </Box>
            </Box>
            <Grid container {...container}>
              <Grid item xs={4}>
                1
              </Grid>
              <Grid item xs={4}>
                X
              </Grid>
              <Grid item xs={4}>
                2
              </Grid>
            </Grid>
            <Grid container fontSize="14px" fontWeight={600}>
              <Grid {...itemBack}>{matchData?.team1Back}</Grid>
              <Grid {...itemLay}>{matchData?.team1Lay}</Grid>
              <Grid {...itemBack}>{matchData?.drawBack}</Grid>
              <Grid {...itemLay}>{matchData?.drawLay}</Grid>
              <Grid {...itemBack}>{matchData?.team2Back}</Grid>
              <Grid {...itemLay}>{matchData?.team2Lay}</Grid>
            </Grid>
          </Box>
        ))}
      </Box>
      {!(oddBetData?.data && oddBetData?.data?.length > 0) && (
        <Box
          sx={{
            color: '#2c3e50',
            fontSize: '12px',
            backgroundColor: '#e4e4e4',
            margin: '4px 0 0',
            padding: '6px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          No real-time records found
        </Box>
      )}
    </StyledContainer>
  )
}

export default MatchTable
