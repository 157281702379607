import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { BetFairApiActiveMatch, allinplayResponse, gameDetailApiTypes } from '../../../services/types'

const oddsApiSlice = createApi({
    reducerPath: 'oddsApiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_ODD_URL,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token')
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    endpoints: (build) => {
        return {
            homePageInPlayApi: build.query<any, void>({
                query: () => {
                    return {
                        url: 'betfair_api/active_match',
                        method: 'GET',
                    }
                },
            }),

            homePageApi: build.query<BetFairApiActiveMatch, string>({
                query: (args) => {
                    return {
                        url: 'betfair_api/active_match/' + args,
                        method: 'GET',
                    }
                },
            }),

            inplayApi: build.query<allinplayResponse, void>({
                query: () => {
                    return {
                        url: 'betfair_api/active_match',
                        method: 'GET'
                    }
                }
            }),

            Odds: build.query<gameDetailApiTypes, string>({
                query: (args) => {
                    return {
                        url: `betfair_api/fancy/${args}`,
                        method: 'GET',
                    }
                },
            }),
        }
    }
})

export const {
    useHomePageInPlayApiQuery,
    useHomePageApiQuery,
    useInplayApiQuery,
    useOddsQuery
} = oddsApiSlice

export default oddsApiSlice