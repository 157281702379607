import { Typography, Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import GameDetailTabSection from './GameDetailBottomTabs'
import { useParams } from 'react-router'
import MatchOdds from './MatchOdds'
import Bookmaker from './Bookmaker'
import { FC, useEffect, useRef, useState } from 'react'
import BetModal from '../BetModal/BetModal'
import {
  fancyBetPayload,
  oddsBetApiResponse,
  RunnerssData,
} from '../../../services/types'
import SessionBookModal from '../../SessionBook'
import { useSearchParams } from 'react-router-dom'
import { useOddsQuery } from '../../../app/apis/oddsApi/oddsApiSlice'
import './style.css'
import { useChannelIdQuery } from '../../../app/apis/mainApi/mainApiSlice'
import { useIpQuery } from '../../../services/global'
import { createProfits } from '../../../utils/eventutils'

interface Props {
  liveIframe: boolean
}
const GameDetail: FC<Props> = ({ liveIframe }) => {
  const ref = useRef<HTMLIFrameElement | null>(null)
  const scale = (ref.current?.clientWidth || 300) / 280
  const { data: userIp } = useIpQuery()
  const [sessionModal, setSessionModal] = useState('')
  const [query, setQuery] = useSearchParams()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) // Matches xs and down
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md')) // Matches sm to md
  const isLarge = useMediaQuery(theme.breakpoints.up('md')) // Matches md and up
  const [profits, setProfits] = useState<any>({
    Odds: {},
    Bookmaker: [],
    Fancy: [],
  })
  const [oddsPnll, setOddsPnll] = useState<any>()

  const sportId = query.get('sportId')

  const { id: matchId } = useParams()

  const [placeBetTime, setPlaceBetTime] = useState(0)
  const [open, setOpen] = useState(false)
  const [betDetailData, setBetDetailData] = useState<fancyBetPayload | null>(
    null
  )

  let userId = localStorage.getItem('data')
  let parseUser = userId && JSON.parse(userId)

  // useEffect(() => {
  //   setBetDetailData((prev: any) => ({
  //     ...prev,
  //     userid: parseUser?.userid,
  //   }))
  // }, [userId])

  const [runnersData, setRunnersData] = useState<RunnerssData[]>([])

  const handleClickBetModalOpen = (args: fancyBetPayload) => {
    setOpen(true)
    setRunnersData(args?.runner)

    setOddsPnll(args?.oddsPnlObj)
    setBetDetailData((prev: any) => ({
      ...prev,
      // ...args,

      betType: args?.betType,
      deviceInfo: {
        browser: 'Chrome',
        browser_version: '131.0.0.0',
        device: 'Nexus Phone',
        deviceType: 'mobile',
        orientation: 'portrait',
        os: 'Android',
        os_version: 'unknown',
        userAgent:
          'Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Mobile Safari/537.36',
        userIp: '160.202.38.153',
      },
      isBack: args?.isBack,
      isbetfair: args?.isbetfair,
      isBetfair: args?.isBetfair,
      isLay: args?.isLay,
      liability: Number(args?.liability),
      marketId: args?.marketId,
      marketName: args?.marketName,
      matchId: Number(args?.matchId),
      matchName: args?.matchName,
      odds: args?.odds,
      placeTime: args?.placeTime,
      priceValue: args?.isfancy ? Number(args?.priceValue) : Number(args?.odds),
      selectionId: args?.selectionId,
      selectionIds: args?.selectionIds,
      selectionName: args?.selectionName,
      sportId: Number(args?.sportId),
      stake: args?.stake,
      type: args?.type,
    }))
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setPlaceBetTime((currTime) => {
        if (currTime === 1) {
          setOpen(false)
          return 0
        }
        return currTime - 1
      })
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    if (open) {
      setPlaceBetTime(30)
    } else {
      setPlaceBetTime(0)
    }
  }, [open])

  const {
    data: OddsBetFairApi,
    isError,
    isSuccess,
    isLoading,
  } = useOddsQuery(matchId || '', { pollingInterval: 1000 })

  const { data } = useChannelIdQuery({ matchId: matchId })
  useEffect(() => {
    createProfits({
      fancyOdds: OddsBetFairApi,
      // fancyPnl: fancyOddsPnl,
      betDetails: {
        isFancy: betDetailData?.isfancy,
        isBack: betDetailData?.isBack,
        odds: betDetailData?.odds,
        marketName: betDetailData?.type,
        selectionId: Number(betDetailData?.selectionId),
        priceValue: betDetailData?.isfancy
          ? betDetailData?.odds
          : betDetailData?.priceValue,
        marketId:
          betDetailData?.marketId === ''
            ? betDetailData?.selectionId
            : betDetailData?.marketId,
        matchId: matchId,
      },
      rechange: false,
      pnl: oddsPnll,
      setProfits,
    })
  }, [betDetailData, oddsPnll, OddsBetFairApi])

  return (
    <Box>
      <Box
        className="heading"
        sx={{
          backgroundColor: 'dark.main',
          padding: '10px 15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          // marginTop: '10px',
        }}
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontSize: '12px',
            color: '#fff',
            fontWeight: '600',
            textAlign: 'left',
          }}
        >
          {OddsBetFairApi?.Odds[0]?.matchName}
        </Typography>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontSize: '12px',
            color: '#fff',
            fontWeight: '600',
            textAlign: 'left',
          }}
        >
          {OddsBetFairApi?.Odds[0]?.eventTime}
        </Typography>
      </Box>

      {!liveIframe ? (
        <iframe
          className=""
          // title='tv-score-container'
          style={{ aspectRatio: '16/9' }}
          width={'100%'}
          height={isSmall ? 'auto' : '283px'}
          src={`https://scoredata.365cric.com/#/score5/${matchId}`}
          // src={`https://score.247idhub.com/go-score/template/${sportId}/${matchId}`}
          // src={`https://score.247idhub.com/index.html/event/${matchId}?theme=crazy-diamond`}
        />
      ) : (
        <div className="tv-score-container">
          <iframe
            width="100%"
            ref={ref}
            className="tv-iframe"
            title="tv"
            style={{
              aspectRatio: '16/9',
              transform: `scale(${scale})`,
            }}
            // src={`https://100tun.online/web/${matchId}.html`}
            src={`https://e765432.xyz/static/48efac116d4775729d8ee0cda4a0361df3d3c89b/getdata.php?chid=${data?.data?.channelId}&ip=${userIp?.ip}`}
            // src={`https://luckybet.one/?eventId=${matchId}`}
            //src={`http://13.233.57.150/test.php?ChannelId=${channelId}`}
          />
        </div>
      )}

      {sessionModal && (
        <SessionBookModal
          sessionModal={sessionModal}
          setSessionModal={setSessionModal}
        />
      )}

      {OddsBetFairApi && (
        <BetModal
          runnersData={runnersData}
          placeBetTime={placeBetTime}
          OddsBetFairApi={OddsBetFairApi}
          open={open}
          profit={profits}
          setOpen={setOpen}
          setProfits={setProfits}
          betDetailData={betDetailData}
          setBetDetailData={setBetDetailData}
          oddsPnl={oddsPnll}
        />
      )}

      {matchId &&
        OddsBetFairApi?.Odds?.map((marketData, idx: number) => (
          <MatchOdds
            // setOddsPnll={setOddsPnll}
            key={idx}
            profit={profits}
            marketData={marketData}
            matchId={matchId}
            handleClickBetModalOpen={handleClickBetModalOpen}
          />
        ))}

      {OddsBetFairApi?.Bookmaker && OddsBetFairApi?.Bookmaker?.length > 0 && (
        <Bookmaker
          // setOddsPnll={setOddsPnll}
          profit={profits}
          matchName={OddsBetFairApi?.Odds[0]?.matchName || ''}
          bookmakerData={OddsBetFairApi?.Bookmaker}
          handleClickBetModalOpen={handleClickBetModalOpen}
        />
      )}

      {OddsBetFairApi && (
        <GameDetailTabSection
          setSessionModal={setSessionModal}
          matchName={OddsBetFairApi?.Odds[0]?.matchName || ''}
          FancyOdds={OddsBetFairApi}
          handleClickBetModalOpen={handleClickBetModalOpen}
        />
      )}
    </Box>
  )
}

export default GameDetail
