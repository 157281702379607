import { Box } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const Cardcompostyled = styled(Box)`
.flip-card {
    width: 35px;
    height: 43.75px;
    margin: '0px auto 10px auto',
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    margin:auto;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */

  
  /* Position the front and back side */
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background-color: #bbb;
  }
  
  .flip-card img {
    width: 35px;
    height: 43.75px;
  }
  
  /* Style the back side */
   .flip-card-back {
    transform: rotateY(180deg);
  }
  .flip-card.true .flip-card-inner {
    transform: rotateY(180deg);
  }
`




/* 
.card_full{
    position: relative;
    transition: transform 5000ms 500ms;
    transform-style: preserve-3d;

}
.card_full.true{
    transform:  rotate3d(0, 1, 0, 180deg);

}
.card_full:hover  {
  transform: rotateY(180deg);
}

.card_front{
    position: absolute;
    transform: rotateY(180deg);
}
.card_back{
     position: absolute;

} */

