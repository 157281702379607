import {
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useChangepasswordMutation } from '../../app/apis/mainApi/mainApiSlice'
import { ErrorMessage } from '../../utils/errorconfig'
import snackBarUtil from '../../utils/snackbar'
import {
  ChangePasswordBtn,
  CustomInput,
  CustomInputLabel,
} from './changepassword.styled'

const Changepassword = () => {

  const navigate = useNavigate()

  const [data, setData] = useState({
    currentPassword: '',
    confirmPassword: '',
    newPassword: '',
    currentPasswordError: false,
    confirmPasswordError: false,
    newPasswordError: false
  })

  const [trigger, { data: changePasswordData, isError, isSuccess, isLoading }] = useChangepasswordMutation()

  const handleOnchange = (e: any) => {
    setData((prevState) => { return { ...prevState, [e.target.name]: e.target.value, [`${e.target.name}Error`]: !e.target.value } });
  }

  const checkPassword = () => !!(data.newPassword && data.confirmPassword && data.currentPassword && data.newPassword !== data.currentPassword && data.newPassword === data.confirmPassword)

  const getmessageerr = (message: string) => {
    snackBarUtil.error(<Typography sx={{ fontSize: '14px', textAlign: 'left' }} component='p'>{message}</Typography>)
  }

  const handleChangePassword = () => {
    if (checkPassword()) {
      trigger({
        newPassword: data.newPassword,
        oldPassword: data.currentPassword,

      })
    }
    else {
      setData((prevState) => {
        return {
          ...prevState,
          currentPasswordError: !data.currentPassword,
          newPasswordError: !data.newPassword,
          confirmPasswordError: !data.confirmPassword,
        }
      });
      getmessageerr(changePasswordData?.type || 'invalid data');
    }
  }

  useEffect(() => {
    if (changePasswordData) {
      if (changePasswordData?.responseCode === 202) {
        return
      } else if (changePasswordData?.responseCode === 200) {
        const token = localStorage.getItem('token')
        if (token) {
          localStorage.clear()
          navigate('/login')
        }
      }
    }

  }, [changePasswordData])


  return (
    <Box sx={{ padding: '5px' }}>

      <Box
        sx={{
          backgroundColor: 'primary.main',
          padding: '8px 15px',
          textAlign: 'left',
        }}
      >
        <Typography
          sx={{ fontSize: '16px', color: '#fff', textTransform: 'capitalize' }}
        >
          change password
        </Typography>
      </Box>
      <form>
        <Grid container >
          <Grid item xs={12} sx={{ padding: '15px 0px' }}>

            <CustomInputLabel htmlFor="component-simple">
              current password
            </CustomInputLabel>

            <CustomInput
              name='currentPassword'
              type="password"
              id="component-simple"
              disableUnderline
              // defaultValue=""
              value={data.currentPassword}
              onChange={handleOnchange}
            />
            {data.currentPasswordError && <Typography sx={{ color: '#FF3C3C', fontSize: '12px', }}>
              {ErrorMessage?.currentpass}
            </Typography>}
          </Grid>
          <Grid item xs={12} sx={{ padding: '15px 0px' }}>
            <CustomInputLabel
              htmlFor="component-simple"
              sx={{ textTransform: 'capitalize' }}
            >
              new password
            </CustomInputLabel>
            <CustomInput
              name='newPassword'
              type="password"
              disableUnderline
              id="component-simple"
              // defaultValue=""
              value={data.newPassword}
              onChange={handleOnchange}
              sx={{ borderBottom: '1px solid #0088cc' }}
            />
            {data.newPasswordError && <Typography sx={{ color: '#FF3C3C', fontSize: '12px', }}>
              {ErrorMessage?.newpass}
            </Typography>}
          </Grid>
          <Grid item xs={12} sx={{ padding: '15px 0px' }}>
            <CustomInputLabel htmlFor="component-simple" sx={{}}>
              confirm new password
            </CustomInputLabel>
            <CustomInput
              name='confirmPassword'
              value={data.confirmPassword}
              type="password"
              id="component-simple"
              disableUnderline
              // defaultValue=""
              onChange={handleOnchange}
              sx={{ borderBottom: '1px solid #0088cc' }}
            />
            {data.newPassword !== data.confirmPassword && <Typography sx={{ color: '#FF3C3C', fontSize: '12px', }}>
              {ErrorMessage?.passnotmatch}
            </Typography>}
          </Grid>
        </Grid>
        <ChangePasswordBtn onClick={handleChangePassword} disableRipple>
          change password
        </ChangePasswordBtn>
      </form>
    </Box>
  )
}

export default Changepassword
