import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FooterContainerSecond } from './Footer.styled'
import { useLocation, useNavigate } from 'react-router'

type SocialLinksType = {
  img: string
  link: string
}[]

const SocialLinks: SocialLinksType = [
  {
    img: '/footericon/18plus.png',
    link: '',
  },
  {
    img: '/footericon/gamecare.png',
    link: '',
  },
  {
    img: '/footericon/gt.png',
    link: '',
  },
]

const sociallinkcss = {
  height: { md: '30px', xs: '30px' },
  minWidth: { md: '30px', xs: '30px' },
  borderRadius: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '10px',
}

const imgcss: any = {
  height: '30px',
  xsheight: '30px',
}

const secureimg: any = {
  maxHeight: '50px',
  maxWidth: '100px',
  marginRight: '10px',
}

const securelogocss = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  //   justifyContent: 'flex-start',
  lineHeight: 'normal',
}

const FooterBottom = () => {
  const nav = useNavigate()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) // Matches xs and down
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md')) // Matches sm to md
  const isLarge = useMediaQuery(theme.breakpoints.up('md')) // Matches md and up
  const handlerNavigate = (routeTo: string) => {
    nav(`${routeTo}`)
  }
  const { pathname } = useLocation()

  const hostname = window.location.hostname
  const newhostname = hostname.split('.')[0]

  return (
    <FooterContainerSecond>
      <Grid
        container
        sx={{
          p: '10px',
          alignItems: 'center',
          flexDirection: { md: 'row', xs: 'column' },
        }}
      >
        <Grid item xs={12} md={10}>
          <Box
            sx={{
              ...securelogocss,
              justifyContent: isSmall ? 'center' : 'flex-start',
            }}
            className="securelogo"
          >
            <img src="/footericon/ssl.png" alt="" style={{ ...secureimg }} />
            <Box>
              <Typography
                component="b"
                sx={{
                  color: '#000',
                  fontSize: { md: '16px', xs: '12px' },
                  fontFamily: 'Roboto Condensed',
                  textAlign: 'left',
                  width: '100%',
                  display: 'block',
                  fontWeight: '700',
                  lineHeight: 1,
                }}
              >
                100% SAFE
              </Typography>
              <Typography
                component="p"
                sx={{
                  color: '#000',
                  fontSize: { md: '16px', xs: '12px' },
                  fontFamily: 'Roboto Condensed',
                  textAlign: 'left',
                  lineHeight: 1,
                }}
              >
                Protected connection and encrypted data.
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={3}>
          <Typography
            component="h2"
            sx={{
              width: '100%',
              textAlign: { md: 'center' },
              fontSize: { md: '24px' },
              fontWeight: { md: '700' },
              margin: { md: 0, xs: '15px 0 0' },
              fontFamily: 'Roboto Condensed',
            }}
          >
            24X7 Support
          </Typography>
        </Grid> */}
        <Grid item xs={12} md={2} sx={{ width: '100%' }}>
          <Box
            sx={{
              m: '10px 0 0',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: { md: 'flex-end', xs: 'center' },
            }}
          >
            {SocialLinks?.map((icons, idx: number) => (
              <Button
                key={idx}
                sx={{
                  ...sociallinkcss,
                  padding: '0 !important',
                  '&:hover': {
                    backgroundColor: '#fff',
                  },
                }}
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() => handlerNavigate(icons?.link)}
              >
                {isSmall && (
                  <img
                    src={icons?.img}
                    style={{
                      height: imgcss?.xsheight,
                    }}
                    alt=""
                  />
                )}
                {isLarge && (
                  <img
                    src={icons?.img}
                    style={{
                      height: imgcss?.height,
                    }}
                    alt=""
                  />
                )}
              </Button>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography
            sx={{
              color: '#000',
              fontSize: { md: '16px', xs: '12px' },
              fontFamily: 'Roboto Condensed',
            }}
          >
            © Copyright 2022. All Rights Reserved. Powered by {newhostname}.
          </Typography>
        </Grid>
      </Grid>
    </FooterContainerSecond>
  )
}
export default FooterBottom
