import React, { FC, useEffect, useState } from 'react'
import { Typography, Box, Grid } from '@mui/material'
// import { useTheme } from '@mui/material/styles'
import { theme } from '../../../theme'
import { fancyBetPayload, FancyOddsInterface } from '../../../services/types'
import { MatchOddsMarquee } from './GameDetail.styled'
import { useParams } from 'react-router'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import { useOddsPnlQuery } from '../../../app/apis/mainApi/mainApiSlice'
import { type } from 'os'
import { postNegValue } from '../../../utils/postNegVal'

interface Props {
  matchName: string
  bookmakerData: FancyOddsInterface[]
  handleClickBetModalOpen: (args: fancyBetPayload) => void
  profit: any
}

const Bookmaker: FC<Props> = ({
  bookmakerData,
  handleClickBetModalOpen,
  matchName,
  profit,
}) => {
  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(0)

  useEffect(() => {
    setMaxValue(bookmakerData[0]?.maxBet)
    setMinValue(bookmakerData[0]?.minBet)
  }, [])

  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const sportid = searchParams.get('sportId')

  const skyLightMain = {
    bgcolor: 'back.sky',
  }
  const skyLightTwo = {
    bgcolor: 'skylightTwo.main',
  }
  const skyLight = {
    bgcolor: 'skylight.main',
  }

  const pinkMain = {
    bgcolor: 'lay.pink',
  }
  const pinkLightTwo = {
    bgcolor: 'pinklightTwo.main',
  }
  const pinkLight = {
    bgcolor: 'pinklight.main',
  }

  const itemBack = {
    borderLeft: '1px solid #999',
    height: '41px',
  }

  const itemBackOddTypography = {
    sx: {
      textTransform: 'uppercase',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    color: 'dark.main',
  }

  const itemBackNumberTypography = {
    sx: {
      textTransform: 'capitalize',
      fontSize: '12px',
      fontWeight: '600',
    },
    color: 'dark.main',
  }

  const itemLay = {
    borderLeft: '1px solid #999',
    height: '41px',
  }

  const itemLayOddTypography = {
    sx: {
      textTransform: 'uppercase',
      fontSize: '16px',
      fontWeight: '600',
    },
    color: 'dark.main',
  }

  const {
    data: oddsPnlData,
    isError,
    isSuccess,
    isLoading,
  } = useOddsPnlQuery(bookmakerData[0]?.mid, { pollingInterval: 2000 })

  console.log(oddsPnlData, 'asldkfjalksdjfk')

  const oddsPnlObj = {
    [oddsPnlData?.data?.[0]?.selection1]: oddsPnlData?.data?.[0].pnl1,
    [oddsPnlData?.data?.[0]?.selection2]: oddsPnlData?.data?.[0].pnl2,
    [oddsPnlData?.data?.[0]?.selection3]: oddsPnlData?.data?.[0].pnl3,
  }

  // useEffect(() => {
  //   if (oddsPnlData?.data) {
  //     setOddsPnll(oddsPnlData.data);
  //   }
  // }, [oddsPnlData]);

  return (
    <Box>
      {bookmakerData?.length > 0 && (
        <>
          <Box>
            <Box
              className="heading"
              sx={{
                backgroundColor: 'gdheadingbg.main',
                padding: '10px 15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
            >
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontSize: '12px',
                  color: '#fff',
                  fontWeight: '600',
                  textAlign: 'left',
                }}
              >
                bookmaker
              </Typography>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontSize: '12px',
                  color: '#fff',
                  fontWeight: '600',
                  textAlign: 'left',
                }}
              >
                {`bet delay -${bookmakerData[0]?.betDelay} sec`}
              </Typography>
            </Box>
            <Box>
              <Grid container sx={{ borderBlock: '1px solid #999' }}>
                <Grid
                  item
                  xs={8}
                  md={7.2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    height: '44px',
                    padding: '5px',
                    fontSize: '12px',
                    fontWeight: '600',
                    color: 'dark.main',
                  }}
                >
                  Max:
                  <Typography
                    component="span"
                    sx={{
                      fontSize: '12px',
                      fontWeight: '600',
                      color: 'dark.main',
                      fontFamily: 'Roboto Condensed!important',
                    }}
                  >
                    {bookmakerData?.[0]?.maxBet}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={1.2}
                  sx={{ padding: '2px', borderLeft: '1px solid #999' }}
                  bgcolor={theme.palette.back.sky}
                >
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    color={theme.palette.dark.main}
                  >
                    back
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    color={theme.palette.dark.main}
                  >
                    (lagai)
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={1.2}
                  sx={{
                    padding: '2px',
                    borderLeft: `1px solid ${theme.palette.gamedetailtablebg.main}`,
                  }}
                  bgcolor={theme.palette.lay.pink}
                >
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    color={theme.palette.dark.main}
                  >
                    lay
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    color={theme.palette.dark.main}
                  >
                    (khai)
                  </Typography>
                </Grid>
                <Grid item md={1.6} xs={0}></Grid>
              </Grid>

              {bookmakerData.map((bookMakerData) => {
                if (bookMakerData?.t == 'TOSS') return <></>
                return (
                  <Grid container sx={{ borderBottom: '1px solid #999' }}>
                    <Grid
                      item
                      xs={8}
                      md={4.8}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        height: '41px',
                        padding: '2px 5px',
                        fontSize: '12px',
                        fontWeight: '600',
                        textTransform: 'capitalize',
                      }}
                    >
                      <Typography
                        component="p"
                        sx={{
                          fontSize: '12px',
                          fontWeight: '600',
                          fontFamily: 'Roboto Condensed',
                        }}
                      >
                        {`${bookMakerData?.nation}`}
                        {postNegValue(
                          oddsPnlObj[Number(bookMakerData?.sid.toString())]
                        )}
                      </Typography>
                    </Grid>
                    <Grid container md={7.2} xs={4} position="relative">
                      {bookMakerData?.gstatus === 'SUSPENDED' ? (
                        <Box
                          position="absolute"
                          sx={{
                            cursor:
                              bookMakerData?.gstatus === 'SUSPENDED'
                                ? 'not-allowed'
                                : '',
                            fontFamily: 'Roboto Condensed',
                            letterSpacing: '1.6px',
                            width: '100%',
                            height: '100%',
                            top: 0,
                            backgroundColor: '#00000099',
                            color: '#f00',
                            fontWeight: '600',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {bookMakerData?.gstatus}
                        </Box>
                      ) : (
                        <></>
                      )}
                      <Grid
                        item
                        display={{ xs: 'none', md: 'block' }}
                        md={2}
                        sx={{ cursor: 'not-allowed' }}
                        {...itemBack}
                        {...skyLight}
                      >
                        <Typography {...itemBackOddTypography}>{0}</Typography>
                        <Typography {...itemBackNumberTypography}>
                          {bookMakerData?.maxBet}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        display={{ xs: 'none', md: 'block' }}
                        md={2}
                        sx={{ cursor: 'not-allowed' }}
                        {...itemBack}
                        {...skyLightTwo}
                      >
                        <Typography {...itemBackOddTypography}>{0}</Typography>
                        <Typography {...itemBackNumberTypography}>
                          {bookMakerData?.maxBet}
                        </Typography>
                      </Grid>

                      <Grid
                        onClick={() => {
                          id &&
                            sportid &&
                            handleClickBetModalOpen({
                              isBetfair: false,
                              betType: 'back',
                              deviceInfo: {
                                browser: 'Chrome',
                                browser_version: '114.0.0.0',
                                device: 'Macintosh',
                                deviceType: 'desktop',
                                orientation: 'landscape',
                                os: 'Mac',
                                os_version: 'mac-os-x-15',
                                userAgent:
                                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                                userIp: '180.151.224.106',
                              },
                              isBack: true,
                              isLay: false,
                              isbetfair: false,
                              liability: 0,
                              marketId: bookMakerData?.mid,
                              marketName: 'Bookmaker',
                              matchId: id,
                              matchName: matchName,
                              odds: bookMakerData?.b1,
                              placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                              priceValue: bookMakerData?.bs1,
                              selectionId: bookMakerData?.sid,
                              selectionIds: '',
                              selectionName: bookMakerData?.nation,
                              sportId: sportid,
                              stake: 0,
                              type: 'Bookmaker',
                              runner: bookmakerData,
                              minValue: minValue,
                              maxValue: maxValue,
                              isfancy: false,
                              oddsPnlObj,
                            })
                        }}
                        item
                        md={2}
                        xs={6}
                        {...itemBack}
                        {...skyLightMain}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Typography {...itemBackOddTypography}>
                          {bookMakerData?.b1}
                        </Typography>
                        <Typography {...itemBackNumberTypography}>
                          {bookMakerData?.maxBet}
                        </Typography>
                      </Grid>

                      <Grid
                        onClick={() => {
                          id &&
                            sportid &&
                            handleClickBetModalOpen({
                              deviceInfo: {
                                browser: 'Chrome',
                                browser_version: '114.0.0.0',
                                device: 'Macintosh',
                                deviceType: 'desktop',
                                orientation: 'landscape',
                                os: 'Mac',
                                os_version: 'mac-os-x-15',
                                userAgent:
                                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                                userIp: '180.151.224.106',
                              },
                              isBetfair: false,
                              betType: 'lay',
                              isBack: false,
                              isLay: true,
                              isbetfair: false,
                              liability: 0,
                              marketId: bookMakerData?.mid,
                              marketName: 'Bookmaker',
                              matchId: id,
                              matchName: matchName,
                              odds: bookMakerData?.l1,
                              placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                              priceValue: bookMakerData?.ls1,
                              selectionId: bookMakerData?.sid,
                              selectionIds: '',
                              selectionName: bookMakerData?.nation,
                              sportId: sportid,
                              stake: 0,
                              type: 'Bookmaker',
                              runner: bookmakerData,
                              minValue: minValue,
                              maxValue: maxValue,
                              isfancy: false,
                              oddsPnlObj,
                            })
                        }}
                        item
                        md={2}
                        xs={6}
                        {...itemLay}
                        {...pinkMain}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Typography {...itemLayOddTypography}>
                          {bookMakerData?.l1}
                        </Typography>
                        <Typography {...itemBackNumberTypography}>
                          {bookMakerData?.maxBet}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        display={{ xs: 'none', md: 'block' }}
                        md={2}
                        sx={{ cursor: 'not-allowed' }}
                        {...itemLay}
                        {...pinkLightTwo}
                      >
                        <Typography {...itemLayOddTypography}>{0}</Typography>
                        <Typography {...itemBackNumberTypography}>
                          {bookMakerData?.maxBet}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        display={{ xs: 'none', md: 'block' }}
                        md={2}
                        sx={{ cursor: 'not-allowed' }}
                        {...itemLay}
                        {...pinkLight}
                      >
                        <Typography {...itemLayOddTypography}>{0}</Typography>
                        <Typography {...itemBackNumberTypography}>
                          {bookMakerData?.maxBet}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
              {bookmakerData[0]?.display_message && (
                <MatchOddsMarquee className="displayMatch">
                  {bookmakerData[0]?.display_message}
                </MatchOddsMarquee>
              )}
            </Box>
          </Box>

          {/* {bookmakerData?.length < 0 && (
            <Box className="toss">
              <Box
                className="heading"
                sx={{
                  backgroundColor: 'gdheadingbg.main',
                  padding: '10px 15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                }}
              >
                <Typography
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    color: '#fff',
                    fontWeight: '600',
                    textAlign: 'left',
                  }}
                >
                  Toss
                </Typography>
                <Typography
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    color: '#fff',
                    fontWeight: '600',
                    textAlign: 'left',
                  }}
                >
                  {`bet delay -${bookmakerData[0]?.betDelay} sec`}
                </Typography>
              </Box>
              <Box>
                <Grid container sx={{ borderBlock: '1px solid #999' }}>
                  <Grid
                    item
                    xs={8}
                    md={7.2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      height: '44px',
                      padding: '5px',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: 'dark.main',
                    }}
                  >
                    Max:
                    <Typography
                      component="span"
                      sx={{
                        fontSize: '12px',
                        fontWeight: '600',
                        color: 'dark.main',
                        fontFamily: 'Roboto Condensed!important',
                      }}
                    >
                      {bookmakerData?.[0]?.maxBet}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    md={1.2}
                    sx={{ padding: '2px', borderLeft: '1px solid #999' }}
                    bgcolor={theme.palette.back.sky}
                  >
                    <Typography
                      sx={{
                        textTransform: 'uppercase',
                        fontSize: '12px',
                        fontWeight: '600',
                      }}
                      color={theme.palette.dark.main}
                    >
                      back
                    </Typography>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        fontSize: '12px',
                        fontWeight: '600',
                      }}
                      color={theme.palette.dark.main}
                    >
                      (lagai)
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    md={1.2}
                    sx={{
                      padding: '2px',
                      borderLeft: `1px solid ${theme.palette.gamedetailtablebg.main}`,
                    }}
                    bgcolor={theme.palette.lay.pink}
                  >
                    <Typography
                      sx={{
                        textTransform: 'uppercase',
                        fontSize: '12px',
                        fontWeight: '600',
                      }}
                      color={theme.palette.dark.main}
                    >
                      lay
                    </Typography>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        fontSize: '12px',
                        fontWeight: '600',
                      }}
                      color={theme.palette.dark.main}
                    >
                      (khai)
                    </Typography>
                  </Grid>
                  <Grid item md={1.6} xs={0}></Grid>
                </Grid>

                {bookmakerData.map((bookMakerData) => {
                  if (bookMakerData?.t != 'TOSS') return <></>
                  return (
                    <Grid container sx={{ borderBottom: '1px solid #999' }}>
                      <Grid
                        item
                        xs={8}
                        md={4.8}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          height: '41px',
                          padding: '2px 5px',
                          fontSize: '12px',
                          fontWeight: '600',
                          textTransform: 'capitalize',
                        }}
                      >
                        <Typography
                          component="p"
                          sx={{
                            fontSize: '12px',
                            fontWeight: '600',
                            fontFamily: 'Roboto Condensed',
                          }}
                        >
                          {`${bookMakerData?.nation}`}
                          {postNegValue(
                            oddsPnlObj[Number(bookMakerData?.sid.toString())]
                          )}
                        </Typography>
                      </Grid>
                      <Grid container md={7.2} xs={4} position="relative">
                        {bookMakerData?.gstatus === 'SUSPENDED' ? (
                          <Box
                            position="absolute"
                            sx={{
                              cursor:
                                bookMakerData?.gstatus === 'SUSPENDED'
                                  ? 'not-allowed'
                                  : '',
                              fontFamily: 'Roboto Condensed',
                              letterSpacing: '1.6px',
                              width: '100%',
                              height: '100%',
                              top: 0,
                              backgroundColor: '#00000099',
                              color: '#f00',
                              fontWeight: '600',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {bookMakerData?.gstatus}
                          </Box>
                        ) : (
                          <></>
                        )}
                        <Grid
                          item
                          display={{ xs: 'none', md: 'block' }}
                          md={2}
                          sx={{ cursor: 'not-allowed' }}
                          {...itemBack}
                          {...skyLight}
                        >
                          <Typography {...itemBackOddTypography}>
                            {0}
                          </Typography>
                          <Typography {...itemBackNumberTypography}>
                            {bookMakerData?.maxBet}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          display={{ xs: 'none', md: 'block' }}
                          md={2}
                          sx={{ cursor: 'not-allowed' }}
                          {...itemBack}
                          {...skyLightTwo}
                        >
                          <Typography {...itemBackOddTypography}>
                            {0}
                          </Typography>
                          <Typography {...itemBackNumberTypography}>
                            {bookMakerData?.maxBet}
                          </Typography>
                        </Grid>

                        <Grid
                          onClick={() => {
                            id &&
                              sportid &&
                              handleClickBetModalOpen({
                                isBetfair: false,
                                betType: 'back',
                                deviceInfo: {
                                  browser: 'Chrome',
                                  browser_version: '114.0.0.0',
                                  device: 'Macintosh',
                                  deviceType: 'desktop',
                                  orientation: 'landscape',
                                  os: 'Mac',
                                  os_version: 'mac-os-x-15',
                                  userAgent:
                                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                                  userIp: '180.151.224.106',
                                },
                                isBack: true,
                                isLay: false,
                                isbetfair: false,
                                liability: 0,
                                marketId: bookMakerData?.mid,
                                marketName: 'Bookmaker',
                                matchId: id,
                                matchName: matchName,
                                odds: bookMakerData?.b1,
                                placeTime: moment().format(
                                  'YYYY-MM-DD hh:mm:ss'
                                ),
                                priceValue: bookMakerData?.bs1,
                                selectionId: bookMakerData?.sid,
                                selectionIds: '',
                                selectionName: bookMakerData?.nation,
                                sportId: sportid,
                                stake: 0,
                                type: 'Bookmaker',
                                runner: bookmakerData,
                                minValue: minValue,
                                maxValue: maxValue,
                                isfancy: false,
                              })
                          }}
                          item
                          md={2}
                          xs={6}
                          {...itemBack}
                          {...skyLightMain}
                          sx={{ cursor: 'pointer' }}
                        >
                          <Typography {...itemBackOddTypography}>
                            {bookMakerData?.b1}
                          </Typography>
                          <Typography {...itemBackNumberTypography}>
                            {bookMakerData?.maxBet}
                          </Typography>
                        </Grid>

                        <Grid
                          onClick={() => {
                            id &&
                              sportid &&
                              handleClickBetModalOpen({
                                deviceInfo: {
                                  browser: 'Chrome',
                                  browser_version: '114.0.0.0',
                                  device: 'Macintosh',
                                  deviceType: 'desktop',
                                  orientation: 'landscape',
                                  os: 'Mac',
                                  os_version: 'mac-os-x-15',
                                  userAgent:
                                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                                  userIp: '180.151.224.106',
                                },
                                isBetfair: false,
                                betType: 'lay',
                                isBack: false,
                                isLay: true,
                                isbetfair: false,
                                liability: 0,
                                marketId: bookMakerData?.mid,
                                marketName: 'Bookmaker',
                                matchId: id,
                                matchName: matchName,
                                odds: bookMakerData?.l1,
                                placeTime: moment().format(
                                  'YYYY-MM-DD hh:mm:ss'
                                ),
                                priceValue: bookMakerData?.ls1,
                                selectionId: bookMakerData?.sid,
                                selectionIds: '',
                                selectionName: bookMakerData?.nation,
                                sportId: sportid,
                                stake: 0,
                                type: 'Bookmaker',
                                runner: bookmakerData,
                                minValue: minValue,
                                maxValue: maxValue,
                                isfancy: false,
                              })
                          }}
                          item
                          md={2}
                          xs={6}
                          {...itemLay}
                          {...pinkMain}
                          sx={{ cursor: 'pointer' }}
                        >
                          <Typography {...itemLayOddTypography}>
                            {bookMakerData?.l1}
                          </Typography>
                          <Typography {...itemBackNumberTypography}>
                            {bookMakerData?.maxBet}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          display={{ xs: 'none', md: 'block' }}
                          md={2}
                          sx={{ cursor: 'not-allowed' }}
                          {...itemLay}
                          {...pinkLightTwo}
                        >
                          <Typography {...itemLayOddTypography}>{0}</Typography>
                          <Typography {...itemBackNumberTypography}>
                            {bookMakerData?.maxBet}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          display={{ xs: 'none', md: 'block' }}
                          md={2}
                          sx={{ cursor: 'not-allowed' }}
                          {...itemLay}
                          {...pinkLight}
                        >
                          <Typography {...itemLayOddTypography}>{0}</Typography>
                          <Typography {...itemBackNumberTypography}>
                            {bookMakerData?.maxBet}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
                {bookmakerData[0]?.display_message && (
                  <MatchOddsMarquee className="displayMatch">
                    {bookmakerData[0]?.display_message}
                  </MatchOddsMarquee>
                )}
              </Box>
            </Box>
          )} */}
        </>
      )}
    </Box>
  )
}

export default Bookmaker
