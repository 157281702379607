import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'
import { BeforeAfter, InplayHeading } from './inplay.styled'

interface Props {
    name: string
}

const InPlayHeading: FC<Props> = ({ name }) => {
    return (
        <InplayHeading>
            <BeforeAfter>
                <Typography component='p' sx={{ backgroundColor: '#bb1919', paddingRight: '50px' }}>
                    {name}
                </Typography>
            </BeforeAfter>
        </InplayHeading>
    )
}

export default InPlayHeading