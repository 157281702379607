import { useEffect, type FC } from 'react'
import './placebet.scss'
import { IoMdClose } from 'react-icons/io'
// import { useGetCasinoBetPlacedMutation } from '../../store/service/userServices/userServices'
// import snackbarUtil from '../../utils/Snackbar'

// interface Props {
//   handleClose: () => void;
//   betState: any;
//   setBetState: any;
//   userIp: string;
//   setOpenModals: any;
//   data: any;
//   isLoading: boolean;
//   trigger: any;
// }

const stack: number[] = [100, 200, 500, 1000, 5000, 10000, 20000]

const CasinoBetPlace: FC<any> = ({
  handleClose,
  betState,
  setBetState,
  userIp,
  setOpenModals,
  isLoading,
  data,
  trigger,
}) => {
  const handleStack = (val: number) => {
    setBetState((prev: any) => ({
      ...prev,
      stake: val,
    }))
  }
  const handleStackChange = (e: any) => {
    const { value } = e.target
    setBetState((prev: any) => ({
      ...prev,
      stake: Number(value),
    }))
  }

  const handleCasinoBetPlaced = () => {
    setOpenModals(false)
    trigger({
      ...betState,
      userIp,
    })
    setBetState((prev: any) => ({
      ...prev,
      stake: 0,
    }))
  }

  return (
    <div className="accordian-view-modals">
      <div className="card">
        <div className="card-header2">
          <div className="w-100">
            <h5 className="m-0">PLACE BET</h5>
          </div>
          <div onClick={handleClose}>
            <IoMdClose
              style={{
                color: '#fff',
                fontSize: '19px',
              }}
            />
          </div>
        </div>
        <div className={`card-body ${betState?.isBack ? 'back' : 'lay'}`}>
          <div className="personal-info-content">
            {/* <table className="w-100">
              <thead>
                <tr>
                  <th>Bet for</th>
                  <th>Odds</th>
                  <th>Stake</th>
                  <th>Profit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{betState?.nation}</td>
                  <td></td>
                  <td></td>
                  <td>{betState?.odds}</td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="bet-input ng-pristine ng-valid ng-touched"
                      value={betState?.stake}
                      onChange={handleStackChange}
                    />
                  </td>
                  <td>
                    {betState?.colorName === 'back'
                      ? ((betState?.odds - 1) * betState?.stake)?.toFixed(2)
                      : '0'}
                  </td>
                </tr>
              </tbody>
            </table> */}
            <div className="cosino_head_d">
              <div className="col_head">
                <b className="nation_name">{betState?.nation}</b>
              </div>
              <div className="col_head">
                <div className="float-end">
                  <input
                    type="text"
                    className="stakeinput"
                    disabled
                    value={betState?.odds}
                  />
                </div>
              </div>
            </div>
            <div className="cosino_head_d">
              <div className="cosino_stack">
                <input
                  type="number"
                  className="stakeinput w-100"
                  value={betState?.stake}
                  onChange={handleStackChange}
                />
              </div>
              <div className="cosino_stack1 d-grid">
                <button
                  className="btn btn-primary btn-block"
                  onClick={handleCasinoBetPlaced}
                >
                  Submit
                </button>
              </div>
              <div className="cosino_stack3 pt-2 text-center">
                <span>
                  {' '}
                  {betState?.colorName === 'back'
                    ? ((betState?.odds - 1) * betState?.stake)?.toFixed(2)
                    : '0'}
                </span>
              </div>
            </div>
            <div className={`tags ${betState?.isBack ? 'back' : 'lay'}`}>
              {stack?.map((num) => (
                <div
                  key={num}
                  className="single-tag"
                  onClick={() => handleStack(num)}
                >
                  {num}{' '}
                </div>
              ))}
            </div>
            {/* <div
              className="btns d-flex justify-content-between w-100"
              style={{
                padding: '6px 12px',
                boxSizing: 'border-box',
              }}
            >
              <button className="cancel" onClick={handleClose}>
                Cancel
              </button>
              <button className="submit" onClick={handleCasinoBetPlaced}>
                Submit
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CasinoBetPlace
