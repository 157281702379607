import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'
import { IoMdClose } from 'react-icons/io'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import { useParams } from 'react-router'
import { postNegValue } from '../utils/postNegVal'
import { useSessionBookResQuery } from '../app/apis/mainApi/mainApiSlice'

interface props {
  sessionModal: string
  setSessionModal: Dispatch<SetStateAction<string>>
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiTypography-root': {
    padding: '8px 12px',
    // fontSize: '18px',
    fontWeight: 600,
  },

  '& .MuiPaper-root': {
    margin: 20,
    borderRadius: 0,
    padding: 0,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
      width: '100%',
    },
  },
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
    marginTop: 0,
    maxWidth: 564,
    margin: 'auto',
    padding: 0,
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle
      sx={{
        m: 0,
        // p: 2,
        backgroundColor: '#2C3E50',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: '#fff',
          }}
        >
          <IoMdClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const SessionBookModal: FC<props> = ({ sessionModal, setSessionModal }) => {
  const handleSessionModalClose = () => {
    setSessionModal('')
  }
  const { id } = useParams()

  if (!(sessionModal && id)) return <></>

  const { data: sessionBookRes } = useSessionBookResQuery(
    {
      matchId: id || '',
      marketId: sessionModal,
    },
    { refetchOnMountOrArgChange: true }
  )

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={!!sessionModal}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleSessionModalClose}
      >
        Session book
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <table style={{ display: 'flex', flexDirection: 'column' }}>
          <thead style={{ width: '100%' }}>
            <tr
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <th style={{ width: '50%', fontSize: '12px' }}>Run</th>
              <th style={{ width: '50%', fontSize: '12px' }}>Profit/Loss</th>
            </tr>
          </thead>
          <tbody>

            {sessionBookRes?.data?.length !== 0 && sessionBookRes?.data?.map((ratepnl) => (
              <tr
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <td
                  style={{
                    width: '50%',
                    fontFamily: 'Roboto Condensed',
                    fontSize: '12px'
                  }}
                >
                  {ratepnl?.rates}
                </td>
                <td
                  style={{
                    fontFamily: 'Roboto Condensed',
                    width: '50%',
                    fontSize: '12px'
                  }}
                >

                  {postNegValue(ratepnl?.pnl)}

                </td>
              </tr>
            ))}

            {!(sessionBookRes?.data && sessionBookRes?.data?.length > 0) && <Box
              sx={{
                color: '#2c3e50',
                fontSize: '12px',
                backgroundColor: '#e4e4e4',
                margin: '4px 0 0',
                padding: '6px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'roboto'
              }}
            >
              No data found!
            </Box>}

          </tbody>
        </table>
      </DialogContent>
    </BootstrapDialog>
  )
}

export default SessionBookModal
