import { Button, useMediaQuery, useTheme } from '@mui/material'
import './mybet.scss'
import { useNavigate } from 'react-router-dom'
// import { useGetCasinoMyBetQuery } from "../../../store/service/userServices/userServices";
import { useEffect } from 'react'
import { useGetCasinoMyBetQuery } from '../../../app/apis/casinoApi/casinoApiSlice'

const Mybet = ({ tableId }: any) => {
  const nav = useNavigate()
  // const betList: any = []
  const { data: betList } = useGetCasinoMyBetQuery(
    { tableId: tableId, isGameCompleted: false, sportId: 5015 },
    { pollingInterval: 1500, refetchOnMountOrArgChange: true }
  )
  const handleCasinoBet = () => {
    nav('/liveCasinoBet')
  }

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) // Matches xs and down
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md')) // Matches sm to md
  const isLarge = useMediaQuery(theme.breakpoints.up('md')) // Matches md and up

  return (
    <div className="accordian-view ">
      <div className="card my-bet ">
        {isLarge && (
          <div className="card-header ">
            <div className="w-100 ">
              <h5 className="m-0 ">MY BET</h5>
            </div>
          </div>
        )}
        <div className="card-body ">
          <div className="personal-info-content">
            <table className="w-100 ">
              <thead>
                <tr className="casino_bet_head">
                  <th>Matched Bet</th>
                  {/* <th>Market</th> */}
                  <th>Odds</th>
                  <th>Stake</th>
                </tr>
              </thead>
              <tbody className=" ng-star-inserted">
                {betList?.data?.map((items: any) => (
                  <tr
                    className={`casino_bet_list ${
                      items?.back ? 'back' : 'lay'
                    }`}
                  >
                    <td>
                      {items?.selectionName}
                      {/* ({items?.roundId}) */}
                    </td>
                    {/* <td>{items?.gameName}</td> */}
                    <td>{items?.odds}</td>
                    <td>{items?.stake}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="justify-content-center " style={{ marginBottom: 10 }}>
        {/* <Button
          className="comp_bet"
          onClick={handleCasinoBet}
          sx={{
            background: 'linear-gradient( #0088cc, #2c3e50)',
            borderRadius: 1,
          }}
        >
          See All Complete Bets
        </Button> */}
      </div>
    </div>
  )
}

export default Mybet
