import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { LoginRes, changePassword, accountStatementPayload, accountStatementResponse, ActiveAllCasinoRes, allactivecasinoresponse, BalanceRes, betHistoryPayload, betHistoryResponse, casinoResult, casinoResultPayload, fancyBetPayload, getActiveSports, getSessionBookRes, MatchNamesRes, oddsPnlPayload, profitandlosspayload, profitandlossResponse, unsetteledPayload, unsetteledResponse, getuserStackRes, stackValuePayload, stackValuesRes, fancyPnlRes, ErrorRes, oddsBetApiPayload, oddsBetApiResponse, virtualcasinoResponse, virtualcasinoPayload, virtualcasinoliabilityResponse, virtualcasinoliabilityPayload, casinoresultpayload, casinoresultresponse, virtualcasinoliabilityData } from '../../../services/types';
import snackBarUtil from '../../../utils/snackbar';
import { logout } from '../../features/auth/authSlilce';

const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_KALYANEXC_URL,
    prepareHeaders: headers => {
        // const token = import.meta.env.VITE_FASTBET_TOKEN;
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        }
    },
});

const wrapperQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    // if (result?.error?.status === 401) api.dispatch(logout());
    // if (result?.error?.status === 403) api.dispatch(logout());
    // if ((result.data as ErrorRes).responseCode === 401) api.dispatch(logout());
    // if ((result.data as ErrorRes).responseCode === 403) api.dispatch(logout());
    return result;
};


const casinoApi = createApi({
    reducerPath: 'casinoapi',
    baseQuery: wrapperQuery,
    endpoints: (build) => {
        return {
            casinoList: build.query<CasinoListRes, CasinoListPayload>({
                query: (payload) => ({
                    url: 'casino/casino-list',
                    method: 'POST',
                    body: payload
                }),
            }),
            casinoBetListUser: build.mutation<CasinoBetListUserRes, CasinoBetListUserPayload>({
                query: (payload) => ({
                    url: 'casino/casino-bet-list-user',
                    method: 'POST',
                    body: payload
                })
            }),
            getCasinoBetPlaced: build.mutation<any, any>({
                query: (body) => ({
                    url: `casino/place-bet`,
                    method: "POST",
                    body
                }),
            }),
            getCasinoMyBet: build.query<mybetResponce, mybetRequest>({
                query: (payload) => ({
                    url: `/casino/casino-bet-list-user`,
                    method: "POST",
                    body: payload
                }),
            }),
            getBetListLedger: build.mutation<BetListLegdgerRes, BetListLegdgerProps>({
                query: (body) => ({
                    url: `/casino/bet-list-ledger`,
                    method: "POST",
                    body
                }),
            }),
            getLedgerBetDetails: build.mutation<LedgerListData, LedgerReq>({
                query: (body) => ({
                    url: `/enduser/get-enduser-bet-detail`,
                    method: "POST",
                    body
                }),
            }),
            LedgerDetails: build.mutation<LedgerDetailsRes, LedgerDetailsReq>({
                query: (body) => ({
                    url: "/ledger/get-ledger-cash-trans-userid",
                    method: "POST",
                    body,
                }),
            }),

            virtualCasinoData: build.query({
                query: (vcasinodata) => {
                    return {
                        url: `casautomation/GetData/${vcasinodata}`,
                        method: 'GET'
                    }
                }
            }),
            virtualCasinoResult: build.query({
                query: (vcasinoresult) => {
                    return {
                        url: `casautomation/GetData/${vcasinoresult}`,
                        method: 'GET'
                    }
                }
            }),
            // casinoLiability: build.mutation<any,casinoLiabilityPayload>({
            //     query: (payload) => ({
            //         url: '/casino/liability',
            //         method: 'POST',
            //         body: payload
            //     })
            // })
        }

    }
})

export const {
    useCasinoListQuery,
    useCasinoBetListUserMutation,
    useGetCasinoBetPlacedMutation,
    useGetCasinoMyBetQuery,
    useGetBetListLedgerMutation,
    useGetLedgerBetDetailsMutation,
    useLedgerDetailsMutation,
    useVirtualCasinoDataQuery,
    useVirtualCasinoResultQuery,
    // useCasinoLiabilityMutation,
} = casinoApi;

export default casinoApi;