import { FC } from "react";
import { Cardcompostyled } from "./cardcomp.styled";

interface Props {
    shown: boolean;
    card: string;
}

const CardComp: FC<Props> = ({ shown, card }) => {
    return (
        <>
            <Cardcompostyled>
                <div className={`flip-card ${shown}`}>
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="card_back" src='http://admin.kalyanexch.com/images/cards/backcover.png' />
                        </div>
                        <div className="flip-card-back">
                            <img
                                className="card_front"
                                src={`http://admin.kalyanexch.com/images/cards/${card}.png`}
                            />
                        </div>
                    </div>
                </div>
            </Cardcompostyled>
        </>
    );
};

export default CardComp;