import { useEffect, useState } from "react";
import { T1 } from "../../services/types";
import { useParams } from "react-router";

type OddsData = {

}

export const useOdds = (value: string) => {
  const { id: vcasinoid } = useParams()
  const [odds, setOdds] = useState<any>(null);
  const [pnl, setPnl] = useState<{ [x: string]: number }>({});
  const [betPlace, setBetPlace] = useState(false);
  const token = localStorage.getItem("token");
  // const token = import.meta.env.VITE_FASTBET_TOKEN;
  const vCasinot20 = (vcasinoid == '71' || vcasinoid == '72' || vcasinoid == '73' || vcasinoid == '74' || vcasinoid == '75' || vcasinoid == '76' || vcasinoid == '77') ? `http://52.140.1.186:8080/casautomation/GetData/${value}Data` :
    `https://oddsapi.247idhub.com/betfair_api/casino/data/meta-${value}`
  console.log(value, vCasinot20, 'kjasd')


  useEffect(() => {
    const timer = setInterval(() => {
      value &&
        fetch(vCasinot20)
          .then((res: any) =>
            // vcasinoid == '73' ? res.data?.json():
            res.json())
          .then((res) => {
            const valRes = vcasinoid == '71' || vcasinoid == '72' || vcasinoid == '73' ||
              vcasinoid == '74' || vcasinoid == '75' || vcasinoid == '76' || vcasinoid ==
              '77' ? res?.data : res
            console.log(valRes, 'ress')
            if (Array.isArray(valRes?.bf)) {
              convertBfToT2(valRes.data);
            }
            if (Array.isArray(valRes?.t2)) {
              if (Array.isArray(valRes?.t3)) {
                valRes.t2 = [...valRes.t2, ...valRes.t3];
              }
              valRes.t2BySid = {};
              valRes?.t2?.forEach((item: any) => {
                if (!item.nation && item.nat) {
                  item.nation = item.nat;
                }
                item.gstatus =
                  Number(item.gstatus) === 1
                    ? true
                    : Number(item.gstatus) === 0
                      ? false
                      : item.gstatus;
                item.pnl = pnl[item.sid] || 0;
                valRes.t2BySid[item.sid] = item;
              });
            }
            setOdds(res);
          });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [pnl, value]);

  useEffect(() => {
    return () => setOdds(null);
  }, [value])

  useEffect(() => {
    const timer = setInterval(() => {
      Number(odds?.t1?.[0]?.mid) &&
        fetch(
          `https://client-api.kalyanexch.com/casino/liability`,
          {
            body: JSON.stringify({
              roundId: odds?.t1?.[0].mid,
            }),
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
          }
        )
          .then((res) => res.json())
          .then((res) => {
            if (res.data) {
              const pnl: { [x: string]: number } = {};
              let i: { sid: string; liability: number };
              for (i of res.data) {
                pnl[i.sid] = i.liability;
              }
              setPnl(pnl);
            } else {
              setPnl({});
            }
          });

    }, 2000)
    return () => {
      clearInterval(timer);
    };
  }, [betPlace, odds?.t1?.[0].mid]);
  return { odds, setBetPlace, pnl };
};


const convertBfToT2 = (oddData: any | OddsData) => {
  if (oddData?.data?.bf && oddData?.data?.bf?.length > 0) {
    oddData.data.bf.forEach((bfElement: any, index: number) => {
      const t2Obj = {
        mid: bfElement.marketId,
        sid: bfElement.sectionId,
        max: +bfElement.max,
        min: +bfElement.min,
        gstatus: bfElement.gstatus,
        gtype: bfElement.gameType,
        nation: bfElement.nation,
        nat: bfElement.nation,
        rate: "",
        b1: `${bfElement.b1}`,
        l1: `${bfElement.l1}`,
        bs1: `${bfElement.bs1}`,
        ls1: `${bfElement.ls1}`,
      };
      const t1Obj: T1 | any = {
        mid: bfElement.marketId,
        autotime: bfElement.UpdateTime,
        gtype: bfElement.gameType,
        remark: bfElement.remark,
        max: +bfElement.max,
        min: +bfElement.min,
      };

      if (index === 0) {
        t1Obj.C1 = bfElement.C1;
        t1Obj.C3 = bfElement.C2;
        t1Obj.C5 = bfElement.C3;
      }
      if (index === 1) {
        t1Obj.C2 = bfElement.C1;
        t1Obj.C4 = bfElement.C2;
        t1Obj.C6 = bfElement.C3;
      }
      if (Array.isArray(oddData.data.t1)) {
        const newt1Obj = [{ ...oddData.data.t1?.[0], ...t1Obj }];
        oddData.data.t1 = newt1Obj;
      } else {
        oddData.data.t1 = [t1Obj];
      }

      if (Array.isArray(oddData.data.t2)) {
        oddData.data.t2.push(t2Obj);
      } else {
        oddData.data.t2 = [t2Obj];
      }
    });
  }
}