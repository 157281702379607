import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      status: {
        danger: string
      }
      info: {
        main: string
      }
      primary: {
        main: string
      }
      secondary: {
        main: string
      }
      dark: {
        main: string
      }
      back: {
        sky: string
      }
      lay: {
        pink: string
      }
      border: {
        lightgray: string
        lightgray2: string
      }
      tablebg: {
        main: string
      }
      gamedetailtablebg: {
        main: string
      }
      gdheadingbg: {
        main: string
      }
      tabsbgclr: {
        main: string
      }
      skylightTwo: {
        main: string
      }
      skylight: {
        main: string
      }
      pinklight: {
        main: string
      }
      pinklightTwo: {
        main: string
      }
      inputtextcolor: {
        main: string
      }

    }
  }
}

const tokensLight = {
  status: {
    danger: 'red',
  },
  info: {
    main: '#fff',
  },
  primary: {
    main: '#0088cc',
  },
  secondary: {
    main: '#ffffff',
  },
  dark: {
    main: '#2C3E50',
  },
  back: {
    sky: '#72bbef',
  },
  lay: {
    pink: '#faa9ba',
  },
  border: {
    lightgray: '#D4D4D4',
    lightgray2: '#c7c8ca'
  },
  tablebg: {
    main: '#EDEDED',
  },
  gamedetailtablebg: {
    main: '#999999',
  },
  gdheadingbg: {
    main: '#2C3E50D9',
  },
  tabsbgclr: {
    main: '#0088CC',
  },
  skylightTwo: {
    main: '#72BBEFBF',
  },
  skylight: {
    main: '#72BBEF80',
  },
  pinklight: {
    main: '#FAA9BA80',
  },
  pinklightTwo: {
    main: '#FAA9BABF',
  },
  inputtextcolor: {
    main: '#495057',
  },

}

export const theme = createTheme({
  palette: {
    ...tokensLight,
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
        },
      },
    },
  },
})
