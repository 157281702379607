import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router'
import GameDetailPage from '../component/common/GameDetail/GameDetailPage'
import Header from '../component/common/Header/Header'
import SecondHeader from '../component/common/Header/SecondHeader'
import RuleModal from '../component/common/RuleModal'
import { LoginComponent } from '../component/Login/LoginComponent'
import AccountStatement from '../screens/AccountStatement/AccountStatement'
import BetHistory from '../screens/BetHistory/BetHistory'
import CasinoResult from '../screens/CasinoResultPage/CasinoResult'
import Changepassword from '../screens/Changepassword/Changepassword'
import Home from '../screens/Home/Home'
import InPlayPage from '../screens/InPlay/InPlayPage'
import ProfitAndLoss from '../screens/ProfitandLoss/ProfitAndLoss'
import RulesPage from '../screens/Rules/RulesPage'

import ChangeButtonValues from '../screens/StakeButtonValue/ChangeButtonValues'
import Unsetledbet from '../screens/Unsetlledbet/Unsetledbet'
import VirtualCasinoPage from '../screens/VirtualCasinoPage/VirtualCasinoPage'
import VirtualCasinoTabs from '../screens/VirtualCasinoPage/VirtualCasinoTabs'
import VteenPattiDragonTiger from '../screens/VteenDragonTiger/VteenPattiDragonTiger'
import VteenPatti20 from '../screens/VteenPatti20/VteenPatti20'
import VteenPattiAAA from '../screens/VteenPattiAmarAkbarAnthony/VteenPattiAAA'
import VteenPattiBollywoodTable from '../screens/VteenPattiBollywoodTable/VteenPattiBollywoodTable'
import VteenPattiLuckySeven from '../screens/VteenPattiLuckySeven/VteenPattiLuckySeven'
import VteenPattiOpen from '../screens/VteenPattiOpen/VteenPattiOpen'
import LiveCasinoPage from '../screens/LiveCasinoPage/LiveCasinoPage'
import CasinoMainPage from '../Casino_New/CasinoMainPage/CasinoMainPage'
import CasinoBet from '../Casino_New/LiveTennPatti/CasinoBet/CasinoBet'
import Footer from '../component/common/Footer/Footer'
import FooterBottom from '../component/common/Footer/FooterBottom'
import { Box } from '@mui/material'

interface props {
  MenuOpen: boolean
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Layout = ({ MenuOpen, setMenuOpen }: props) => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)

  const { pathname } = useLocation()

  const protectRoutetoken = localStorage.getItem('token')

  useEffect(() => {
    if (protectRoutetoken) {
      if (pathname.includes('login')) {
        navigate('/')
      }
    } else {
      navigate('login')
    }
  }, [])

  return (
    <>
      <RuleModal open={open} setOpen={setOpen} />
      <Routes>
        <Route
          path="login"
          element={<LoginComponent setOpen={setOpen} />}
        ></Route>

        {protectRoutetoken ? (
          <Route
            path=""
            element={<Header MenuOpen={MenuOpen} setMenuOpen={setMenuOpen} />}
          >
            <Route path="" element={<SecondHeader />}>
              <Route path="" element={<Home />} />
              <Route path="inplay" element={<InPlayPage />} />
              <Route path="/accountstatement" element={<AccountStatement />} />
              <Route path="/profitandloss" element={<ProfitAndLoss />} />
              <Route path="/bethistory" element={<BetHistory />} />
              <Route path="/unsetledbet" element={<Unsetledbet />} />
              <Route path="/casinoresult" element={<CasinoResult />} />
              <Route
                path="/changebuttonValue"
                element={<ChangeButtonValues />}
              />
              <Route path="/rules" element={<RulesPage />} />
              <Route path="/changepassword" element={<Changepassword />} />
              <Route path="/gamedetail/:id" element={<GameDetailPage />} />
              <Route path="/livecasino" element={<LiveCasinoPage />} />
              <Route path="/casino/:id/:tableId" element={<CasinoMainPage />} />
              <Route
                path="/vcasino/:id/:tableId"
                element={<CasinoMainPage />}
              />
              <Route path="/liveCasinoBet" element={<CasinoBet />} />
              {/* <Route path="virtualcasino" element={<VirtualCasinoTabs />}> */}
              <Route path="/virtualcasino" element={<VirtualCasinoPage />} />
              <Route path="vteenpatti20" element={<VteenPatti20 />} />
              <Route path="vteenpattiopen" element={<VteenPattiOpen />} />
              <Route path="vdt20" element={<VteenPattiDragonTiger />} />
              <Route path="vlucky7" element={<VteenPattiLuckySeven />} />
              <Route path="vaaa" element={<VteenPattiAAA />} />
              <Route path="vbwd" element={<VteenPattiBollywoodTable />} />
              <Route path="*" element={<></>} />
              {/* </Route> */}
            </Route>
          </Route>
        ) : (
          ''
        )}
      </Routes>
      <Box
        sx={{
          position: pathname.includes('login') ? 'fixed' : 'relative',
          bottom: 0,
          left: 0,
          width: '100%',
        }}
      >
        <Footer />
        {pathname.includes('login') ? '' : <FooterBottom />}
      </Box>
    </>
  )
}

export default Layout
