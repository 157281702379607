export const ruleTypography = [
  { key: '1.', text: 'If you not accept this agreement do not place any bet.' },
  {
    key: '2.',
    text: 'Cheating bets deleted automatically or manual. No Claim.',
  },
  { key: '3.', text: 'Our exchange decision is final and no claim on it.' },
  {
    key: '4.',
    text: 'If any case wrong rate has been given in fancy that particular bets will be cancelled.',
  },
  { key: ' 5.', text: 'We do not accept manual bet.' },
  {
    key: '6.',
    text: 'In case of cheating and betting in unfair rates we will cancel the bet even after settling.',
  },
  { key: '7.', text: 'Local fancy are based on Haar - Jeet.' },
  {
    key: '8.',
    text: 'In next men out fancy if player is injured particular fancy will be deleted.',
  },
  {
    key: '9.',
    text: 'Once our exchange give username and password its your responsibility to change a password.',
  },
  {
    key: '10.',
    text: 'Incomplete session will be cancelled but complete session will be settled.',
  },
  {
    key: '11.',
    text: 'In case of match abandoned, cancelled, no result etc. completed sessions will be settled.',
  },
  {
    key: '12.',
    text: 'In case of a Tie match, only match odds will be cancelled , completed sessions will be continue.',
  },
  { key: '13.', text: "Advance Lambi 1'st Inning Valid Only." },
  {
    key: '14.',
    text: 'Due to any technical issue software not work properly at that time we are not responsible for any loss.',
  },
]
