import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
// import { TopTab } from './GameDetail.styled'
import Typography from '@mui/material/Typography'
import React, { useEffect, useMemo, useState } from 'react'
// import { TopTabs } from './GameDetail.styled'
import { Outlet, useOutletContext, useParams } from 'react-router'
// import GameDetail from './GameDetail'
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
// import MatchedBet from './MatchedBet'
// import { AiOutlineDesktop } from 'react-icons/ai'
import {
  TopTab,
  TopTabs,
} from '../../component/common/GameDetail/GameDetail.styled'
// import MatchedBet from '../../component/common/GameDetail/MatchedBet'
import VirtualCasinoHeader from '../../component/common/VirtualAndLiveCasino/VirtualCasinoHeader'
// import VirtualMatchedBet from './VirtualMatchedBet'
// import { CardSize, ParentWrapper, Virtuacasinoltable } from '../VteenPattiOpen/virtualpattiopen.styled'
import { FiArrowRight } from 'react-icons/fi'
import moment from 'moment'
import virtualModalrules from '../../../public/img/5041Rules.jpeg'
import VirtualCasinoModal from '../../component/common/VirtualCasinoModal/VirtualCasinoModal'
import { BsFillPlayFill } from 'react-icons/bs'
import VirtualMatchedBet from '../VteenPatti20/VirtualMatchedBet'
import Suspendedlock from '../../component/common/Suspendedlock'
import {
  CardSize,
  CustomTypography,
  DragonTigerButton,
  ParentWrapper,
  Virtuacasinoltable,
} from './virtualteenpattiluckyseven.styled'
import { database } from '../../firebase'
import {
  T2,
  virtualcasinoResult,
  virutalcasinoData,
} from '../../services/types'
import CardComp from '../../component/common/CardComp'
import Lastresult from '../../component/common/Lastresult'
import { useSearchParams } from 'react-router-dom'
import { useGetBetListByMatchResQuery } from '../../app/apis/mdkalyanExchApi/mdkalyanExchApiSlice'
import { useVirtualcasinoliabilityQuery } from '../../app/apis/mainApi/mainApiSlice'
// import { dataobj } from '../../utils/transformingdata'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
  className: string
}

const getByC1 = (odds?: virutalcasinoData) => {
  const c1 = odds?.t1?.[0]?.C1.charAt(0) || ''
  if (c1 === '') return false
  if (['A', '2', '3', '4', '5', '6'].includes(c1)) {
    return 1
  }
  if (['8', '9', '1', 'J', 'K', 'Q'].includes(c1)) {
    return 2
  }
  if ('7' === c1) {
    return 3
  }
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Box sx={{ p: 0 }}>{children}</Box>
        </Box>
      )}
    </div>
  )
}

const VteenPattiLuckySeven = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const eventId = searchParams.get('id')
  const theme = useTheme()
  const [value, setValue] = React.useState(0)
  const [virtualModal, setVirtualModal] = useState(false)
  const [odds, setOdds] = useState<virutalcasinoData>()
  const [result, setResult] = useState<virtualcasinoResult>()

  useEffect(() => {
    const betex = database.ref('betex/lucky7a')
    betex.on('value', (snapshot) => {
      const odds = snapshot.val()?.data.data
      odds.t2 =
        odds.t2?.reduce((accu: { [x: string]: T2 }, curr: T2) => {
          accu[curr.sid] = curr
          return accu
        }, {}) || {}
      setOdds(odds)
      setResult(snapshot.val()?.result)
    })
    return () => {
      betex.off()
    }
  }, [])

   
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue)
  }
  // const { data: betlistmatch } = useGetBetListByMatchResQuery({ eventId: Number(id) })
  const virtual2020teenpatti = () => {
    setVirtualModal(true)
  }
 


  const { handleClickBetModalOpen
  } = useOutletContext<any>()

  const { data: luckysevenpnldata } = useVirtualcasinoliabilityQuery(
    {
      eventid: eventId || '',
      roundid: odds?.t1?.[0]?.mid || ''
    }
  )
  

  const { data: getBetlistmatch } = useGetBetListByMatchResQuery({ eventId: Number(eventId) });

  const pnlmemo = useMemo(() => {
    if (odds?.t2?.[6]?.sid && luckysevenpnldata?.dataObj?.[odds?.t2?.[6]?.sid]?.total_pnl) {
      return luckysevenpnldata?.dataObj?.[odds?.t2?.[6]?.sid]?.total_pnl;
    } else { return 0 }
  }, [odds, luckysevenpnldata]);



  return (
    <Box sx={{ width: { md: 'xl' } }}>
      <VirtualCasinoModal
        virtualModal={virtualModal}
        setVirtualModal={setVirtualModal}
      />

      <Paper
        elevation={10}
        sx={{
          bgcolor: 'tabsbgclr.main',
          height: 35,
          borderRadius: 0,
        }}
      >
        <TopTabs
          sx={{
            '& .MuiButtonBase-root': {
              width: '100%',
              maxWidth: '50%',
            },
          }}
          aria-activedescendant="active"
          value={value}
          onChange={handleChange}
          variant="standard"
          aria-label="full width tabs example"
          indicatorColor="secondary"
        >
          <TopTab
            disableRipple
            label={`game`.toUpperCase()}
            onClick={() => setValue(0)}
          />
          <TopTab
            disableRipple
            label={`Matched bet (${getBetlistmatch?.data && getBetlistmatch?.data?.length > 0 ? getBetlistmatch?.data?.length : 0})`.toUpperCase()}
            onClick={() => setValue(1)}
          />
        </TopTabs>
      </Paper>

      <TabPanel
        value={value}
        index={0}
        dir={theme.direction}
        className="tabpanell"
      >
        <ParentWrapper>
          <VirtualCasinoHeader className="some">
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="header-title header-title-left"
                >
                  V LUCKY 7 - A
                </Typography>
                <Typography component="p" className=" header-title-left">
                  <Typography
                    component="span"
                    className="pointer title"
                    onClick={virtual2020teenpatti}
                  >
                    <FiArrowRight /> Rules
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="p" className="title header-title-right">
                  Round ID: {odds?.t1?.[0]?.mid}
                </Typography>
                <Typography component="p" className="title header-title-right">
                  Time: {moment().format('hh:mm:ss A')}
                </Typography>
              </Grid>
            </Grid>
          </VirtualCasinoHeader>

          <Virtuacasinoltable>
            <Box className="teenpattiheadertext">
              <Typography className="heading">LUCKY 7 - A</Typography>
            </Box>

            <Grid container>
              <Grid item xs={2} md={3}></Grid>
              <Grid item xs={8} md={6} className="flex" sx={{ mt: 2 }}>
                <Grid container>
                  <Grid item xs={4} md={4}>
                    <CardSize>
                      {/* <img
                        src="http://admin.kalyanexch.com/images/cards/backcover.png"
                        alt=""
                      /> */}
                      <CardComp
                        shown={getByC1(odds) === 1}
                        card={odds?.t1?.[0]?.C1 || ''}
                      />
                    </CardSize>

                    <Button className="playerbutton" disableRipple disabled>
                      <Typography component="p">a-6</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <CardSize>
                      {/* 
                      <img
                        src="http://admin.kalyanexch.com/images/cards/backcover.png"
                        alt=""
                      />
                    */}
                      <CardComp
                        shown={getByC1(odds) === 3}
                        card={odds?.t1?.[0]?.C1 || ''}
                      />
                    </CardSize>

                    <Button className="playerbutton" disableRipple disabled>
                      <Typography component="p">7</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <CardSize>
                      {/*
                      <img
                        src="http://admin.kalyanexch.com/images/cards/backcover.png"
                        alt=""
                      />
                    */}
                      <CardComp
                        shown={getByC1(odds) === 2}
                        card={odds?.t1?.[0]?.C1 || ''}
                      />
                    </CardSize>
                    <Button className="playerbutton" disableRipple disabled>
                      <Typography component="p">8-k</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} md={3}></Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box className="_timer">
                <Typography component="p">
                  {Number(odds?.t1?.[0]?.autotime) > 0
                    ? odds?.t1?.[0]?.autotime
                    : 'GO!!'}
                </Typography>
              </Box>
            </Grid>
          </Virtuacasinoltable>

          <Box className="dragontiger" sx={{ m: 'auto' }}>
            <Grid
              container
              sx={{
                backgroundColor: '#f2f2f2',
                padding: '10px 20px',
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={4}
                onClick={() => {
                  if (odds?.t2['1']?.gstatus === '0') {
                    return <></>
                  }
                  handleClickBetModalOpen({
                    betType: 'back',
                    deviceInfo: {
                      browser: "Chrome",
                      browser_version: "114.0.0.0",
                      device: "Macintosh",
                      deviceType: "desktop",
                      orientation: "landscape",
                      os: "Mac",
                      os_version: "mac-os-x-15",
                      userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                      userIp: "180.151.224.106"
                    },
                    isBetfair: false,
                    isBack: true,
                    isLay: false,
                    isbetfair: false,
                    liability: 0,
                    marketId: odds?.t2['1']?.mid,
                    matchId: Number(eventId),
                    marketName: odds?.t1['0']?.gtype,
                    matchName: location,
                    odds: odds?.t2['1']?.rate,
                    placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                    priceValue: odds?.t2['1']?.rate,
                    selectionId: odds?.t2['1']?.sid,
                    selectionIds: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19",
                    selectionName: odds?.t2['1']?.nat,
                    stake: 0,
                    type: odds?.t1['0']?.gtype,
                    sportId: 501,
                  })
                }}
              >
                <CustomTypography >
                  {odds?.t2['1']?.rate}
                </CustomTypography>
                <DragonTigerButton size="large" sx={{ minHeight: '35px' }}>
                  {odds?.t2['1']?.nat}
                  {odds?.t2['1']?.gstatus === '0' && <Suspendedlock />}
                </DragonTigerButton>
                {/* <CustomTypography>{odds?.t2?.[1]?.sid == luckysevenpnldata?.data?.[0]?.selectionid ? luckysevenpnldata?.data?.[0]?.total_pnl : 0}</CustomTypography> */}
                <CustomTypography>
                  {odds?.t2?.[2]?.sid && luckysevenpnldata?.dataObj?.[odds?.t2?.[1]?.sid]?.total_pnl || 0}
                </CustomTypography>

              </Grid>
              <Grid item xs={3.8}>
                <Box
                  sx={{
                    margin: '10px auto',
                    width: { md: '100px', xs: '50px' },
                  }}
                >
                  <img src={`/img/7.jpg`} style={{ width: '100%' }} alt="" />
                </Box>
              </Grid>
              <Grid item xs={4}
                onClick={() => {
                  if (odds?.t2['2']?.gstatus === '0') {
                    return <></>
                  }
                  handleClickBetModalOpen({
                    betType: 'back',
                    deviceInfo: {
                      browser: "Chrome",
                      browser_version: "114.0.0.0",
                      device: "Macintosh",
                      deviceType: "desktop",
                      orientation: "landscape",
                      os: "Mac",
                      os_version: "mac-os-x-15",
                      userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                      userIp: "180.151.224.106"
                    },
                    isBetfair: false,
                    isBack: true,
                    isLay: false,
                    isbetfair: false,
                    liability: 0,
                    marketId: odds?.t2['2']?.mid,
                    matchId: Number(eventId),
                    marketName: odds?.t1['0']?.gtype,
                    matchName: location,
                    odds: odds?.t2['2']?.rate,
                    placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                    priceValue: odds?.t2['2']?.rate,
                    selectionId: odds?.t2['2']?.sid,
                    selectionIds: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19",
                    selectionName: odds?.t2['2']?.nat,
                    stake: 0,
                    type: odds?.t1['0']?.gtype,
                    sportId: 501,
                  })
                }}
              >
                <CustomTypography>{odds?.t2['2']?.rate}</CustomTypography>
                <DragonTigerButton size="large" sx={{ minHeight: '35px' }}>
                  {odds?.t2['2']?.nat}
                  {odds?.t2['2']?.gstatus === '0' && <Suspendedlock />}
                </DragonTigerButton>
                {/* <CustomTypography>{odds?.t2?.[2]?.sid == luckysevenpnldata?.data?.[2]?.selectionid ? luckysevenpnldata?.data?.[2]?.total_pnl : 0}</CustomTypography> */}
                <CustomTypography>
                  {odds?.t2?.[2]?.sid && luckysevenpnldata?.dataObj?.[odds?.t2?.[2]?.sid]?.total_pnl || 0}
                </CustomTypography>

              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    textTransform: 'capitalize',
                    textAlign: 'right',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >
                  Min:
                  <Typography component="span" sx={{ fontSize: '12px' }}>
                    {odds?.t2['1']?.min}
                  </Typography>
                </Typography>

                <Typography
                  sx={{
                    textTransform: 'capitalize',
                    textAlign: 'right',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >
                  Max:
                  <Typography component="span" sx={{ fontSize: '12px' }}>
                    {odds?.t2['1']?.max}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className="dragonheader">
            <Grid
              container
              sx={{
                padding: '10px 20px',
                justifyContent: 'space-between',
                m: 'auto',
              }}
              gap={1}
            >
              <Grid item xs={5.8}>
                <Grid
                  container
                  gap={1.6}
                  sx={{ justifyContent: 'space-between' }}
                >
                  <Grid item xs={5.2} onClick={() => {
                    if (odds?.t2['3']?.gstatus === '0') {
                      return <></>
                    }
                    handleClickBetModalOpen({
                      betType: 'back',
                      deviceInfo: {
                        browser: "Chrome",
                        browser_version: "114.0.0.0",
                        device: "Macintosh",
                        deviceType: "desktop",
                        orientation: "landscape",
                        os: "Mac",
                        os_version: "mac-os-x-15",
                        userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                        userIp: "180.151.224.106"
                      },
                      isBetfair: false,
                      isBack: true,
                      isLay: false,
                      isbetfair: false,
                      liability: 0,
                      marketId: odds?.t2['3']?.mid,
                      matchId: Number(eventId),
                      marketName: odds?.t1['0']?.gtype,
                      matchName: location,
                      odds: odds?.t2['3']?.rate,
                      placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                      priceValue: odds?.t2['3']?.rate,
                      selectionId: odds?.t2['3']?.sid,
                      selectionIds: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19",
                      selectionName: odds?.t2['3']?.nat,
                      stake: 0,
                      type: odds?.t1['0']?.gtype,
                      sportId: 501,
                    })
                  }}
                  >
                    <CustomTypography>{odds?.t2['3']?.rate}</CustomTypography>
                    <DragonTigerButton size="large" sx={{ minHeight: '35px' }}>
                      {odds?.t2['3']?.nat}
                      {odds?.t2['3']?.gstatus === '0' && <Suspendedlock />}
                    </DragonTigerButton>
                    {/* <CustomTypography>{odds?.t2?.[3]?.sid == luckysevenpnldata?.data?.[0]?.selectionid ? luckysevenpnldata?.data?.[0]?.total_pnl : 0}</CustomTypography> */}
                    <CustomTypography>
                      {odds?.t2?.[3]?.sid && luckysevenpnldata?.dataObj?.[odds?.t2?.[3]?.sid]?.total_pnl || 0}
                    </CustomTypography>
                  </Grid>
                  <Grid item xs={5.2} onClick={() => {
                    if (odds?.t2['3']?.gstatus === '0') {
                      return <></>
                    }
                    handleClickBetModalOpen({
                      betType: 'back',
                      deviceInfo: {
                        browser: "Chrome",
                        browser_version: "114.0.0.0",
                        device: "Macintosh",
                        deviceType: "desktop",
                        orientation: "landscape",
                        os: "Mac",
                        os_version: "mac-os-x-15",
                        userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                        userIp: "180.151.224.106"
                      },
                      isBetfair: false,
                      isBack: true,
                      isLay: false,
                      isbetfair: false,
                      liability: 0,
                      marketId: odds?.t2['4']?.mid,
                      matchId: Number(eventId),
                      marketName: odds?.t1['0']?.gtype,
                      matchName: location,
                      odds: odds?.t2['4']?.rate,
                      placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                      priceValue: odds?.t2['4']?.rate,
                      selectionId: odds?.t2['4']?.sid,
                      selectionIds: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19",
                      selectionName: odds?.t2['4']?.nat,
                      stake: 0,
                      type: odds?.t1['0']?.gtype,
                      sportId: 501,
                    })
                  }}>
                    <CustomTypography>{odds?.t2['4']?.rate}</CustomTypography>
                    <DragonTigerButton size="large" sx={{ minHeight: '35px' }}>
                      {odds?.t2['4']?.nat}
                      {odds?.t2['4']?.gstatus === '0' && <Suspendedlock />}
                    </DragonTigerButton>
                    {/* <CustomTypography>{odds?.t2?.[4]?.sid == luckysevenpnldata?.data?.[0]?.selectionid ? luckysevenpnldata?.data?.[0]?.total_pnl : 0}</CustomTypography> */}
                    <CustomTypography>
                      {odds?.t2?.[4]?.sid && luckysevenpnldata?.dataObj?.[odds?.t2?.[4]?.sid]?.total_pnl || 0}
                    </CustomTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        textAlign: 'right',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      min:
                      <Typography component="span" sx={{ fontSize: '12px' }}>
                        {odds?.t2['3']?.min}
                      </Typography>
                    </Typography>

                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        textAlign: 'right',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      max:
                      <Typography component="span" sx={{ fontSize: '12px' }}>
                        {odds?.t2['3']?.max}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={5.8}>
                <Grid
                  container
                  gap={1.6}
                  sx={{ justifyContent: 'space-between' }}
                >
                  <Grid item xs={5.2} onClick={() => {
                    if (odds?.t2['5']?.gstatus === '0') {
                      return <></>
                    }
                    handleClickBetModalOpen({
                      betType: 'back',
                      deviceInfo: {
                        browser: "Chrome",
                        browser_version: "114.0.0.0",
                        device: "Macintosh",
                        deviceType: "desktop",
                        orientation: "landscape",
                        os: "Mac",
                        os_version: "mac-os-x-15",
                        userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                        userIp: "180.151.224.106"
                      },
                      isBetfair: false,
                      isBack: true,
                      isLay: false,
                      isbetfair: false,
                      liability: 0,
                      marketId: odds?.t2['5']?.mid,
                      matchId: Number(eventId),
                      marketName: odds?.t1['0']?.gtype,
                      matchName: location,
                      odds: odds?.t2['5']?.rate,
                      placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                      priceValue: odds?.t2['5']?.rate,
                      selectionId: odds?.t2['5']?.sid,
                      selectionIds: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19",
                      selectionName: odds?.t2['5']?.nat,
                      stake: 0,
                      type: odds?.t1['0']?.gtype,
                      sportId: 501,
                    })
                  }}>
                    <CustomTypography>{odds?.t2['5']?.rate}</CustomTypography>
                    <DragonTigerButton size="large" sx={{ minHeight: '35px' }}>
                      <img
                        src={`http://admin.kalyanexch.com/images/cards/ccard-1.png`}
                        alt=""
                        style={{ width: '30px' }}
                      />
                      {odds?.t2['5']?.gstatus === '0' && <Suspendedlock />}
                    </DragonTigerButton>
                    {/* <CustomTypography>{odds?.t2?.[5]?.sid == luckysevenpnldata?.data?.[0]?.selectionid ? luckysevenpnldata?.data?.[0]?.total_pnl : 0}</CustomTypography> */}
                    <CustomTypography>
                      {odds?.t2?.[5]?.sid && luckysevenpnldata?.dataObj?.[odds?.t2?.[5]?.sid]?.total_pnl || 0}
                    </CustomTypography>
                  </Grid>

                  <Grid item xs={5.2} onClick={() => {
                    if (odds?.t2['6']?.gstatus === '0') {
                      return <></>
                    }
                    handleClickBetModalOpen({
                      betType: 'back',
                      deviceInfo: {
                        browser: "Chrome",
                        browser_version: "114.0.0.0",
                        device: "Macintosh",
                        deviceType: "desktop",
                        orientation: "landscape",
                        os: "Mac",
                        os_version: "mac-os-x-15",
                        userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                        userIp: "180.151.224.106"
                      },
                      isBetfair: false,
                      isBack: true,
                      isLay: false,
                      isbetfair: false,
                      liability: 0,
                      marketId: odds?.t2['6']?.mid,
                      matchId: Number(eventId),
                      marketName: odds?.t1['0']?.gtype,
                      matchName: location,
                      odds: odds?.t2['6']?.rate,
                      placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                      priceValue: odds?.t2['6']?.rate,
                      selectionId: odds?.t2['6']?.sid,
                      selectionIds: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19",
                      selectionName: odds?.t2['6']?.nat,
                      stake: 0,
                      type: odds?.t1['0']?.gtype,
                      sportId: 501,
                    })
                  }}>
                    <CustomTypography>{odds?.t2['6']?.rate}</CustomTypography>
                    <DragonTigerButton size="large" sx={{ minHeight: '35px' }}>
                      <img
                        src={`http://admin.kalyanexch.com/images/cards/ccard-2.png`}
                        alt=""
                        style={{ width: '30px' }}
                      />
                      {odds?.t2['6']?.gstatus === '0' && <Suspendedlock />}
                    </DragonTigerButton>
                    {/* <CustomTypography>{odds?.t2?.[6]?.sid == luckysevenpnldata?.data?.[0]?.selectionid ? luckysevenpnldata?.data?.[0]?.total_pnl : 0}</CustomTypography> */}
                    <CustomTypography>
                      {odds?.t2?.[6]?.sid && luckysevenpnldata?.dataObj?.[odds?.t2?.[6]?.sid]?.total_pnl || 0}
                    </CustomTypography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        textAlign: 'right',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      min:
                      <Typography component="span" sx={{ fontSize: '12px' }}>
                        {odds?.t2['5']?.min}
                      </Typography>
                    </Typography>

                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        textAlign: 'right',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      max:
                      <Typography component="span" sx={{ fontSize: '12px' }}>
                        {odds?.t2['5']?.max}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <VirtualCasinoHeader className="some m-5 p-0">
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    component="p"
                    className="header-title header-title-left"
                  >
                    News
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    component="p"
                    className="title header-title-right"
                  >
                    <Typography
                      component="span"
                      sx={{
                        textTransform: 'capitalize',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 'auto',
                        maxWidth: '100px',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      <span>play</span>
                      <span>
                        <BsFillPlayFill
                          size={22}
                          style={{ marginTop: '5px' }}
                        />
                      </span>
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </VirtualCasinoHeader>
          </Box>

          {result && <Lastresult teenTwentyResult={result} />}

        </ParentWrapper>
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        dir={theme.direction}
        className="tabpanell"
      >
        <VirtualMatchedBet />
      </TabPanel>
    </Box>
  )
}

export default VteenPattiLuckySeven
