import { Box, CircularProgress, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useActiveSportQuery, useLazyBethistoryApiQuery } from '../../app/apis/mainApi/mainApiSlice'
// import { useActiveSportQuery } from '../../services/activeSports'
// import { useLazyBethistoryApiQuery } from '../../services/getbetHistory'
import { postNegValue } from '../../utils/postNegVal'
import { CustomButton, CustomTextFieldLeft, CustomTypography, CustomTypoParent } from './bethistory.styled'

const BetHistory = () => {
    const [sportIdd, setSportIdd] = useState('4')
    const [startingDate, setStartingDate] = useState(moment().subtract(7, 'day').format('YYYY-MM-DD'))
    const [endingDate, setEndingDate] = useState(moment().format('YYYY-MM-DD'))

    const handlersportvalchange = (e: any) => {
        setSportIdd(e.target.value)
    }
    const handlerEndDate = (e: any) => {
        setEndingDate(e.target.value)
    }

    const handlerStartingDate = (e: any) => {
        setStartingDate(e.target.value)
    }
    const { data: activeSportData, isError: err, isLoading: loading, isSuccess: success } = useActiveSportQuery()
    const [trigger, { data: bethistoryData, isFetching, isLoading }] = useLazyBethistoryApiQuery()



    useEffect(() => {
        trigger({
            startDate: startingDate,
            endDate: endingDate,
            isActive: false,
            sportId: sportIdd
        })
    }, [])
    return (
        <Box sx={{ padding: '5px' }}>
            <CustomTypoParent>
                <CustomTypography>
                    Bet history
                </CustomTypography>
            </CustomTypoParent>

            <FormControl sx={{ width: '100%' }} >
                <Select value={sportIdd} onChange={handlersportvalchange} size="medium" sx={{ mt: 2, height: '38px', textAlign: 'left' }}>
                    {activeSportData?.data?.map((activeSport, index) => (
                        <MenuItem key={index} value={activeSport?.sportid} sx={{ color: '#000' }}>
                            {activeSport?.name}
                        </MenuItem>
                    ))}
                </Select>

                <Grid container sx={{ margin: '10px 0px 10px' }} gap={{ md: 2.8, xs: 1 }} >
                    <Grid item xs={5.8} md={5.9} >
                        <CustomTextFieldLeft value={startingDate} onChange={handlerStartingDate} type='date' fullWidth size="small" InputLabelProps={{ sx: { color: "#000 !important" } }} />
                    </Grid>
                    <Grid item xs={5.8} md={5.9} >
                        <CustomTextFieldLeft value={endingDate} onChange={handlerEndDate} type='date' fullWidth size="small" InputLabelProps={{ sx: { color: "#000 !important" } }} />
                    </Grid>
                </Grid>

                <CustomButton onClick={() => trigger({
                    endDate: endingDate,
                    startDate: startingDate,
                    isActive: false,
                    sportId: sportIdd
                })}>
                    submit
                </CustomButton>
            </FormControl>

            {isFetching || isLoading ? <CircularProgress sx={{ color: '#ccc' }} thickness={2} /> : bethistoryData?.data && bethistoryData?.data?.map((historyData, index) => (
                <Grid container key={index + 1} sx={{ backgroundColor: historyData?.isback === true ? 'back.sky' : 'lay.pink', alignItems: 'flex-start', padding: '5px', margin: '5px 0px 0px' }}>
                    <Grid item xs={6}>
                        <Box>
                            <Typography component='p' sx={{ fontWeight: 600, textTransform: 'capitalize', fontSize: '12px', textAlign: 'left', color: '#007bff' }}>
                                {historyData?.matchname}
                            </Typography>
                            <Typography component='p' sx={{ textTransform: 'capitalize', fontSize: '12px', textAlign: 'left' }}>
                                <Typography component='span' sx={{ fontWeight: 600, fontSize: '12px' }}>
                                    Nation:
                                </Typography>
                                <Typography component='span' sx={{ marginLeft: '5px', textTransform: 'capitalize', fontSize: '12px', textAlign: 'left' }}>
                                    {historyData?.selectionname}
                                </Typography>
                            </Typography>
                            <Typography component='p' sx={{ textTransform: 'capitalize', fontSize: '12px', textAlign: 'left' }}>

                                <Typography component='span' sx={{ fontWeight: 600, fontSize: '12px' }}>
                                    Bet Type:
                                </Typography>
                                <Typography component='span' sx={{ marginLeft: '5px', textTransform: 'capitalize', fontSize: '12px', textAlign: 'left' }}>
                                    {historyData?.isback ? 'No' : 'Yes'}
                                </Typography>
                            </Typography>
                            <Typography component='p' sx={{ fontWeight: 600, textTransform: 'capitalize', fontSize: '12px', textAlign: 'left' }}>
                                <Typography component='span' sx={{ fontWeight: 600, fontSize: '12px' }}>
                                    Matched Date:
                                </Typography>
                                <Typography component='span' sx={{ marginLeft: '2px', textTransform: 'capitalize', fontSize: '12px', textAlign: 'left' }}>
                                    {historyData?.matchedtime}
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '12px', fontWeight: 600 }}>
                            user rate
                        </Typography>
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                            {historyData?.odds}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '12px', fontWeight: 600 }}>
                            amount
                        </Typography>
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                            {historyData?.stake}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '12px', fontWeight: 600 }}>
                            p&l
                        </Typography>
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                            {postNegValue(historyData?.netpnl)}
                        </Typography>
                    </Grid>
                </Grid>
            ))}

            {!bethistoryData?.data && <Box
                sx={{
                    color: '#2c3e50',
                    fontSize: '12px',
                    fontWeight: 600,
                    backgroundColor: '#e4e4e4',
                    margin: '4px 0 0',
                    padding: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: 'roboto'
                }}
            >
                No data found!
            </Box>}
        </Box >
    )
}

export default BetHistory