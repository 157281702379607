import { Box, CircularProgress } from '@mui/material'

const LoadingSpinner = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#32323282',
          zIndex: '213487',
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          left: 0,
          top: 0,
          width: '100%',
          height: '100vh',
        }}
      >
        <CircularProgress
          sx={{ marginRight: 1 }}
          color="info"
          size={30}
          thickness={1.6}
        />
      </Box>
    </>
  )
}
export default LoadingSpinner
