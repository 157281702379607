import styled from '@mui/material/styles/styled'
import Container from '@mui/material/Container/Container'

export const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.tablebg.main,
  maxHeight: 'calc(100vh - 284px)',
  overflow: 'auto ',
  [theme.breakpoints.up('lg')]: {
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
    margin: 0,
    width: '100%',
  },
}))
