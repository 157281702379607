import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
// import { TopTab } from './GameDetail.styled'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
// import { TopTabs } from './GameDetail.styled'
import { Outlet, useLocation, useOutletContext, useParams } from 'react-router'
// import GameDetail from './GameDetail'
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
// import MatchedBet from './MatchedBet'
// import { AiOutlineDesktop } from 'react-icons/ai'
import { TopTab, TopTabs } from '../../component/common/GameDetail/GameDetail.styled'
// import MatchedBet from '../../component/common/GameDetail/MatchedBet'
import VirtualCasinoHeader from '../../component/common/VirtualAndLiveCasino/VirtualCasinoHeader'
import VirtualMatchedBet from './VirtualMatchedBet'
import { CardSize, ParentWrapper, Virtuacasinoltable } from './virtualpatti.styled'
import { FiArrowRight } from 'react-icons/fi'
import moment from 'moment'
import VirtualCasinoModal from '../../component/common/VirtualCasinoModal/VirtualCasinoModal'
import { BsFillPlayFill } from 'react-icons/bs'
import Suspendedlock from '../../component/common/Suspendedlock'
import { useSearchParams } from 'react-router-dom'
import { database } from '../../firebase'
import { useVirtualcasinoliabilityQuery } from '../../app/apis/mainApi/mainApiSlice'
import { virtualcasinoResult, virutalcasinoData, vteenpatti20Payload } from '../../services/types'
import CardComp from '../../component/common/CardComp'
import Lastresult from '../../component/common/Lastresult'
import { useGetBetListByMatchResQuery } from '../../app/apis/mdkalyanExchApi/mdkalyanExchApiSlice'
interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
    className: string
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Box sx={{ p: 0 }}>{children}</Box>
                </Box>
            )}
        </div>
    )
}

interface Props {
    handleClickBetModalOpen: (args: vteenpatti20Payload) => void
}

const VteenPatti20 = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const location: any = useLocation()

    const [teenTwenty, setTeenTwenty] = useState<virutalcasinoData>()
    const [teenTwentyResult, setTeenTwentyResult] = useState<virtualcasinoResult>()


    const eventId = searchParams.get('id')
    const theme = useTheme()
    const [value, setValue] = React.useState(0)
    const [virtualModal, setVirtualModal] = useState(false)
    const { data: casinoPnlData } = useVirtualcasinoliabilityQuery({ eventid: eventId || '', roundid: teenTwenty?.t1?.[0]?.mid || '' })
    const handleChange = (
        event: React.SyntheticEvent<Element, Event>,
        newValue: number
    ) => {
        setValue(newValue)
    }
    const { handleClickBetModalOpen
    } = useOutletContext<Props>()
    const { data: getBetlistmatch } = useGetBetListByMatchResQuery({ eventId: Number(eventId) });
    const virtual2020teenpatti = () => {
        setVirtualModal(true)
    }



    const dataarr = [
        {
            key: 'pair (Double)',
            data: '1 To 1'
        },
        {
            key: 'Flush (Color)',
            data: '1 To 4'
        },
        {
            key: 'Straight (Rown)',
            data: '1 To 6'
        },
        {
            key: 'Trio (Teen)',
            data: '1 To 35'
        },
        {
            key: 'Straight Flush (Pakki Rown)',
            data: '1 To 45'
        },
    ]

    useEffect(() => {
        const betex = database.ref('betex');
        betex.on('value', (snapshot) => {
            setTeenTwenty(snapshot.val()?.t20.data.data)
            setTeenTwentyResult(snapshot.val()?.t20.result)
        })

    }, [])



    const autotimeFunc = () => {
        const autotime = teenTwenty?.t1?.[0].autotime;
        if (autotime === '0') {
            return <Typography component='p' sx={{ fontSize: '12px', fontWeight: '600' }}>
                GO!!
            </Typography>
        } else {
            return <Typography>
                {autotime}
            </Typography>
        }
    }

    return (
        <Box sx={{ width: { md: 'xl' } }}>
            <VirtualCasinoModal virtualModal={virtualModal} setVirtualModal={setVirtualModal} />
            <Paper elevation={10} sx={{
                bgcolor: 'tabsbgclr.main',
                height: 35,
                borderRadius: 0
            }}>
                <TopTabs
                    sx={{
                        '& .MuiButtonBase-root': {
                            width: '100%',
                            maxWidth: '50%'
                        }
                    }}
                    aria-activedescendant="active"
                    value={value}
                    onChange={handleChange}
                    variant="standard"
                    aria-label="full width tabs example"
                    indicatorColor="secondary"
                >
                    <TopTab disableRipple label={`game`.toUpperCase()} onClick={() => setValue(0)} />
                    <TopTab
                        disableRipple
                        label={getBetlistmatch && `Matched bet (${getBetlistmatch?.data?.length > 0 ? getBetlistmatch?.data?.length : 0})`.toUpperCase()}
                        onClick={() => setValue(1)}
                    />
                </TopTabs>
            </Paper>

            <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                className="tabpanell"
            >

                <ParentWrapper>

                    <VirtualCasinoHeader className='some' >
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography component='p' className='header-title header-title-left'>
                                    V 20-20 TEENPATTI
                                </Typography>
                                <Typography component='p' className=' header-title-left'>
                                    <Typography component='span' className='pointer title' onClick={virtual2020teenpatti}>
                                        <FiArrowRight /> Rules
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography component='p' className='title header-title-right'>
                                    Round ID: {teenTwenty?.t1?.[0]?.mid}
                                </Typography>
                                <Typography component='p' className='title header-title-right'>
                                    Time: {moment().format('hh:mm:ss A')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </VirtualCasinoHeader>

                    <Virtuacasinoltable>

                        <Box className='teenpattiheadertext'>
                            <Typography className='heading'>
                                Teenpatti T20
                            </Typography>
                        </Box>

                        <Grid container sx={{ minHeight: '103px' }}>
                            <Grid item xs={2}>
                                <Grid container sx={{ flexDirection: 'column', alignItems: 'center' }} className='flex'>
                                    <Grid item xs={4} className="flex" sx={{ justifyContent: 'center' }}>
                                        {teenTwenty?.t1?.[0] &&
                                            <CardComp shown={teenTwenty?.t1?.[0]?.C1 != ''} card={teenTwenty?.t1?.[0]?.C1} />}
                                    </Grid>
                                    <Grid item xs={4} className="flex" sx={{ justifyContent: 'center' }}>
                                        {teenTwenty?.t1?.[0] &&
                                            <CardComp shown={teenTwenty?.t1?.[0]?.C3 != ''} card={teenTwenty?.t1?.[0]?.C3} />}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} className="flex">
                                {teenTwenty?.t1?.[0] &&
                                    <CardComp shown={teenTwenty?.t1?.[0]?.C2 != ''} card={teenTwenty?.t1?.[0]?.C2} />}
                            </Grid>
                            <Grid item xs={4}>

                            </Grid>
                            <Grid item xs={2} className="flex" sx={{ justifyContent: 'flex-end' }}>
                                {teenTwenty?.t1?.[0] &&
                                    <CardComp shown={teenTwenty?.t1?.[0]?.C5 != ''} card={teenTwenty?.t1?.[0]?.C5} />}


                            </Grid>
                            <Grid item xs={2}>
                                <Grid container sx={{ flexDirection: 'column' }} className="flex">
                                    <Grid item xs={4} className="flex" sx={{ justifyContent: 'center' }}>
                                        {teenTwenty?.t1?.[0] &&
                                            <CardComp shown={teenTwenty?.t1?.[0]?.C4 != ''} card={teenTwenty?.t1?.[0]?.C4} />}

                                    </Grid>
                                    <Grid item xs={4} className="flex" sx={{ justifyContent: 'center' }}>
                                        {teenTwenty?.t1?.[0] &&
                                            <CardComp shown={teenTwenty?.t1?.[0]?.C6 != ''} card={teenTwenty?.t1?.[0]?.C6} />}

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '94%', margin: '10px auto' }}>

                            <Grid item xs={4} md={4} sx={{ textAlign: 'left', paddingLeft: { md: '55px', xs: '0' } }}>
                                <Button className='playerbutton' disableRipple disabled>
                                    <Typography component='p'>
                                        Player A
                                    </Typography>
                                </Button>

                            </Grid>
                            <Grid item xs={4}>
                                <Box className='_timer'>
                                    <Typography component='p'>
                                        {autotimeFunc()}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={4} sx={{ textAlign: 'right', paddingRight: { md: '55px', xs: '0' } }}>

                                <Button className='playerbutton' disableRipple disabled>
                                    <Typography component='p'>
                                        Player B
                                    </Typography>
                                </Button>

                            </Grid>

                        </Grid>

                    </Virtuacasinoltable>


                    <Grid container
                        sx={{ borderBlock: '1px solid #999' }}
                    >
                        <Grid item xs={6} md={6} sx={{ padding: ' 5px' }}>
                            <Typography component='p' sx={{ textAlign: 'left', fontSize: '12px' }}>
                                Min: {teenTwenty?.t1?.[0]?.min} | Max: {teenTwenty?.t1?.[0]?.max}
                            </Typography>
                        </Grid>

                        <Grid item xs={3} md={2}
                            bgcolor={theme.palette.back.sky}
                            sx={{ padding: '2px', borderLeft: '1px solid #999' }}
                        >
                            <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: 600, textTransform: 'uppercase' }}>
                                Back
                            </Typography>
                        </Grid>
                        <Grid item xs={3} md={2}
                            bgcolor={theme.palette.back.sky}
                            sx={{ padding: '2px', borderLeft: '1px solid #999' }}
                        >
                        </Grid>
                        <Grid item md={2} />
                    </Grid>

                    <Grid container
                        sx={{ borderBottom: '1px solid #999' }}
                    >
                        <Grid item xs={6} md={6} sx={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                            <Typography component='p' sx={{ textAlign: 'left', fontSize: '12px' }}>
                                {teenTwenty?.t2?.[0]?.nat}
                            </Typography>
                        </Grid>
                        {teenTwenty?.t1?.[0]?.mid && eventId && <Grid item xs={3} md={2}
                            onClick={() => {
                                if (teenTwenty?.t2?.[0]?.gstatus === '0') {
                                    return <></>
                                }
                                handleClickBetModalOpen({
                                    betType: 'back',
                                    deviceInfo: {
                                        browser: "Chrome",
                                        browser_version: "114.0.0.0",
                                        device: "Macintosh",
                                        deviceType: "desktop",
                                        orientation: "landscape",
                                        os: "Mac",
                                        os_version: "mac-os-x-15",
                                        userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                                        userIp: "180.151.224.106"
                                    },
                                    isBetfair: false,
                                    isBack: true,
                                    isLay: false,
                                    isbetfair: false,
                                    liability: 0,
                                    marketId: teenTwenty?.t1?.[0]?.mid,
                                    matchId: Number(eventId),
                                    marketName: teenTwenty?.t1?.[0]?.gtype,
                                    matchName: location,
                                    odds: teenTwenty?.t2?.[0]?.rate,
                                    placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                                    priceValue: teenTwenty?.t2?.[0]?.rate,
                                    selectionId: teenTwenty?.t2?.[0]?.sid,
                                    selectionIds: teenTwenty?.t2.map(i => i.sid).join(","),
                                    selectionName: teenTwenty?.t2?.[0]?.nat,
                                    stake: 0,
                                    type: teenTwenty?.t1?.[0]?.gtype,
                                    sportId: 504,
                                })
                            }}
                            bgcolor={theme.palette.back.sky}
                            sx={{ borderLeft: '1px solid #999', position: 'relative', }}
                        >

                            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                                {teenTwenty?.t2[0]?.rate}
                            </Typography>
                            <Typography sx={{ fontSize: '12px' }}>
                                {teenTwenty?.t2?.[0]?.sid && casinoPnlData?.dataObj?.[teenTwenty?.t2?.[0]?.sid]?.total_pnl || 0}
                            </Typography>
                            {teenTwenty?.t2?.[0]?.gstatus === '0' ? <Suspendedlock /> : <></>}
                        </Grid>}

                        <Grid item xs={3} md={2}
                            bgcolor={theme.palette.back.sky}
                            sx={{ borderLeft: '1px solid #999', cursor: 'pointer', position: 'relative' }}
                        >
                            {teenTwenty?.t2?.[1]?.gstatus === '0' ? <Suspendedlock /> : <></>}

                            <Typography sx={{ fontSize: '12px', textTransform: 'capitalize' }}>
                                {teenTwenty?.t2[1]?.nat}
                            </Typography>
                            <Typography sx={{ fontSize: '12px' }}>
                                {teenTwenty?.t2?.[1]?.sid && casinoPnlData?.dataObj?.[teenTwenty?.t2?.[1]?.sid]?.total_pnl || 0}
                            </Typography>
                        </Grid>

                        <Grid item md={2} />
                    </Grid>
                    <Grid container
                        sx={{ borderBottom: '1px solid #999' }}
                    >
                        <Grid item xs={6} md={6} sx={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                            <Typography component='p' sx={{ textAlign: 'left', fontSize: '12px' }}>
                                {teenTwenty?.t2?.[2]?.nat}
                            </Typography>
                        </Grid>
                        {teenTwenty?.t1?.[0]?.mid && eventId && <Grid item xs={3} md={2}
                            bgcolor={theme.palette.back.sky}
                            sx={{ borderLeft: '1px solid #999', cursor: 'pointer', position: 'relative' }}
                            onClick={() => {
                                if (teenTwenty?.t2?.[2].gstatus === '0') {
                                    return <></>
                                }
                                handleClickBetModalOpen({
                                    betType: 'back',
                                    deviceInfo: {
                                        browser: "Chrome",
                                        browser_version: "114.0.0.0",
                                        device: "Macintosh",
                                        deviceType: "desktop",
                                        orientation: "landscape",
                                        os: "Mac",
                                        os_version: "mac-os-x-15",
                                        userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                                        userIp: "180.151.224.106"
                                    },
                                    isBetfair: false,
                                    isBack: true,
                                    isLay: false,
                                    isbetfair: false,
                                    liability: 0,
                                    marketId: teenTwenty?.t1?.[0]?.mid,
                                    matchId: Number(eventId),
                                    marketName: teenTwenty?.t1?.[0]?.gtype,
                                    matchName: location,
                                    odds: teenTwenty?.t2?.[2]?.rate,
                                    placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                                    priceValue: teenTwenty?.t2?.[2]?.rate,
                                    selectionId: teenTwenty?.t2?.[2]?.sid,
                                    selectionIds: teenTwenty?.t2.map(i => i.sid).join(","),
                                    selectionName: teenTwenty?.t2?.[2]?.nat,
                                    stake: 0,
                                    type: teenTwenty?.t1?.[0]?.gtype,
                                    sportId: 504,
                                })
                            }}
                        >
                            {teenTwenty?.t2?.[2]?.gstatus === '0' ? <Suspendedlock /> : <></>}
                            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                                {teenTwenty?.t2?.[2]?.rate}
                            </Typography>
                            <Typography sx={{ fontSize: '12px' }}>
                                {teenTwenty?.t2?.[2]?.sid && casinoPnlData?.dataObj?.[teenTwenty?.t2?.[2]?.sid]?.total_pnl || 0}
                            </Typography>
                        </Grid>}
                        <Grid item xs={3} md={2}
                            bgcolor={theme.palette.back.sky}
                            sx={{ borderLeft: '1px solid #999', cursor: 'pointer', position: 'relative' }}
                        >
                            {teenTwenty?.t2?.[3]?.gstatus === '0' ? <Suspendedlock /> : <></>}
                            <Typography sx={{ fontSize: '12px', textTransform: 'capitalize' }}>
                                {teenTwenty?.t2?.[3]?.nat}
                            </Typography>
                            <Typography sx={{ fontSize: '12px' }}>
                                {teenTwenty?.t2?.[3]?.sid && casinoPnlData?.dataObj?.[teenTwenty?.t2?.[3]?.sid]?.total_pnl || 0}
                            </Typography>
                        </Grid>
                        <Grid item md={2} />
                    </Grid>

                    <Box>
                        <VirtualCasinoHeader className='some p-0 m-5'>
                            <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Grid item xs={6}>
                                    <Typography component='p' className='header-title header-title-left'>
                                        News
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography component='p' className='title header-title-right'>
                                        <Typography component='span' sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', marginLeft: 'auto', maxWidth: '100px', justifyContent: 'flex-end', cursor: 'pointer', fontSize: '14px', fontWeight: '600' }}>
                                            <span>
                                                play
                                            </span>
                                            <span >
                                                <BsFillPlayFill size={22} style={{ marginTop: '5px' }} />
                                            </span>
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </VirtualCasinoHeader>
                    </Box>
                    {teenTwentyResult && <Lastresult teenTwentyResult={teenTwentyResult} />}
                    <Box className='rules'>
                        <VirtualCasinoHeader className='p-0 m-5 some' >
                            <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Grid item xs={6}>
                                    <Typography component='p' sx={{ textTransform: 'capitalize' }} className='header-title header-title-left'>
                                        rules
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>

                                </Grid>
                            </Grid>

                        </VirtualCasinoHeader>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-end' }} className="viewresult">
                            <Grid container>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell sx={{ textAlign: 'center', padding: '0', fontSize: '12px', color: '#000', fontWeight: '600', textTransform: 'capitalize', lineHeight: 'initial' }} colSpan={2}>pair plus</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dataarr.map((data) => (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: '1px solid rgba(224, 224, 224, 1)' } }}
                                                    >
                                                        <TableCell align='left' sx={{ width: '60%', textTransform: 'capitalize', padding: '0 10px', fontSize: '12px', borderRight: '1px solid rgba(224, 224,1)' }}>
                                                            {data?.key}
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ padding: '0 10px', fontSize: '12px' }}>{data?.data}</TableCell>

                                                    </TableRow>
                                                ))
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </ParentWrapper>

            </TabPanel>
            <TabPanel
                value={value}
                index={1}
                dir={theme.direction}
                className="tabpanell"
            >
                <VirtualMatchedBet />
            </TabPanel>
        </Box >
    )
}

export default VteenPatti20
