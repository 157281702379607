// import SwipeableViews from 'react-swipeable-views'
import { Divider } from './SecondHeader.styled'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { Tabs } from './SecondHeader.styled'
import ThirdHeader from './ThirdHeader'
import Table from '../Table/MatchTable'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { useMediaQuery } from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography sx={{ p: 0 }}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    'aria-controls': `full-width-tabpanel-${index}`,
    id: `full-width-tab-${index}`,
  }
}

const SecondHeader = () => {
  const theme = useTheme()
  const [value, setValue] = React.useState(1)
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) // Matches xs and down
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md')) // Matches sm to md
  const isLarge = useMediaQuery(theme.breakpoints.up('md')) // Matches md and up
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue)
  }
  const nav = useNavigate()

  const { pathname } = useLocation()
  console.log(pathname, 'ajdhfkjshfjsd')
  useEffect(() => {
    if (pathname === '/') {
      setValue(1)
    }
    if (pathname === 'livecasino' || pathname === '/casino') {
      setValue(2)
    }
  }, [pathname])

  console.log(pathname, 'lsadkfjlksdjflksdjflksdjflksjd')

  return (
    <Box sx={{ bgcolor: 'background.paper', width: { md: 'xl' } }}>
      {isLarge && (
        <AppBar key={pathname} position="static" sx={{ height: 36 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            indicatorColor="secondary"
          >
            <Tab
              label="in-play"
              {...a11yProps(0)}
              onClick={() => nav('/inplay')}
              sx={{ fontWeight: 'bold' }}
            />
            <Tab
              label="sports"
              {...a11yProps(1)}
              onClick={() => nav('/')}
              sx={{ fontWeight: 'bold' }}
            />
            <Tab
              sx={{ textWrap: 'nowrap', fontWeight: 'bold' }}
              label="our casino"
              {...a11yProps(3)}
              onClick={() => nav('/livecasino')}
            />
            <Tab
              label="v casino"
              {...a11yProps(2)}
              onClick={() => nav('/virtualcasino')}
              sx={{ fontWeight: 'bold' }}
            />
          </Tabs>
        </AppBar>
      )}

      {isSmall &&
        !pathname.includes('gamedetail') &&
        !pathname.startsWith('/casino') &&
        (pathname === '/' ||
          pathname.includes('livecasino') ||
          pathname.includes('virtualcasino') ||
          pathname.includes('inplay')) && (
          <AppBar position="static" sx={{ height: 36 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
              indicatorColor="secondary"
            >
              {/* <Tab
                label="in-play"
                {...a11yProps(0)}
                onClick={() => nav('/inplay')}
                sx={{ fontWeight: 'bold' }}
              /> */}
              <Tab
                label="sports"
                {...a11yProps(1)}
                onClick={() => nav('/')}
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                sx={{ textWrap: 'nowrap', fontWeight: 'bold' }}
                label="our casino"
                {...a11yProps(3)}
                onClick={() => nav('/livecasino')}
              />
              <Tab
                label="v casino"
                {...a11yProps(2)}
                onClick={() => nav('/virtualcasino')}
                sx={{ fontWeight: 'bold' }}
              />
            </Tabs>
          </AppBar>
        )}

      {/* <ThirdHeader /> */}

      {/* <TabPanel value={value} index={0} dir={theme.direction}>
        Item One
      </TabPanel> */}
      {/* <TabPanel value={value} index={1} dir={theme.direction}></TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        Item Four
      </TabPanel> */}
      <Outlet />
    </Box>
  )
}

export default SecondHeader
