const redColorForLabel = "#ff3c3c";
const yellowColorForLabel = "#ffff33";
const greenColorForLabel = "#41805E"
const blueColorForLabel = '#2c3e50'
const skyblueColorForLabel = '#0088cc'

export const LetterAndColorById: LetterAndColorInterface = {
    "5041": {
        "1": {
            color: redColorForLabel,
            label: "A",
        },
        "2": {
            color: skyblueColorForLabel,
            label: "B",
        },
        "3": {
            color: blueColorForLabel,
            label: 'Tie'
        }
    },
    "5042": {
        "W": {
            color: greenColorForLabel,
            label: "R",
        },
        "L": {
            color: greenColorForLabel,
            label: "R"
        }
    },
    "5031": {
        "1": {
            color: redColorForLabel,
            label: "D",
        },
        "2": {
            color: skyblueColorForLabel,
            label: "T",
        },
        "3": {
            color: blueColorForLabel,
            label: "Tie",
        },
    },
    "5011": {
        "H": {
            color: redColorForLabel,
            label: "H",
        },
        "L": {
            color: skyblueColorForLabel,
            label: "L",
        },
        "T": {
            color: blueColorForLabel,
            label: "T",
        },

    },
    "5021": {
        "1": {
            color: redColorForLabel,
            label: "A",
        },
        "2": {
            color: skyblueColorForLabel,
            label: "B",
        },
        "3": {
            color: greenColorForLabel,
            label: "C",
        }
    },
    "5022": {
        "1": {
            color: blueColorForLabel,
            label: "A",
        },
        "2": {
            color: blueColorForLabel,
            label: "B",
        },
        "3": {
            color: blueColorForLabel,
            label: "C",
        },
        "4": {
            color: blueColorForLabel,
            label: "D",
        },
        "5": {
            color: blueColorForLabel,
            label: "E",
        },
        "6": {
            color: blueColorForLabel,
            label: "F",
        },
    },
};

interface LetterAndColorInterface {
    [eventId: string]: {
        [sno: string]: {
            color: string;
            label: string;
        };
    };
}