import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
// import { TopTab } from './GameDetail.styled'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
// import { TopTabs } from './GameDetail.styled'
import { Outlet, useOutletContext, useParams } from 'react-router'
// import GameDetail from './GameDetail'
import {
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
// import MatchedBet from './MatchedBet'
// import { AiOutlineDesktop } from 'react-icons/ai'
import {
  TopTab,
  TopTabs,
} from '../../component/common/GameDetail/GameDetail.styled'
// import MatchedBet from '../../component/common/GameDetail/MatchedBet'
import VirtualCasinoHeader from '../../component/common/VirtualAndLiveCasino/VirtualCasinoHeader'
// import VirtualMatchedBet from './VirtualMatchedBet'
// import { CardSize, ParentWrapper, Virtuacasinoltable } from '../VteenPattiOpen/virtualpattiopen.styled'
import { FiArrowRight } from 'react-icons/fi'
import moment from 'moment'
import { database } from '../../firebase'
import virtualModalrules from '../../../public/img/5041Rules.jpeg'
import VirtualCasinoModal from '../../component/common/VirtualCasinoModal/VirtualCasinoModal'
import { BsFillPlayFill } from 'react-icons/bs'
import VirtualMatchedBet from '../VteenPatti20/VirtualMatchedBet'
import Suspendedlock from '../../component/common/Suspendedlock'
import {
  CardSize,
  DragonTigerButton,
  ParentWrapper,
  Virtuacasinoltable,
} from './virtualteendragontiger.styled'
import { useSearchParams } from 'react-router-dom'
import VirtualCasinoResultModal from '../../component/common/VirtualCasinoModal/VirtualCasinoResultModal'
import Lastresult from '../../component/common/Lastresult'
import {
  T2,
  virtualcasinoData,
  virtualcasinoResult,
  virutalcasinoData,
} from '../../services/types'
import { useVirtualcasinoliabilityQuery } from '../../app/apis/mainApi/mainApiSlice'
import { useGetBetListByMatchResQuery } from '../../app/apis/mdkalyanExchApi/mdkalyanExchApiSlice'
import CardComp from '../../component/common/CardComp'
import { FaLock } from 'react-icons/fa'
import { postNegValue } from '../../utils/postNegVal'
interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
  className: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Box sx={{ p: 0 }}>{children}</Box>
        </Box>
      )}
    </div>
  )
}

const VteenPattiDragonTiger = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const eventId = searchParams.get('id')
  const theme = useTheme()
  const [value, setValue] = React.useState(0)
  const [virtualModal, setVirtualModal] = useState(false)
  const [odds, setOdds] = useState<virutalcasinoData>()
  const [result, setResult] = useState<virtualcasinoResult>()
  const { data: getBetlistmatch } = useGetBetListByMatchResQuery({
    eventId: Number(eventId),
  })

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue)
  }

  // const { data: betlistmatch } = useGetBetListByMatchResQuery({ eventId: Number(id) })
  const virtual2020teenpatti = () => {
    setVirtualModal(true)
  }

  // useEffect(() => {
  //   const betex = database.ref('betex/dt20')
  //   betex.on('value', (snapshot) => {
  //     const odds = snapshot.val()?.data.data
  //     odds.t2 =
  //       odds?.t2?.reduce((accu: { [x: string]: T2 }, curr: T2) => {
  //         accu[curr.sid] = curr
  //         return accu
  //       }, {}) || {}
  //     setOdds(odds)
  //     setResult(snapshot.val()?.result)
  //   })
  //   return () => {
  //     betex.off()
  //   }
  // }, [])

  const autotimeFunc = () => {
    const autotime = odds?.t1?.[0].autotime
    if (autotime === '0') {
      return (
        <Typography component="p" sx={{ fontSize: '12px', fontWeight: '600' }}>
          GO!!
        </Typography>
      )
    } else {
      return <Typography>{autotime}</Typography>
    }
  }

  const { data: dragontigerpayload } = useVirtualcasinoliabilityQuery({
    eventid: eventId || '',
    roundid: odds?.t1?.[0]?.mid || '',
  })

  const { handleClickBetModalOpen } = useOutletContext<any>()

  return (
    <Box sx={{ width: { md: 'xl' } }}>
      <VirtualCasinoModal
        virtualModal={virtualModal}
        setVirtualModal={setVirtualModal}
      />

      <Paper
        elevation={10}
        sx={{
          bgcolor: 'tabsbgclr.main',
          height: 35,
          borderRadius: 0,
        }}
      >
        <TopTabs
          sx={{
            '& .MuiButtonBase-root': {
              width: '100%',
              maxWidth: '50%',
            },
          }}
          aria-activedescendant="active"
          value={value}
          onChange={handleChange}
          variant="standard"
          aria-label="full width tabs example"
          indicatorColor="secondary"
        >
          <TopTab
            disableRipple
            label={`game`.toUpperCase()}
            onClick={() => setValue(0)}
          />
          <TopTab
            disableRipple
            label={`Matched bet (${
              getBetlistmatch?.data && getBetlistmatch?.data?.length > 0
                ? getBetlistmatch?.data?.length
                : 0
            })`.toUpperCase()}
            onClick={() => setValue(1)}
          />
        </TopTabs>
      </Paper>

      <TabPanel
        value={value}
        index={0}
        dir={theme.direction}
        className="tabpanell"
      >
        <ParentWrapper>
          <VirtualCasinoHeader className="some">
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="header-title header-title-left"
                >
                  V 20-20 Dragon Tiger
                </Typography>
                <Typography component="p" className=" header-title-left">
                  <Typography
                    component="span"
                    className="pointer title"
                    onClick={virtual2020teenpatti}
                  >
                    <FiArrowRight /> Rules
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="p" className="title header-title-right">
                  Round ID: {odds?.t1?.[0]?.mid}
                </Typography>
                <Typography component="p" className="title header-title-right">
                  Time: {moment().format('hh:mm:ss A')}
                </Typography>
              </Grid>
            </Grid>
          </VirtualCasinoHeader>

          <Virtuacasinoltable>
            <Box className="teenpattiheadertext">
              <Typography className="heading">20-20 Dragon Tiger</Typography>
            </Box>

            <Grid container>
              <Grid item xs={2} md={3}></Grid>
              <Grid item xs={8} md={6} className="flex" sx={{ mt: 2 }}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    md={5}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <CardComp
                      shown={!!odds?.t1?.[0]?.C1}
                      card={odds?.t1?.[0]?.C1 || ''}
                    />
                    <Button
                      className="playerbutton"
                      sx={{ mt: 2 }}
                      disableRipple
                      disabled
                    >
                      <Typography component="p">dragon</Typography>
                    </Button>
                  </Grid>
                  <Grid item md={2}></Grid>
                  <Grid
                    item
                    xs={6}
                    md={5}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <CardComp
                      shown={!!odds?.t1?.[0]?.C2}
                      card={odds?.t1?.[0]?.C2 || ''}
                    />
                    <Button
                      className="playerbutton"
                      sx={{ mt: 2 }}
                      disableRipple
                      disabled
                    >
                      <Typography component="p">tiger</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} md={3}></Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box className="_timer">
                <Typography component="p">{autotimeFunc()}</Typography>
              </Box>
            </Grid>
          </Virtuacasinoltable>
          <Box className="blockingcontainer" sx={{ position: 'relative' }}>
            {odds?.t2?.[1]?.gstatus === '0' ? (
              <Box
                sx={{
                  width: '100%',
                  userSelect: 'none',
                  position: 'absolute',
                  height: '100%',
                  cursor: 'not-allowed',
                  zIndex: 20,
                  backgroundColor: 'rgba(44,62,80,.6)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  component="p"
                  sx={{
                    mb: 5,
                    fontSize: '20px',
                    fontWeight: '700',
                    color: '#fff',
                  }}
                >
                  <FaLock color="#fff" size={20} /> BET LOCKED
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            <Box className="dragontiger" sx={{ maxWidth: '1180px', m: 'auto' }}>
              <Grid
                container
                sx={{
                  backgroundColor: '#f2f2f2',
                  padding: '10px 20px',
                  justifyContent: 'space-between',
                }}
              >
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {odds?.t2?.[1]?.rate}
                  </Typography>
                  <DragonTigerButton
                    size="large"
                    onClick={() => {
                      if (odds?.t2?.[1]?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: 'Chrome',
                          browser_version: '114.0.0.0',
                          device: 'Macintosh',
                          deviceType: 'desktop',
                          orientation: 'landscape',
                          os: 'Mac',
                          os_version: 'mac-os-x-15',
                          userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                          userIp: '180.151.224.106',
                        },
                        isBetfair: false,
                        isBack: true,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2?.[1]?.mid,
                        matchId: Number(eventId),
                        marketName: odds?.t1['0']?.gtype,
                        matchName: location,
                        odds: odds?.t2?.[1]?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2?.[1]?.rate,
                        selectionId: odds?.t2?.[1]?.sid,
                        selectionIds:
                          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38',
                        selectionName: odds?.t2?.[1]?.nat,
                        stake: 0,
                        type: odds?.t1?.['0']?.gtype,
                        sportId: 503,
                      })
                    }}
                  >
                    {odds?.t2?.[1]?.nat}
                    {odds?.t2?.[1]?.gstatus === '0' && <Suspendedlock />}
                  </DragonTigerButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {postNegValue(
                      odds?.t2?.[1]?.sid &&
                        dragontigerpayload?.dataObj?.[odds?.t2?.[1]?.sid]
                          ?.total_pnl
                    ) || 0}
                  </Typography>
                </Grid>
                <Grid item xs={3.8}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {odds?.t2?.[3]?.rate}
                  </Typography>
                  <DragonTigerButton
                    size="large"
                    onClick={() => {
                      if (odds?.t2?.[1]?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: 'Chrome',
                          browser_version: '114.0.0.0',
                          device: 'Macintosh',
                          deviceType: 'desktop',
                          orientation: 'landscape',
                          os: 'Mac',
                          os_version: 'mac-os-x-15',
                          userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                          userIp: '180.151.224.106',
                        },
                        isBetfair: false,
                        isBack: true,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2?.[3]?.mid,
                        matchId: Number(eventId),
                        marketName: odds?.t1['0']?.gtype,
                        matchName: location,
                        odds: odds?.t2?.[3]?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2?.[3]?.rate,
                        selectionId: odds?.t2?.[3]?.sid,
                        selectionIds:
                          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38',
                        selectionName: odds?.t2?.[3]?.nat,
                        stake: 0,
                        type: odds?.t1?.['0']?.gtype,
                        sportId: 503,
                      })
                    }}
                  >
                    {odds?.t2?.[3]?.nat}
                    {odds?.t2?.[3]?.gstatus === '0' && <Suspendedlock />}
                  </DragonTigerButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {(odds?.t2?.[3]?.sid &&
                      dragontigerpayload?.dataObj?.[odds?.t2?.[3]?.sid]
                        ?.total_pnl) ||
                      0}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {odds?.t2?.[2]?.rate}
                  </Typography>
                  <DragonTigerButton
                    size="large"
                    onClick={() => {
                      if (odds?.t2?.[1]?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: 'Chrome',
                          browser_version: '114.0.0.0',
                          device: 'Macintosh',
                          deviceType: 'desktop',
                          orientation: 'landscape',
                          os: 'Mac',
                          os_version: 'mac-os-x-15',
                          userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                          userIp: '180.151.224.106',
                        },
                        isBetfair: false,
                        isBack: true,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2?.[2]?.mid,
                        matchId: Number(eventId),
                        marketName: odds?.t1['0']?.gtype,
                        matchName: location,
                        odds: odds?.t2?.[2]?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2?.[2]?.rate,
                        selectionId: odds?.t2?.[2]?.sid,
                        selectionIds:
                          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38',
                        selectionName: odds?.t2?.[2]?.nat,
                        stake: 0,
                        type: odds?.t1?.['0']?.gtype,
                        sportId: 503,
                      })
                    }}
                  >
                    {odds?.t2?.[2]?.nat}
                    {odds?.t2?.[2]?.gstatus === '0' && <Suspendedlock />}
                  </DragonTigerButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {(odds?.t2?.[2]?.sid &&
                      dragontigerpayload?.dataObj?.[odds?.t2?.[2]?.sid]
                        ?.total_pnl) ||
                      0}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {odds?.t2?.[4]?.rate}
                  </Typography>
                  <DragonTigerButton
                    size="large"
                    onClick={() => {
                      if (odds?.t2?.[1]?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: 'Chrome',
                          browser_version: '114.0.0.0',
                          device: 'Macintosh',
                          deviceType: 'desktop',
                          orientation: 'landscape',
                          os: 'Mac',
                          os_version: 'mac-os-x-15',
                          userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                          userIp: '180.151.224.106',
                        },
                        isBetfair: false,
                        isBack: true,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2?.[4]?.mid,
                        matchId: Number(eventId),
                        marketName: odds?.t1['0']?.gtype,
                        matchName: location,
                        odds: odds?.t2?.[4]?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2?.[4]?.rate,
                        selectionId: odds?.t2?.[4]?.sid,
                        selectionIds:
                          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38',
                        selectionName: odds?.t2?.[4]?.nat,
                        stake: 0,
                        type: odds?.t1?.['0']?.gtype,
                        sportId: 503,
                      })
                    }}
                  >
                    {odds?.t2?.[4]?.nat}
                    {odds?.t2?.[4]?.gstatus === '0' && <Suspendedlock />}
                  </DragonTigerButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {(odds?.t2?.[4]?.sid &&
                      dragontigerpayload?.dataObj?.[odds?.t2?.[4]?.sid]
                        ?.total_pnl) ||
                      0}
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      textAlign: 'right',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    min:
                    <Typography
                      component="span"
                      sx={{ marginLeft: '3px', fontSize: '12px' }}
                    >
                      {odds?.t2?.[1]?.min}
                    </Typography>
                  </Typography>

                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      textAlign: 'right',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    max:
                    <Typography
                      component="span"
                      sx={{ marginLeft: '3px', fontSize: '12px' }}
                    >
                      {odds?.t2?.[1]?.max}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box className="dragonheader">
              <Box
                sx={{
                  backgroundColor: theme.palette.gdheadingbg.main,
                  padding: '6px 8px',
                }}
              >
                <Typography
                  component="p"
                  sx={{
                    textAlign: 'left',
                    fontSize: '14px',
                    textTransform: 'capitalize',
                    color: '#fff',
                    fontWeight: '600',
                  }}
                >
                  dragon
                </Typography>
              </Box>

              <Grid
                container
                sx={{
                  padding: '10px 20px',
                  justifyContent: 'space-between',
                  maxWidth: '1180px',
                  m: 'auto',
                }}
                gap={1}
              >
                <Grid item xs={5.5}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {odds?.t2?.['5']?.rate}
                  </Typography>
                  <DragonTigerButton
                    size="large"
                    onClick={() => {
                      if (odds?.t2?.[1]?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: 'Chrome',
                          browser_version: '114.0.0.0',
                          device: 'Macintosh',
                          deviceType: 'desktop',
                          orientation: 'landscape',
                          os: 'Mac',
                          os_version: 'mac-os-x-15',
                          userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                          userIp: '180.151.224.106',
                        },
                        isBack: true,
                        isBetfair: false,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2?.[5]?.mid,
                        marketName: odds?.t1['0']?.gtype,
                        matchId: Number(eventId),
                        matchName: location,
                        odds: odds?.t2?.[5]?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2?.[5]?.rate,
                        selectionId: odds?.t2?.[5]?.sid,
                        selectionIds:
                          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38',
                        selectionName: odds?.t2?.[5]?.nat,
                        sportId: 503,
                        stake: 0,
                        type: odds?.t1?.['0']?.gtype,
                      })
                    }}
                  >
                    {odds?.t2?.['5']?.nat === 'Dragon Even' ? 'Even' : ''}
                    {odds?.t2?.['5']?.gstatus === '0' && <Suspendedlock />}
                  </DragonTigerButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {(odds?.t2?.[5]?.sid &&
                      dragontigerpayload?.dataObj?.[odds?.t2?.[5]?.sid]
                        ?.total_pnl) ||
                      0}
                  </Typography>
                </Grid>

                <Grid item xs={5.5}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {odds?.t2?.['6']?.rate}
                  </Typography>
                  <DragonTigerButton
                    size="large"
                    onClick={() => {
                      if (odds?.t2?.[1]?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: 'Chrome',
                          browser_version: '114.0.0.0',
                          device: 'Macintosh',
                          deviceType: 'desktop',
                          orientation: 'landscape',
                          os: 'Mac',
                          os_version: 'mac-os-x-15',
                          userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                          userIp: '180.151.224.106',
                        },
                        isBack: true,
                        isBetfair: false,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2?.[6]?.mid,
                        marketName: odds?.t1['0']?.gtype,
                        matchId: Number(eventId),
                        matchName: location,
                        odds: odds?.t2?.[6]?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2?.[6]?.rate,
                        selectionId: odds?.t2?.[6]?.sid,
                        selectionIds:
                          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38',
                        selectionName: odds?.t2?.[6]?.nat,
                        sportId: 503,
                        stake: 0,
                        type: odds?.t1?.['0']?.gtype,
                      })
                    }}
                  >
                    {odds?.t2?.['6']?.nat === 'Dragon Odd' ? 'ODD' : ''}
                    {odds?.t2?.['6']?.gstatus === '0' && <Suspendedlock />}
                  </DragonTigerButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {(odds?.t2?.[6]?.sid &&
                      dragontigerpayload?.dataObj?.[odds?.t2?.[6]?.sid]
                        ?.total_pnl) ||
                      0}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      textAlign: 'right',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    min:
                    <Typography
                      component="span"
                      sx={{ marginLeft: '3px', fontSize: '12px' }}
                    >
                      {odds?.t2?.['6']?.min}
                    </Typography>
                  </Typography>

                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      textAlign: 'right',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    max:
                    <Typography
                      component="span"
                      sx={{ marginLeft: '3px', fontSize: '12px' }}
                    >
                      {odds?.t2?.['6']?.max}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  padding: '10px 20px',
                  justifyContent: 'space-between',
                  maxWidth: '1180px',
                  m: 'auto',
                }}
                gap={1}
              >
                <Grid item xs={5.5}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {odds?.t2?.['7']?.rate}
                  </Typography>
                  <DragonTigerButton
                    size="large"
                    onClick={() => {
                      if (odds?.t2?.[1]?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: 'Chrome',
                          browser_version: '114.0.0.0',
                          device: 'Macintosh',
                          deviceType: 'desktop',
                          orientation: 'landscape',
                          os: 'Mac',
                          os_version: 'mac-os-x-15',
                          userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                          userIp: '180.151.224.106',
                        },
                        isBack: true,
                        isBetfair: false,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2?.[7]?.mid,
                        marketName: odds?.t1['0']?.gtype,
                        matchId: Number(eventId),
                        matchName: location,
                        odds: odds?.t2?.[7]?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2?.[7]?.rate,
                        selectionId: odds?.t2?.[7]?.sid,
                        selectionIds:
                          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38',
                        selectionName: odds?.t2?.[7]?.nat,
                        sportId: 503,
                        stake: 0,
                        type: odds?.t1?.['0']?.gtype,
                      })
                    }}
                  >
                    <img
                      src={`http://admin.kalyanexch.com/images/cards/ccard-1.png`}
                      alt=""
                      style={{ width: '30px' }}
                    />
                    {odds?.t2?.['7']?.gstatus === '0' && <Suspendedlock />}
                  </DragonTigerButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {(odds?.t2?.[7]?.sid &&
                      dragontigerpayload?.dataObj?.[odds?.t2?.[7]?.sid]
                        ?.total_pnl) ||
                      0}
                  </Typography>
                </Grid>

                <Grid item xs={5.5}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {odds?.t2?.['8']?.rate}
                  </Typography>
                  <DragonTigerButton
                    size="large"
                    onClick={() => {
                      if (odds?.t2?.[1]?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: 'Chrome',
                          browser_version: '114.0.0.0',
                          device: 'Macintosh',
                          deviceType: 'desktop',
                          orientation: 'landscape',
                          os: 'Mac',
                          os_version: 'mac-os-x-15',
                          userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                          userIp: '180.151.224.106',
                        },
                        isBack: true,
                        isBetfair: false,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2?.[8]?.mid,
                        marketName: odds?.t1['0']?.gtype,
                        matchId: Number(eventId),
                        matchName: location,
                        odds: odds?.t2?.[8]?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2?.[8]?.rate,
                        selectionId: odds?.t2?.[8]?.sid,
                        selectionIds:
                          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38',
                        selectionName: odds?.t2?.[8]?.nat,
                        sportId: 503,
                        stake: 0,
                        type: odds?.t1?.['0']?.gtype,
                      })
                    }}
                  >
                    <img
                      src={`http://admin.kalyanexch.com/images/cards/ccard-2.png`}
                      alt=""
                      style={{ width: '30px' }}
                    />
                    {odds?.t2?.['8']?.gstatus === '0' && <Suspendedlock />}
                  </DragonTigerButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {(odds?.t2?.[8]?.sid &&
                      dragontigerpayload?.dataObj?.[odds?.t2?.[8]?.sid]
                        ?.total_pnl) ||
                      0}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      textAlign: 'right',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    min:
                    <Typography component="span" sx={{ fontSize: '12px' }}>
                      {odds?.t2?.['8']?.min}
                    </Typography>
                  </Typography>

                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      textAlign: 'right',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    max:
                    <Typography component="span" sx={{ fontSize: '12px' }}>
                      {odds?.t2?.['8']?.max}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box className="dragonheader">
              <Box
                sx={{
                  backgroundColor: theme.palette.gdheadingbg.main,
                  padding: '6px 8px',
                }}
              >
                <Typography
                  component="p"
                  sx={{
                    textAlign: 'left',
                    fontSize: '14px',
                    textTransform: 'capitalize',
                    color: '#fff',
                    fontWeight: '600',
                  }}
                >
                  tiger
                </Typography>
              </Box>

              <Grid
                container
                sx={{
                  padding: '10px 20px',
                  justifyContent: 'space-between',
                  maxWidth: '1180px',
                  m: 'auto',
                }}
                gap={1}
              >
                <Grid item xs={5.5}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {odds?.t2?.['22']?.rate}
                  </Typography>
                  <DragonTigerButton
                    size="large"
                    onClick={() => {
                      if (odds?.t2?.[1]?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: 'Chrome',
                          browser_version: '114.0.0.0',
                          device: 'Macintosh',
                          deviceType: 'desktop',
                          orientation: 'landscape',
                          os: 'Mac',
                          os_version: 'mac-os-x-15',
                          userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                          userIp: '180.151.224.106',
                        },
                        isBack: true,
                        isBetfair: false,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2?.[22]?.mid,
                        marketName: odds?.t1['0']?.gtype,
                        matchId: Number(eventId),
                        matchName: location,
                        odds: odds?.t2?.[22]?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2?.[22]?.rate,
                        selectionId: odds?.t2?.[22]?.sid,
                        selectionIds:
                          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38',
                        selectionName: odds?.t2?.[22]?.nat,
                        sportId: 503,
                        stake: 0,
                        type: odds?.t1?.['0']?.gtype,
                      })
                    }}
                  >
                    {odds?.t2?.['22']?.nat === 'Tiger Even' && 'Even'}
                    {odds?.t2?.['22']?.gstatus === '0' && <Suspendedlock />}
                  </DragonTigerButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {(odds?.t2?.[22]?.sid &&
                      dragontigerpayload?.dataObj?.[odds?.t2?.[22]?.sid]
                        ?.total_pnl) ||
                      0}
                  </Typography>
                </Grid>

                <Grid item xs={5.5}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {odds?.t2?.['23']?.rate}
                  </Typography>
                  <DragonTigerButton
                    size="large"
                    onClick={() => {
                      if (odds?.t2?.[1]?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: 'Chrome',
                          browser_version: '114.0.0.0',
                          device: 'Macintosh',
                          deviceType: 'desktop',
                          orientation: 'landscape',
                          os: 'Mac',
                          os_version: 'mac-os-x-15',
                          userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                          userIp: '180.151.224.106',
                        },
                        isBack: true,
                        isBetfair: false,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2?.[23]?.mid,
                        marketName: odds?.t1['0']?.gtype,
                        matchId: Number(eventId),
                        matchName: location,
                        odds: odds?.t2?.[23]?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2?.[23]?.rate,
                        selectionId: odds?.t2?.[23]?.sid,
                        selectionIds:
                          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38',
                        selectionName: odds?.t2?.[23]?.nat,
                        sportId: 503,
                        stake: 0,
                        type: odds?.t1?.['0']?.gtype,
                      })
                    }}
                  >
                    {odds?.t2?.['23']?.nat === 'Tiger Odd' && 'Odd'}
                    {odds?.t2?.['23']?.gstatus === '0' && <Suspendedlock />}
                  </DragonTigerButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {(odds?.t2?.[23]?.sid &&
                      dragontigerpayload?.dataObj?.[odds?.t2?.[23]?.sid]
                        ?.total_pnl) ||
                      0}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      textAlign: 'right',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    min:
                    <Typography
                      component="span"
                      sx={{ marginLeft: '3px', fontSize: '12px' }}
                    >
                      {odds?.t2?.['23']?.min}
                    </Typography>
                  </Typography>

                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      textAlign: 'right',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    max:
                    <Typography
                      component="span"
                      sx={{ marginLeft: '3px', fontSize: '12px' }}
                    >
                      {odds?.t2?.['23']?.max}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  padding: '10px 20px',
                  justifyContent: 'space-between',
                  maxWidth: '1180px',
                  m: 'auto',
                }}
                gap={1}
              >
                <Grid item xs={5.5}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {odds?.t2?.['24']?.rate}
                  </Typography>
                  <DragonTigerButton
                    size="large"
                    onClick={() => {
                      if (odds?.t2?.[1]?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: 'Chrome',
                          browser_version: '114.0.0.0',
                          device: 'Macintosh',
                          deviceType: 'desktop',
                          orientation: 'landscape',
                          os: 'Mac',
                          os_version: 'mac-os-x-15',
                          userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                          userIp: '180.151.224.106',
                        },
                        isBack: true,
                        isBetfair: false,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2?.[24]?.mid,
                        marketName: odds?.t1['0']?.gtype,
                        matchId: Number(eventId),
                        matchName: location,
                        odds: odds?.t2?.[24]?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2?.[24]?.rate,
                        selectionId: odds?.t2?.[24]?.sid,
                        selectionIds:
                          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38',
                        selectionName: odds?.t2?.[24]?.nat,
                        sportId: 503,
                        stake: 0,
                        type: odds?.t1?.['0']?.gtype,
                      })
                    }}
                  >
                    <img
                      src={`http://admin.kalyanexch.com/images/cards/ccard-1.png`}
                      alt=""
                      style={{ width: '30px' }}
                    />
                    {odds?.t2?.['24']?.gstatus === '0' && <Suspendedlock />}
                  </DragonTigerButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {(odds?.t2?.[24]?.sid &&
                      dragontigerpayload?.dataObj?.[odds?.t2?.[24]?.sid]
                        ?.total_pnl) ||
                      0}
                  </Typography>
                </Grid>

                <Grid item xs={5.5}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {odds?.t2?.['25']?.rate}
                  </Typography>
                  <DragonTigerButton
                    size="large"
                    onClick={() => {
                      if (odds?.t2?.[1]?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: 'Chrome',
                          browser_version: '114.0.0.0',
                          device: 'Macintosh',
                          deviceType: 'desktop',
                          orientation: 'landscape',
                          os: 'Mac',
                          os_version: 'mac-os-x-15',
                          userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                          userIp: '180.151.224.106',
                        },
                        isBack: true,
                        isBetfair: false,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2?.[25]?.mid,
                        marketName: odds?.t1['0']?.gtype,
                        matchId: Number(eventId),
                        matchName: location,
                        odds: odds?.t2?.[25]?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2?.[25]?.rate,
                        selectionId: odds?.t2?.[25]?.sid,
                        selectionIds:
                          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38',
                        selectionName: odds?.t2?.[25]?.nat,
                        sportId: 503,
                        stake: 0,
                        type: odds?.t1?.['0']?.gtype,
                      })
                    }}
                  >
                    <img
                      src={`http://admin.kalyanexch.com/images/cards/ccard-2.png`}
                      alt=""
                      style={{ width: '30px' }}
                    />
                    {odds?.t2?.['25']?.gstatus === '0' && <Suspendedlock />}
                  </DragonTigerButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {(odds?.t2?.[25]?.sid &&
                      dragontigerpayload?.dataObj?.[odds?.t2?.[25]?.sid]
                        ?.total_pnl) ||
                      0}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      textAlign: 'right',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    min:
                    <Typography component="span" sx={{ fontSize: '12px' }}>
                      {odds?.t2?.['25']?.min}
                    </Typography>
                  </Typography>

                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      textAlign: 'right',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    max:
                    <Typography component="span" sx={{ fontSize: '12px' }}>
                      {odds?.t2?.['25']?.max}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box>
            <VirtualCasinoHeader className="some m-5 p-0">
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    component="p"
                    className="header-title header-title-left"
                  >
                    News
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    component="p"
                    className="title header-title-right"
                  >
                    <Typography
                      component="span"
                      sx={{
                        textTransform: 'capitalize',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 'auto',
                        maxWidth: '100px',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      <span>play</span>
                      <span>
                        <BsFillPlayFill
                          size={22}
                          style={{ marginTop: '5px' }}
                        />
                      </span>
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </VirtualCasinoHeader>
          </Box>

          {result && <Lastresult teenTwentyResult={result} />}
        </ParentWrapper>
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        dir={theme.direction}
        className="tabpanell"
      >
        <VirtualMatchedBet />
      </TabPanel>
    </Box>
  )
}

export default VteenPattiDragonTiger
