import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import './casino.style.css'
import { theme } from '../../theme'
import LiveCasino from '../../component/common/Table/LIveCasino/LiveCasino'

const LiveCasinoPage = () => {
  return (
    <Box
      sx={{
        minHeight: 'calc(100vh - 145px)',
      }}
      className="livecasinomain"
    >
      <LiveCasino />
    </Box>
  )
}
export default LiveCasinoPage
