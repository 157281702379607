import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import authReducer from './app/features/auth/authSlilce'
import { globalApi } from './services/global'
import mainApi from './app/apis/mainApi/mainApiSlice';
import oddsApiSlice from './app/apis/oddsApi/oddsApiSlice'
import mdKalyanExchApi from './app/apis/mdkalyanExchApi/mdkalyanExchApiSlice'
import casinoApi from './app/apis/casinoApi/casinoApiSlice';
import { casinoData } from './app/apis/casino/casinoServices';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(globalApi.middleware)
      .concat(mainApi.middleware)
      .concat(oddsApiSlice.middleware)
      .concat(mdKalyanExchApi.middleware)
      .concat(casinoApi.middleware)
      .concat(casinoData.middleware),
  reducer: {
    auth: authReducer,
    [mainApi.reducerPath]: mainApi.reducer,
    [oddsApiSlice.reducerPath]: oddsApiSlice.reducer,
    [mdKalyanExchApi.reducerPath]: mdKalyanExchApi.reducer,
    [globalApi.reducerPath]: globalApi.reducer,
    [casinoApi.reducerPath]: casinoApi.reducer,
    [casinoData.reducerPath]: casinoData.reducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
