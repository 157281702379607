import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FooterContainer } from './Footer.styled'
import { useLocation, useNavigate } from 'react-router'

type SocialLinksType = {
  img: string
  link: string
}[]

const SocialLinks: SocialLinksType = [
  {
    img: '/footericon/facebook.png',
    link: '',
  },
  {
    img: '/footericon/instagram.png',
    link: '',
  },
  {
    img: '/footericon/telegram.png',
    link: '',
  },
  {
    img: '/footericon/twitter.png',
    link: '',
  },
]

const sociallinkcss = {
  backgroundColor: '#ffffff',
  height: { md: '40px', xs: '30px' },
  minWidth: { md: '40px', xs: '30px' },
  borderRadius: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '5px',
}

const imgcss: any = {
  height: '30px',
  xsheight: '20px',
}

const Footer = () => {
  const nav = useNavigate()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) // Matches xs and down
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md')) // Matches sm to md
  const isLarge = useMediaQuery(theme.breakpoints.up('md')) // Matches md and up

  const handlerNavigate = (routeTo: string) => {
    nav(`${routeTo}`)
  }
  const { pathname } = useLocation()
  return (
    <FooterContainer>
      <Grid
        container
        sx={{
          alignItems: 'center',
          flexDirection: { md: 'row', xs: 'column' },
        }}
      >
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <Typography
            component="h2"
            sx={{
              width: '100%',
              textAlign: { md: 'center' },
              fontSize: { md: '24px', xs: '20px' },
              fontWeight: { md: '700', xs: 900 },
              margin: { md: 0, xs: '15px 0 0' },
              fontFamily: 'Roboto Condensed',
            }}
          >
            24X7 Support
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} sx={{ width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: { md: 'flex-end', xs: 'center' },
              m: '15px 0 0 ',
            }}
          >
            {SocialLinks?.map((icons, idx: number) => (
              <Button
                key={idx}
                sx={{
                  ...sociallinkcss,
                  padding: '0 !important',
                  '&:hover': {
                    backgroundColor: '#fff',
                  },
                }}
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() => handlerNavigate(icons?.link)}
              >
                {isSmall && (
                  <img
                    src={icons?.img}
                    style={{
                      height: imgcss?.xsheight,
                    }}
                    alt=""
                  />
                )}
                {isLarge && (
                  <img
                    src={icons?.img}
                    style={{
                      height: imgcss?.height,
                    }}
                    alt=""
                  />
                )}
              </Button>
            ))}
          </Box>
        </Grid>
      </Grid>
    </FooterContainer>
  )
}
export default Footer
