import { Box, Button, Typography } from '@mui/material'
import styled from '@mui/material/styles/styled'
import { theme } from '../../theme'

export const ParentWrapper = styled(Box)({
  '& .p-0': {
    padding: '2px !important',
  },

  '& .m-5': {
    marginBlock: '5px',
  },
  '& .some': {
    padding: '10px',
    background: theme.palette.dark.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .pointer': {
      cursor: 'pointer',
    },
    '& .header-title-left': {
      textAlign: 'left',
    },
    '& .header-title-right': {
      textAlign: 'right',
    },
    '& .header-title': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '15px',
      fontWeight: '700',
      color: theme.palette.info.main,
      whiteSpace: 'nowrap',
    },
    '& .title': {
      fontSize: '12px',
      color: theme.palette.info.main,
    },
  },
})

export const Virtuacasinoltable = styled(Box)({
  height: '200px',
  overflow: 'hidden',
  backgroundImage: `url('/img/teenpatti_bckg.jpeg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '& .teenpattiheadertext': {
    backgroundImage: `url('img/teenpatti_header_bg.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    height: '35px',
    color: '#fff',
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .heading': {
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: '1.6',
    },
  },
  '&  .flex': {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    gap: '1rem',
  },
  '& .grid-container-for-24-card': {
    borderTop: '0px solid #fff',
    borderRight: '0px solid #fff',

    '& >*': {
      borderRight: '1px solid #fff',
      borderTop: '1px solid #fff',
    },
  },
  '& .flexright': {
    justifyContent: 'flex-end',
  },
  '& ._timer': {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    p: {
      fontSize: '20px',
      color: '#fff',
    },
  },
  '& .playerbutton': {
    // border: '3px solid #fff',
    borderRadius: '0',
    padding: '5px',
    backgroundColor: 'rgba(26,133,11,.5)',
    cursor: 'default',
    p: {
      textTransform: 'uppercase',
      width: 'auto',
      textAlign: 'center',
      fontSize: '17px',
      fontWeight: 600,
      color: '#fff',
      whiteSpace: 'nowrap',
    },
  },
})

export const CardSize = styled(Box)({
  width: '35px',
  height: '43.75px',
  margin: '0px auto 10px auto',

  img: {
    height: '100%',
  },
})

export const CustomTypography = styled(Typography)({
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 'bold',
})

export const DragonTigerButton = styled(Button)({
  '&:focus': {
    outline: '1px solid #890112',
  },
  minHeight: '28px',
  width: '100%',
  textTransform: 'uppercase',
  textAlign: 'center',
  fontSize: '12px',
  color: '#fff',
  borderRadius: '0',
  position: 'relative',
  padding: '0',
  backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.dark.main})`,
})
