import { Box, Button, CircularProgress, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useUserStackQuery, useUserStackUpdateValMutation } from '../../app/apis/mainApi/mainApiSlice'
import { getuserstack } from '../../services/types'
import snackBarUtil from '../../utils/snackbar'
import { CustomButton, CustomTextFieldLeft, CustomTextFieldRight, CustomTypography, CustomTypoParent, Tablelabel } from './changebutton.styled'
import { AiOutlineInfoCircle } from 'react-icons/ai'
const ChangeButtonValues = () => {
    const { data: getstackdata, isError: err, isLoading: loading, isSuccess: succ } = useUserStackQuery()
    const [trigger, { data: updatestack, isError, isSuccess, isLoading }] = useUserStackUpdateValMutation()
    const [stackValue, setStackValue] = useState<number[]>([]);

    const handlerStackVal = (e: any, ind: number) => {
        const data = [...stackValue];
        if (e.match(/^[0-9]{0,12}$/)) {
            data[ind] = e;
            setStackValue(data);
        } else {

        }
    }

    useEffect(() => {
        if (getstackdata?.data) {
            const data: number[] = [];
            let x: keyof getuserstack;
            for (x in getstackdata.data) {
                data.push(getstackdata.data[x]);
            }
            setStackValue(data)
        }
    }, [getstackdata]);

    const getmessage = (message: string) => {
        snackBarUtil.success(<Typography component='p' sx={{ fontSize: '14px', textAlign: 'left' }}>{message} <br />stack updated successfully</Typography>)
    }

    return (
        <Box sx={{ padding: '5px' }}>
            <CustomTypoParent>
                <CustomTypography>
                    Change Button Values
                </CustomTypography>
            </CustomTypoParent>

            <Grid container sx={{ margin: '10px 0 ' }} gap={{ md: 2, xs: 1 }}>
                <Grid item xs={5.8} md={5.9}>
                    <Tablelabel>price label</Tablelabel>
                </Grid>
                <Grid item xs={5.8} md={5.9}>
                    <Tablelabel>price value</Tablelabel>
                </Grid>
            </Grid>

            {isLoading || loading ? <CircularProgress size={40} color='primary' /> : stackValue?.length > 0 && stackValue.map((stackVal, index) => (
                <Grid container sx={{ margin: '0px 0px 10px' }} gap={{ md: 2, xs: 1 }} key={index + 1}>
                    <Grid item xs={5.8} md={5.9} >
                        <CustomTextFieldLeft fullWidth size="small" disabled InputLabelProps={{ sx: { color: "#000 !important" } }} label={`Stack ${index + 1}`} />
                    </Grid>
                    <Grid item xs={5.8} md={5.9} sx={{ height: '38px' }}>
                        <CustomTextFieldRight fullWidth type='text'
                            error={!stackVal} size='small' id="outlined-start-adornment"
                            InputProps={{
                                endAdornment: !stackVal ? <InputAdornment position='end'>
                                    <AiOutlineInfoCircle color='#f00' />
                                </InputAdornment> : '',
                                inputProps: { min: 0 }

                            }}
                            value={stackVal} placeholder="Value" onChange={(e) => handlerStackVal(e.target.value, index)} />
                    </Grid>
                </Grid>
            ))}

            <CustomButton onClick={() => {
                getmessage(getstackdata?.type || '')
                if (getstackdata?.data)
                    trigger({
                        stack1: stackValue[0],
                        stack2: stackValue[1],
                        stack3: stackValue[2],
                        stack4: stackValue[3],
                        stack5: stackValue[4],
                        stack6: stackValue[5],
                        stack7: stackValue[6],
                        stack8: stackValue[7],
                        stack9: stackValue[8],
                        stack10: stackValue[9]
                    })
            }}>
                update
            </CustomButton>
        </Box >
    )
}

export default ChangeButtonValues