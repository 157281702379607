import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FaTrophy } from 'react-icons/fa'
import { result } from '../../services/types'
import CardComp from './CardComp'

interface props {
  casinoResultModal: result | null
  // openTeenPattiDataResult: any | null
}
const T20DTResultModal = ({
  casinoResultModal,
}: // openTeenPattiDataResult
  props) => {

  const numValue = [0, 1, 2, 3, 4, 5, 6, 7, 8]

  const [trueTrue, setTrue] = useState(false)
  useEffect(() => {
    setTrue(true)
  }, [])
  return (
    <Box sx={{ mt: 3 }}>
      <Box
        p={5}
        textAlign={'center'}
        sx={{
          transform: 'scale(1.5)',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardComp shown={trueTrue} card={casinoResultModal?.C1 || ''} />
        <p style={{ margin: 'auto 3px' }} />
      </Box>
      <Typography>
        <Typography component={'b'} color={'green'} sx={{ fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          Winner: <Typography component='p' color="initial" sx={{ marginLeft: '3px', fontSize: '12px', color: '#000', fontWeight: '600' }}>
            {casinoResultModal?.detail}
          </Typography>
        </Typography>
      </Typography>
    </Box>
  )
}

export default T20DTResultModal
