import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router'
import { ruleTypography } from './ruleText'
import {
  MuiDialogActions,
  RuleButton,
  RuleTypography,
} from './ruleModal.styled'
import { RxExit } from 'react-icons/rx'
import { BsFillUnlockFill } from 'react-icons/bs'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  overflow: 'scroll',
  maxWidth: 564,
  margin: '0 auto',
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, ...other } = props

  return (
    <DialogTitle
      component="h4"
      variant="h4"
      sx={{
        m: 0,
        p: 2,
        backgroundColor: 'dark.main',
        fontFamily: 'Roboto Condensed',
        fontSize: '18px',
        fontWeight: 600,
        color: '#fff',
        textAlign: 'left',
      }}
      {...other}
    >
      {children}
    </DialogTitle>
  )
}

interface props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const RuleModal: React.FC<props> = ({ open, setOpen }) => {
  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(false)
  }

  const handlerlogout = () => {
    const token = localStorage.getItem('token')
    if (token) {
      localStorage.clear()
      navigate('login')
    }
    setOpen(false)
  }

  return (
    <div>
      <BootstrapDialog
        sx={{ maxHeight: '525px', width: '100%', padding: 1 }}
        fullWidth
        fullScreen
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          Rules
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {ruleTypography.map((ruleData) => (
            <RuleTypography gutterBottom key={ruleData?.key}>
              <Typography component="span">{ruleData?.key}</Typography>
              <Typography component="span">{ruleData?.text}</Typography>
            </RuleTypography>
          ))}
        </DialogContent>
        <MuiDialogActions>
          <RuleButton onClick={handlerlogout} variant="contained">
            Logout
            <BsFillUnlockFill size={16} style={{ marginLeft: '5px' }} />
          </RuleButton>
          <RuleButton onClick={handleClose} variant="contained">
            Accept
            <RxExit size={16} style={{ marginLeft: '5px' }} />
          </RuleButton>
        </MuiDialogActions>
      </BootstrapDialog>
    </div>
  )
}

export default RuleModal
