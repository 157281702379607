import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Marquee from 'react-fast-marquee'
import { FaHome } from 'react-icons/fa'
import { IoIosArrowDown } from 'react-icons/io'
import { RiBankFill } from 'react-icons/ri'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import RightMenu from '../RightMenu/RightMenu'
import {
  useBalanceApiQuery,
  useGetClientHeaderMessageQuery,
} from '../../../app/apis/mainApi/mainApiSlice'
import ExposureModal from '../Exposure/ExposureModal'
import { FaSearchPlus } from 'react-icons/fa'
import UpComingMatch from '../upcoming/UpComingMatch'
import { hostName } from '../../../utils/logochangewithHostname'

interface props {
  MenuOpen: boolean
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Header = ({ MenuOpen, setMenuOpen }: props) => {
  const { pathname } = useLocation()
  const username = localStorage.getItem('data')
  const parseData = username && JSON.parse(username || '')

  // const [MenuOpen, setMenuOpen] = useState(false)
  const [expOpen, setExpOpen] = useState(false)
  const { data: balanceData } = useBalanceApiQuery(undefined, {
    pollingInterval: 500,
  })
  const { data: clientHeaderData } = useGetClientHeaderMessageQuery('')

  const handleOpenUserMenu = () => {
    setMenuOpen(!MenuOpen)
  }

  const handleModalOpen = () => {
    setExpOpen(true)
  }

  const handleClose = () => {
    setExpOpen(false)
  }

  return (
    <React.Fragment>
      {/* <LeftMenuComponent /> */}
      {MenuOpen ? (
        <Box
          onClick={() => setMenuOpen(false)}
          sx={{
            backgroundColor: 'transparent',
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 10,
          }}
        ></Box>
      ) : (
        ''
      )}
      <AppBar
        position="static"
        sx={{
          //  backgroundImage: `linear-gradient(40deg,red,#0088cc)`
          backgroundColor: '#0088cc',
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ paddingInline: { md: 1 }, p: '2px !important' }}
        >
          <Toolbar
            disableGutters
            sx={{
              minHeight: 'auto',
              display: 'flex',
              alignItems: { md: 'flex-start', xs: 'flex-start' },
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="subtitle1"
              noWrap
              component="p"
              sx={{
                display: {
                  md: 'flex',
                },
                alignItems: { md: 'center', xs: 'flex-start' },
                justifyContent: 'space-around',
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '0.2rem',
                mr: 2,
                textDecoration: 'none',
                textTransform: 'capitalize',
              }}
            >
              <Link
                to={'/'}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  fontFamily: 'Roboto Condensed',
                  fontSize: '17px',
                  textTransform: 'uppercase',
                  color: '#fff',
                  textDecoration: 'none',
                  letterSpacing: 0,
                }}
              >
                <FaHome
                  color="#fff"
                  size={22}
                  style={{ marginRight: '5px', marginLeft: '5px' }}
                />
                {hostName(window.location.hostname)}
              </Link>
            </Typography>

            <Box sx={{ flexGrow: 0 }}>
              <Box>
                <Box
                  sx={{
                    display: {
                      md: 'flex',
                      xs: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    },
                  }}
                >
                  {/* <RiBankFill size={14} color="#fff" /> */}

                  <Typography
                    component="span"
                    sx={{
                      fontSize: { md: 14, xs: 12 },
                      fontWeight: { md: 600, xs: 600 },
                      marginLeft: { md: '5px', xs: '2px' },
                    }}
                  >
                    Balance {balanceData?.data?.balance}
                  </Typography>
                </Box>
              </Box>

              <IconButton onClick={handleModalOpen} sx={{ m: 0, p: 0 }}>
                <Typography
                  component="span"
                  sx={{
                    // borderBottom: {
                    //   md: '1px solid #fff',
                    //   xs: '1px solid #fff',
                    // },
                    color: { md: '#fff', xs: '#fff' },
                    fontSize: { md: '14px', xs: '12px' },
                    px: 1,
                  }}
                >
                  Exp:
                  <Typography
                    component="span"
                    sx={{
                      color: { md: '#fff', xs: '#fff' },
                      fontSize: { md: '14px', xs: '12px' },
                      marginLeft: { md: '3px', xs: '4px' },
                    }}
                  >
                    {balanceData?.data?.liability}
                  </Typography>
                </Typography>
              </IconButton>

              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Typography
                  component="span"
                  sx={{
                    // borderBottom: {
                    //   md: '1px solid #fff',
                    //   xs: '1px solid #fff',
                    // },
                    color: { md: '#fff', xs: '#fff' },
                    display: { alignItems: 'center', md: 'flex' },
                    fontSize: { md: '14px', xs: '14px' },
                    fontWeight: { xs: 'bold' },
                  }}
                >
                  {parseData?.username}
                </Typography>

                <IoIosArrowDown size={18} color="#fff" />
              </IconButton>

              {MenuOpen ? (
                <RightMenu
                  handleOpenUserMenu={handleOpenUserMenu}
                  MenuOpen={MenuOpen}
                  setMenuOpen={setMenuOpen}
                />
              ) : (
                ''
              )}
            </Box>
          </Toolbar>
        </Container>
        <Container
          maxWidth={'xl'}
          sx={{
            p: { md: 0 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: { xs: 0.8 },
          }}
        >
          <FaSearchPlus
            style={{
              width: '100%',
              height: '24px',
              fontSize: '24px',
              maxWidth: '24px',
            }}
          />
          <Marquee style={{ fontSize: '14px' }}>
            {clientHeaderData?.data?.message}
          </Marquee>
        </Container>
        {expOpen && (
          <ExposureModal expOpen={expOpen} handleClose={handleClose} />
        )}
      </AppBar>
      {(pathname === '/inplay' || pathname === '/') && (
        <UpComingMatch key={pathname} />
      )}

      <Outlet />
    </React.Fragment>
  )
}

export default Header
