import {
  Box,
  Grid,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { BookButton } from './GameDetail.styled'
import { IoMdInformationCircle } from 'react-icons/io'
import { fancyBetPayload, FancyOddsInterface } from '../../../services/types'
// import { useOddsQuery } from '../../../services/gameDetail'
import { useFancyPnlQuery } from '../../../app/apis/mainApi/mainApiSlice'
import { useParams } from 'react-router'
import { backDetail, layDetail } from './TabsDataTable'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import { postNegValue, postNegValueFancy } from '../../../utils/postNegVal'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    marginTop: '0 !important',
    padding: '10px 30px 5px 5px',
    color: '#000000',
    boxShadow: theme.shadows[1],
  },
}))

interface props {
  setSessionModal: Dispatch<SetStateAction<string>>
  matchName: string
  FancyTwoData: FancyOddsInterface
  handleClickBetModalOpen: (args: fancyBetPayload) => void
  marketname: string
}

const FancyRow: FC<props> = ({
  FancyTwoData,
  matchName,
  handleClickBetModalOpen,
  marketname,
  setSessionModal,
}) => {
  console.log(FancyTwoData, 'askdfjlkasdjflkasd')
  const [tooltip, setToolTip] = useState(false)
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const sportid = searchParams.get('sportId')

  const handlerSession = () => {
    setSessionModal(FancyTwoData.sid)
  }

  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(0)

  useEffect(() => {
    setMaxValue(FancyTwoData?.maxBet)
    setMinValue(FancyTwoData?.minBet)
  }, [])

  const handleOpenTooltip = () => {
    setToolTip((o) => !o)
  }

  const handleCloseTooltip = () => {
    setToolTip(false)
  }

  const {
    data: pnlData,
    isError,
    isSuccess,
    isLoading,
  } = useFancyPnlQuery({ matchId: id || '', marketId: FancyTwoData?.sid })

  if (!id) {
    return <>Error</>
  }

  const oddComp = [
    <Grid
      item
      xs={6}
      sx={{ cursor: 'pointer' }}
      {...backDetail}
      onClick={() => {
        sportid &&
          handleClickBetModalOpen({
            sportId: sportid,
            selectionId: '0',
            matchId: id,
            type: 'Fancy2Market',
            isBetfair: false,
            betType: 'back',
            deviceInfo: {
              browser: 'Chrome',
              browser_version: '114.0.0.0',
              device: 'Macintosh',
              deviceType: 'desktop',
              orientation: 'landscape',
              os: 'Mac',
              os_version: 'mac-os-x-15',
              userAgent:
                'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
              userIp: '180.151.224.106',
            },
            isBack: true,
            isLay: false,
            isbetfair: false,
            liability: 0,
            marketId: FancyTwoData?.sid,
            marketName: marketname,
            matchName: matchName,
            odds: FancyTwoData?.b1,
            placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
            priceValue: FancyTwoData?.bs1,
            selectionIds: '',
            selectionName: FancyTwoData?.nation,
            stake: 0,
            userId: localStorage.getItem('userId') || '',
            minValue: minValue,
            maxValue: maxValue,
            isfancy: true,
          })
      }}
    >
      <Typography
        component="b"
        sx={{ fontSize: '16px', fontWeight: '600', color: 'dark.main' }}
      >
        {FancyTwoData?.b1}
      </Typography>
      <Typography
        component="p"
        sx={{
          fontSize: '12px',
          marginBottom: '10px',
          color: 'dark.main',
        }}
      >
        {FancyTwoData?.bs1}
      </Typography>
    </Grid>,
    <Grid
      item
      xs={6}
      sx={{ cursor: 'pointer' }}
      {...layDetail}
      onClick={() => {
        sportid &&
          handleClickBetModalOpen({
            sportId: sportid,
            selectionId: '0',
            matchId: id,
            type: 'Fancy2Market',
            isBetfair: false,
            betType: 'lay',
            deviceInfo: {
              browser: 'Chrome',
              browser_version: '114.0.0.0',
              device: 'Macintosh',
              deviceType: 'desktop',
              orientation: 'landscape',
              os: 'Mac',
              os_version: 'mac-os-x-15',
              userAgent:
                'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
              userIp: '180.151.224.106',
            },
            isBack: false,
            isLay: true,
            isbetfair: false,
            liability: 0,
            marketId: FancyTwoData?.sid,
            marketName: marketname,
            matchName: '',
            odds: FancyTwoData?.l1,
            placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
            priceValue: FancyTwoData?.ls1,
            selectionIds: '',
            selectionName: FancyTwoData?.nation,
            stake: 0,
            userId: localStorage.getItem('userId') || '',
            minValue: minValue,
            maxValue: maxValue,
            isfancy: true,
          })
      }}
    >
      <Typography
        component="b"
        sx={{ fontSize: '16px', fontWeight: '600', color: 'dark.main' }}
      >
        {FancyTwoData?.l1}
      </Typography>
      <Typography component="p" sx={{ fontSize: '12px', color: 'dark.main' }}>
        {FancyTwoData?.ls1}
      </Typography>
    </Grid>,
  ]
  return (
    <Grid container>
      <Grid
        xs={8}
        item
        sx={{
          display: 'flex',
          alignItems: 'center !important',
          justifyContent: 'space-between',
          color: 'secondary.main',
          fontSize: '12px',
          padding: '2px 5px',
          borderBottom: '1px solid #999',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box textAlign={'left'}>
            <Box>
              <Typography
                sx={{
                  color: '#000',
                  fontSize: '12px',
                  fontWeight: '600',
                }}
                component="p"
              >
                {FancyTwoData?.nation}
              </Typography>
            </Box>

            <Typography sx={{ color: '#000', fontSize: '12px' }}>
              {postNegValueFancy(pnlData?.data?.[0]?.liability)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <LightTooltip
              placement="bottom-end"
              open={tooltip}
              onClick={handleOpenTooltip}
              title={
                <Box bgcolor={'transparent'} sx={{ margin: 0 }}>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      textAlign: 'left',
                      fontWeight: 600,
                    }}
                  >
                    Min:{FancyTwoData?.minBet}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      textAlign: 'left',
                      fontWeight: 600,
                    }}
                  >
                    Max:{FancyTwoData?.maxBet}
                  </Typography>
                </Box>
              }
            >
              <IconButton sx={{ p: 0 }}>
                <IoMdInformationCircle color={'#000'} size={'18px'} />
              </IconButton>
            </LightTooltip>

            <BookButton
              onClick={handlerSession}
              variant="contained"
              size="small"
              sx={{
                display: { md: 'inline-block', xs: 'inline-block' },
                bgcolor: 'dark.main',
                minWidth: '43.35px',
                padding: '4px 6px',
                fontSize: '12px',
                fontWeight: '600',
                textTransform: 'capitalize',
                marginTop: '2px',
                color: '#fff',
              }}
            >
              Book
            </BookButton>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Grid container position="relative">
          {FancyTwoData?.gstatus === 'SUSPENDED' ||
          FancyTwoData?.gstatus === 'Ball Running' ? (
            <Box
              position="absolute"
              sx={{
                cursor:
                  FancyTwoData?.gstatus === 'SUSPENDED' ? 'not-allowed' : '',
                fontFamily: 'Roboto Condensed',
                letterSpacing: '1.6px',
                width: '100%',
                height: '100%',
                top: 0,
                backgroundColor: '#00000099',
                color: '#f00',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {FancyTwoData?.gstatus}
            </Box>
          ) : (
            <></>
          )}

          {marketname === 'Fancy3' ? oddComp : oddComp.reverse()}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FancyRow
