import { Button } from '@mui/material'
import { styled } from '@mui/system'

const CustomButton = () => {
  return (
    <button color="secondary" style={{ backgroundColor: 'secondary' }}>
      Login
    </button>
  )
}

export const StyledButton = styled(Button)(() => ({
  borderRadius: '0',
  color: '#fff',
  position: 'relative',
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  fontSize: '14px',
  margin: '20px auto 5px',
  padding: '4px 0px',
  width: '100%',
}))

export default CustomButton
