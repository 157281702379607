import { useMediaQuery } from '@mui/material'
import PlayerPlusComponent from './PlayerPlusComponent/PlayerPlusComponent'
import './T20.scss'
import { useParams } from 'react-router'

const T20 = ({
  odds,
  t1,
  setOpen,
  setShowBetSection,
  setUpdated,
  setBetState,
  showPnl,
}: any) => {
  const { id } = useParams()
  const mobile = useMediaQuery('(max-width: 768px)')
  const t2 = id == '71' ? odds?.data?.t2 : odds?.t2 || []

  console.log(odds?.data?.t1[0]?.mid, 'looo')
  return (
    <div className="t20_container">
      <table>
        <thead>
          <tr>
            <th>
              Min: {id == '71' ? odds?.data?.t2[0].min : t1?.min} | Max:{' '}
              {id == '71' ? odds?.data?.t2[0].max : t1?.max}
            </th>
            <th colSpan={mobile ? 2 : 1}>BACK</th>
          </tr>
        </thead>
        <tbody>
          <PlayerPlusComponent
            setUpdated={setUpdated}
            showPnl={showPnl}
            setShowBetSection={setShowBetSection}
            setOpen={setOpen}
            showRateForFirstT2
            t2={
              id == '71'
                ? [odds?.data?.t2[0], odds?.data?.t2[1]]
                : [t2[0], t2[1]]
            }
            showRateForSecondT2Also={false}
            title={undefined}
            setBetState={setBetState}
          />
          <PlayerPlusComponent
            setUpdated={setUpdated}
            showPnl={showPnl}
            setShowBetSection={setShowBetSection}
            setOpen={setOpen}
            showRateForFirstT2
            t2={[t2[2], t2[3]]}
            showRateForSecondT2Also={false}
            title={undefined}
            setBetState={setBetState}
          />
        </tbody>
      </table>
    </div>
  )
}

export default T20
