import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useParams } from 'react-router'
import { useGetBetListByMatchResQuery } from '../../../app/apis/mdkalyanExchApi/mdkalyanExchApiSlice'
import { useEffect } from 'react'

const MatchedBet = () => {
  const { id } = useParams()
  const table_data = ['Matched Bet', 'Odds', 'Stake']
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) // Matches xs and down
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md')) // Matches sm to md
  const isLarge = useMediaQuery(theme.breakpoints.up('md')) // Matches md and up

  const namecss = {
    textAlign: 'center',
    padding: '2px 8px',
    color: '#000',
    fontWeight: 'bold',
    fontSize: '14px',
    textTransform: 'capitalize',
    fontFamily: `Roboto Condensed, sans-serif`,
  }

  const mapdatacss = {
    fontSize: '12px',
    paddingInline: '8px',
    color: '#000',
    textTransform: 'capitalize',
    fontFamily: 'Roboto Condensed',
  }

  const datanotfound = {
    textAlign: 'center',
    padding: '2px 8px',
    fontSize: '14px',
    fontFamily: 'Roboto Condensed,sans-serif',
    color: '#212529',
    marginBlock: '2px',
    // fontWeight: '600',
  }
  // const { data: getBetlistmatch } =
  //   getBetListbymarket.endpoints.getBetListByMatchRes.useQueryState(id || '')

  const { data: getBetlistmatch } = useGetBetListByMatchResQuery(
    {
      eventId: Number(id),
    },
    { refetchOnMountOrArgChange: true }
  )

  return (
    <Box>
      <Grid
        container
        xs={12}
        sx={{
          // marginTop: '5px',
          backgroundColor: '#f7f7f7',
          borderTop: '1px solid #c7c8ca',
          borderBottom: '1px solid #c7c8ca',
        }}
      >
        {table_data.map((name: string, idx) => (
          <Grid
            key={idx + 1}
            item
            xs={idx === 0 ? 8 : 2}
            sx={
              {
                // borderLeft: '1px solid #000',
                // borderRight: name === 'amount' ? '1px solid #323232' : '',
              }
            }
          >
            <Typography
              component="p"
              sx={{
                ...namecss,
                p: idx === 0 ? '2px 8px 2px 10px' : '2px 8px',
                textAlign:
                  isSmall || name === 'Matched Bet'
                    ? 'left'
                    : isLarge
                    ? 'center'
                    : 'right',
                color: '#333333',
              }}
            >
              {name}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        xs={12}
        sx={{
          borderBottom: '1px solid #c7c8ca',
        }}
      >
        {Array.isArray(getBetlistmatch?.data) &&
          getBetlistmatch?.data?.map((betlistmatch, idx: number) => (
            <Grid
              key={idx}
              container
              sx={{
                backgroundColor: betlistmatch?.isback ? '#72bbef' : '#faa9ba',
                borderTop: '1px solid #c7c8ca',
                // borderBottom: '1px solid #c7c8ca',
              }}
            >
              <Grid
                item
                xs={8}
                sx={
                  {
                    // borderLeft: '1px solid #000',
                  }
                }
              >
                <Typography
                  component="p"
                  sx={{
                    ...datanotfound,
                    // textWrap: 'nowrap',
                    textAlign: 'left',
                    p: '2px 8px 2px 10px',
                  }}
                >
                  {betlistmatch?.selectionname}
                </Typography>
              </Grid>
              {/* <Grid
                key={index + 1}
                item
                xs={3}
                sx={{
                  borderLeft: '1px solid #000',
                }}
              >
                <Typography component="p" sx={{ ...datanotfound }}>
                  {betlistmatch?.marketname}
                </Typography>
              </Grid> */}
              <Grid
                item
                xs={2}
                sx={
                  {
                    // borderLeft: '1px solid #000',
                  }
                }
              >
                <Typography component="p" sx={{ ...datanotfound }}>
                  {betlistmatch?.odds}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                sx={
                  {
                    // borderLeft: '1px solid #000',
                  }
                }
              >
                <Typography component="p" sx={{ ...datanotfound }}>
                  {betlistmatch?.stake}
                </Typography>
              </Grid>
            </Grid>
          ))}

        {!getBetlistmatch?.data && (
          <Grid item xs={12} sx={{ border: '0.5px solid #ccc' }}>
            <Typography component="p" sx={{ ...datanotfound }}>
              No records Found
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default MatchedBet
