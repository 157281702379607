import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FaTrophy } from 'react-icons/fa'
import { result } from '../../services/types'
import CardComp from './CardComp'

interface props {
  casinoResultModal: result | null
  // openTeenPattiDataResult: any | null
}
const T20OpenResultModal = ({
  casinoResultModal,
}: // openTeenPattiDataResult
  props) => {
  const numValue = [0, 1, 2, 3, 4, 5, 6, 7, 8]


  const randam = casinoResultModal?.C1?.split(',') || []
  const winner = casinoResultModal?.winner?.toString().split(',') || []

  const [trueTrue, setTrue] = useState(false)
  useEffect(() => {
    setTrue(true)
  })
  return (
    <Box sx={{ mt: 3 }}>
      <Grid container>
        {numValue?.map((res, index) => {
          return (
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  padding: '5px',
                  boxShadow: '0 0 2px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Grid item xs={4.5}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '600',
                      textTransform: 'capitalize',
                    }}
                    component="p"
                  >
                    {index === 0 ? 'Dealer' : `Player${index}`}
                  </Typography>
                </Grid>
                <Grid item xs={4.5}>
                  <Grid container>
                    <Grid item xs={4}>
                      <CardComp shown={trueTrue} card={index === 0 ? randam[index + 8] : randam[index - 1]} />
                      {/* <img
                        src={`http://admin.kalyanexch.com/images/cards/${index === 0 ? randam[index + 8] : randam[index - 1]
                          }.png`}
                        alt=""
                        style={{ width: '40px' }}
                      /> */}
                    </Grid>
                    <Grid item xs={4}>
                      <CardComp shown={trueTrue} card={index === 0 ? randam[index + 17] : randam[index + 8]} />

                      {/* <img
                        src={`http://admin.kalyanexch.com/images/cards/${index === 0 ? randam[index + 17] : randam[index + 8]
                          }.png`}
                        alt=""
                        style={{ width: '40px' }}
                      /> */}
                    </Grid>
                    <Grid item xs={4}>
                      <CardComp shown={trueTrue} card={index === 0 ? randam[index + 26] : randam[index + 17]} />

                      {/* <img
                        src={`http://admin.kalyanexch.com/images/cards/${index === 0 ? randam[index + 26] : randam[index + 17]
                          }.png`}
                        alt=""
                        style={{ width: '40px' }}
                      /> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  {winner?.[index - 1] == 'W' ? (
                    <FaTrophy color="#28a745" size={'14px'} />
                  ) : (
                    ''
                  )}

                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default T20OpenResultModal
