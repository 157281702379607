import { Box, Button } from '@mui/material'
import styled from '@mui/material/styles/styled'
import { theme } from '../../../theme'

export const PaginationParent = styled(Box)({
    margin: '10px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
})

export const Btntextstyle = styled(Button)({
    textTransform: 'capitalize',
    borderRadius: 0,
    border: '0.5px solid #ccc',
    fontSize: '12px',
})

export const CurrentBtnactive = styled(Button)({
    backgroundColor: '#0088cc',
    borderRadius: 0,
    fontSize: '12px',
    color: '#fff',
    height: '30px',
    minWidth: '32.68px',
    border: '0.5px solid #ccc',
    '&:hover': {
        backgroundColor: theme.palette.info.main,
        color: '#000'
    }
})