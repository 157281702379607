import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { Tab } from './GameDetail.styled'
import Typography from '@mui/material/Typography'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { Tabs } from './GameDetail.styled'
import { Outlet } from 'react-router'
import TabsDataTable from './TabsDataTable'
import { fancyBetPayload, gameDetailApiTypes } from '../../../services/types'
import MatchTable from '../Table/MatchTable'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography sx={{ p: 0 }}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    'aria-controls': `full-width-tabpanel-${index}`,
    id: `full-width-tab-${index}`,
  }
}

interface props {
  FancyOdds: gameDetailApiTypes
  handleClickBetModalOpen: (args: fancyBetPayload) => void
  matchName: string
  setSessionModal: Dispatch<SetStateAction<string>>
}

const GameDetailTabSection: FC<props> = ({
  FancyOdds,
  matchName,
  handleClickBetModalOpen,
  setSessionModal,
}) => {
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue)
  }
  const tabsLabelAndValue = [
    {
      label: 'Normal',
      active: FancyOdds?.Fancy2?.length > 0,
      value: (
        <TabsDataTable
          setSessionModal={setSessionModal}
          matchName={matchName}
          marketname={'Fancy2'}
          FancyOdds={FancyOdds?.Fancy2}
          handleClickBetModalOpen={handleClickBetModalOpen}
        />
      ),
    },
    {
      label: 'Normal',
      active: FancyOdds?.Fancy3?.length > 0,

      value: (
        <TabsDataTable
          setSessionModal={setSessionModal}
          matchName={matchName}
          marketname={'Fancy3'}
          FancyOdds={FancyOdds?.Fancy3}
          handleClickBetModalOpen={handleClickBetModalOpen}
        />
      ),
    },
    {
      label: 'Normal',
      active: FancyOdds?.OddEven?.length > 0,

      value: (
        <TabsDataTable
          setSessionModal={setSessionModal}
          matchName={matchName}
          marketname={'OddEven'}
          FancyOdds={FancyOdds?.OddEven}
          handleClickBetModalOpen={handleClickBetModalOpen}
        />
      ),
    },
    // {
    //   label: 'Other Matches',
    //   active: true,
    //   value: <MatchTable />,
    // },
  ]
  return (
    <Box sx={{ bgcolor: 'background.paper', width: { md: 'xl' } }}>
      <Tabs
        value={value}
        sx={{
          fontSize: '16px!important',
          height: '34px!important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onChange={handleChange}
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
        indicatorColor="secondary"
      >
        {tabsLabelAndValue
          .filter((i) => i.active)
          .map((item, index) => (
            <Tab
              key={index}
              label={item.label}
              {...a11yProps(index)}
              sx={{
                color: '#ffffff',
                fontSize: '13px',
                fontWeight: 'bold',
                fontFamily: 'Roboto Condensed',
                backgroundColor: index !== value ? '#2C3E50D9' : 'primary.main',
              }}
            />
          ))}
      </Tabs>

      <Outlet />
      {tabsLabelAndValue
        .filter((i) => i.active)
        .map((item, index) => (
          <TabPanel
            value={value}
            key={index}
            index={index}
            dir={theme.direction}
          >
            {item.value}
          </TabPanel>
        ))}
    </Box>
  )
}

export default GameDetailTabSection
