import { Box } from '@mui/material'
import styled from '@mui/material/styles/styled'
import { theme } from '../../theme'

export const ParentWrapper = styled(Box)({
    '& .p-0': {
        padding: '2px !important'
    },

    '& .m-5': {
        marginBlock: '5px'
    },
    '& .some': {
        padding: '10px',
        background: theme.palette.dark.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
        ,
        '& .pointer': {
            cursor: 'pointer',
        },
        '& .header-title-left': {
            textAlign: 'left',
        },
        '& .header-title-right': {
            textAlign: 'right'
        },
        '& .header-title': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px',
            fontWeight: '700',
            color: theme.palette.info.main
        },
        '& .title': {
            fontSize: '12px',
            color: theme.palette.info.main
        }
    },
    // '& ._cursor': {
    //     cursor: 'pointer',
    //     disabled: false
    // }
    // ,
    // '& ._cursordisable': {
    //     cursor: 'not-allowed',
    //     PointerEvent: 'none',
    //     disabled: true

    // }
})

export const Virtuacasinoltable = styled(Box)({
    height: '200px',
    overflow: 'hidden',
    backgroundImage: `url('/img/teenpatti_bckg.jpeg')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
    ,
    '& .teenpattiheadertext': {
        backgroundImage: `url('/img/teenpatti_header_bg.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        height: '35px',
        color: '#fff',
        fontSize: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .heading': {
            fontSize: '10px',
            fontWeight: '600',
            lineHeight: '1.6'
        }
    }
    ,
    '&  .flex': {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'space-between',
        gap: '1rem'

    },

    '& .flexright': {
        justifyContent: 'flex-end'
    },
    '& ._timer': {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center'
        ,
        p: {
            fontSize: '20px', color: '#fff',
        }
    }
    ,
    '& .playerbutton': {
        border: '3px solid #fff',
        borderRadius: '0',
        padding: '5px',
        backgroundColor: 'rgba(26,133,11,.5)',
        cursor: 'default',
        p: {
            textTransform: 'uppercase',
            width: 'auto',
            textAlign: 'center',
            fontSize: '10px',
            fontWeight: 600,
            color: '#fff',
            whiteSpace: 'nowrap'
        }
    }


})

export const CardSize = styled(Box)({
    width: '35px',
    height: '43.75px',
    margin: '0px auto 10px auto',

    img: {
        height: '100%'
    }
})

