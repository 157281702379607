import React from 'react'
import './style.scss'
import { FaExclamationTriangle } from 'react-icons/fa'
import { useNavigate } from 'react-router'


interface Props{
    setOpenModals: React.Dispatch<React.SetStateAction<boolean>>
}

const DisconnectionMouse = ({setOpenModals}:Props) => {
    const nav = useNavigate();

  const handleHome = () => {
        nav("/livecasino")
    }

  return (
    <div className="disconnected-message">
      <div className="text-center">
        {/* <i className="fas fa-exclamation-triangle me-2" /> */}
        <FaExclamationTriangle
          style={{
            color: '#fdcf13',
            fontSize: '14px',
            marginRight:"8px"
          }}
        />
        <b>Disconnection due to inactivity</b>
      </div>
      <div className="mt-3 text-center">
        Are you there? You have been disconnected. Please go back to home or
        start playing again
      </div>
      <div className="disconnected-buttons mt-3">
        <button type="button" className="btn btn-outline-primary" >
          Reconnect
        </button>
        <button className="btn btn-outline-danger" onClick={handleHome} >
          <span>Home</span>
        </button>
      </div>
    </div>
  )
}

export default DisconnectionMouse
