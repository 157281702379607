import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FaTrophy } from 'react-icons/fa'
import { result } from '../../services/types'
import CardComp from './CardComp'

interface props {
  casinoResultModal: result | null
  // openTeenPattiDataResult: any | null
}
const T20modaldatacard = ({
  casinoResultModal,
}: // openTeenPattiDataResult
  props) => {
  const [trueTrue, setTrue] = useState(false)
  useEffect(() => {

    setTrue(true)
  }, [])
  return (
    <Box sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            sx={{
              padding: '5px',
              boxShadow: '0 0 2px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid item xs={4.5}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '600',
                  textTransform: 'capitalize',
                }}
                component="p"
              >
                player A
              </Typography>
            </Grid>
            <Grid item xs={4.5}>
              <Grid container>
                <Grid item xs={4}>
                  <CardComp shown={trueTrue} card={casinoResultModal?.C1 || ''} />
                  {/* <img
                    src={`http://admin.kalyanexch.com/images/cards/${casinoResultModal?.C1}.png`}
                    alt=""
                    style={{ width: '40px' }}
                  /> */}
                </Grid>
                <Grid item xs={4}>
                  <CardComp shown={trueTrue} card={casinoResultModal?.C2 || ''} />

                  {/* <img
                    src={`http://admin.kalyanexch.com/images/cards/${casinoResultModal?.C2}.png`}
                    alt=""
                    style={{ width: '40px' }}
                  /> */}
                </Grid>
                <Grid item xs={4}>
                  <CardComp shown={trueTrue} card={casinoResultModal?.C3 || ''} />

                  {/* <img
                    src={`http://admin.kalyanexch.com/images/cards/${casinoResultModal?.C3}.png`}
                    alt=""
                    style={{ width: '40px' }}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              {casinoResultModal?.winner == 1 ? (
                <FaTrophy color="#28a745" size={'14px'} />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            sx={{
              padding: '5px',
              boxShadow: '0 0 2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item xs={4.5}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '600',
                  textTransform: 'capitalize',
                }}
                component="p"
              >
                player B
              </Typography>
            </Grid>
            <Grid item xs={4.5}>
              <Grid container>
                <Grid item xs={4}>
                  <CardComp shown={trueTrue} card={casinoResultModal?.C4 || ''} />
                </Grid>
                <Grid item xs={4}>
                  <CardComp shown={trueTrue} card={casinoResultModal?.C5 || ''} />
                </Grid>
                <Grid item xs={4}>
                  <CardComp shown={trueTrue} card={casinoResultModal?.C6 || ''} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              {casinoResultModal?.winner != 1 ? (
                <FaTrophy color="#28a745" size={'14px'} />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default T20modaldatacard
