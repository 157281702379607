import { Box } from '@mui/material'
import Container from '@mui/material/Container/Container'
import styled from '@mui/material/styles/styled'

export const StyledBox = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.tablebg.main, 
  backgroundColor: '#fff',
  width: '100%',
  padding: '2px',
}))
