import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { LoginRes, changePassword, accountStatementPayload, accountStatementResponse, ActiveAllCasinoRes, allactivecasinoresponse, BalanceRes, betHistoryPayload, betHistoryResponse, casinoResult, casinoResultPayload, fancyBetPayload, getActiveSports, getSessionBookRes, MatchNamesRes, oddsPnlPayload, profitandlosspayload, profitandlossResponse, unsetteledPayload, unsetteledResponse, getuserStackRes, stackValuePayload, stackValuesRes, fancyPnlRes, ErrorRes, oddsBetApiPayload, oddsBetApiResponse, virtualcasinoResponse, virtualcasinoPayload, virtualcasinoliabilityResponse, virtualcasinoliabilityPayload, casinoresultpayload, casinoresultresponse, virtualcasinoliabilityData, ChannelIdpayload, ChannelIdRes } from '../../../services/types';
import snackBarUtil from '../../../utils/snackbar';
import { logout } from '../../features/auth/authSlilce';

const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE_URL,
    prepareHeaders: headers => {
        const token = localStorage.getItem('token');
        if (token) headers.set('Authorization', `Bearer ${token}`);
        return headers;
    },
});

const wrapperQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) api.dispatch(logout());
    if (result?.error?.status === 403) api.dispatch(logout());
    if ((result.data as ErrorRes).responseCode === 401) api.dispatch(logout());
    if ((result.data as ErrorRes).responseCode === 403) api.dispatch(logout());
    return result;
};


const mainApi = createApi({
    reducerPath: 'mainApi',
    tagTypes: ['userStackTag', 'afterBetFancyPlace', 'afterOddsBetPlace', 'afterCasinoBetPlace'],
    baseQuery: wrapperQuery,
    endpoints: (build) => {
        return {
            login: build.mutation<LoginRes, any>({
                query: (args) => {
                    return {
                        url: 'auth/login',
                        method: 'POST',
                        body: args,
                    }
                },
            }),

            changepassword: build.mutation<changePassword, any>({
                query: (args) => {
                    return {
                        url: '/user/change-password',
                        method: 'PATCH',
                        body: args,
                    }
                },
                transformResponse: (raw: changePassword) => {
                    if (raw.responseCode === 200) {
                        snackBarUtil.success(raw.message)
                    } else {
                        snackBarUtil.error(raw.message);
                    }
                    return raw
                }
            }),

            balanceApi: build.query<BalanceRes, void>({
                keepUnusedDataFor: 0,
                query: () => {
                    return {
                        url: 'user/get-balance',
                        method: 'GET',
                    }
                },
            }),

            bookmakerbet: build.mutation<any, fancyBetPayload>({
                query: (args) => {
                    return {
                        url: 'place-bet/place-bookmaker-bet',
                        method: 'POST',
                        body: args,
                    }
                },
            }),

            accountStatementApi: build.query<accountStatementResponse, accountStatementPayload>({
                query: (args) => {
                    return {
                        url: `/user/get-account-statement?userId=${args?.userId}&startDate=${args?.startDate}&endDate=${args?.endDate}&type=${args?.type}`,
                        method: 'GET',
                    }
                },
            }),

            activeAllCasinoApi: build.query<ActiveAllCasinoRes, string>({
                query: () => {
                    return {
                        url: 'event/get-all-active-casino-events',
                        method: 'GET',
                    }
                },
            }),

            activeSport: build.query<getActiveSports, void>({
                query: () => {
                    return {
                        url: '/sport/get-active-sports',
                        method: 'GET',
                    }
                },
            }),

            casinoResultApi: build.query<casinoResult, casinoResultPayload>({
                query: (args) => {
                    return {
                        url: `/casino-results/all?casinoId=${args?.casinoId}&eventDate=${args?.eventDate}`,
                        method: 'GET',
                    }
                },
            }),

            getallactivecasinoApi: build.query<allactivecasinoresponse, string>({
                query: (args) => {
                    return {
                        url: `/event/get-all-active-casino-events?type=${args}`,
                        method: 'GET'
                    }
                }
            }),

            bethistoryApi: build.query<betHistoryResponse, betHistoryPayload>({
                query: (args) => {
                    return {
                        url: `/user/get-bet-history?startDate=${args?.startDate}&endDate=${args?.endDate}&sportId=${args?.sportId}&isActive=${args?.isActive}`,
                        method: 'GET'
                    }
                }
            }),

            getClientHeaderMessage: build.query({
                query: () => {
                    return {
                        url: 'user/get-client-header-message',
                        method: 'GET',
                    }
                },
            }),

            sessionBookRes: build.query<getSessionBookRes, oddsPnlPayload>({
                query: (args) => {
                    return {
                        url: `market/get-session-book?eventId=${args?.matchId}&fancyId=${args?.marketId}`,
                        method: 'GET',
                    }
                },
            }),

            matchName: build.query<MatchNamesRes, string>({
                query: (arg) => {
                    return {
                        url: 'market/get-active-eventlist-sportwise/' + arg,
                        method: 'GET',
                    }
                },
            }),

            profitandlossapi: build.query<profitandlossResponse, profitandlosspayload>({
                query: (args) => {
                    return {
                        url: `/user/get-profit-and-loss?startDate=${args?.startDate}&endDate=${args?.endDate}`,
                        method: 'GET'
                    }
                }
            }),

            unsetteledbetRes: build.query<unsetteledResponse, unsetteledPayload>({
                keepUnusedDataFor: 0,
                query: (args) => {
                    return {
                        url: `/user/get-unsettled-bets?isActive=${args?.isActive}&isDeleted=${args?.isDeleted}&${args?.sportId ? `sportId=${args?.sportId}` : ""}`,
                        method: 'GET',
                    }
                },
            }),

            userStack: build.query<getuserStackRes, void>({
                query: () => {
                    return {
                        url: 'user/get-user-stacks',
                        method: 'GET',
                    }
                },
                providesTags: ["userStackTag"]
            }),

            userStackUpdateVal: build.mutation<stackValuesRes, stackValuePayload>({
                query: (args) => {
                    return {
                        url: 'user/update-stack',
                        method: 'PATCH',
                        body: args,
                    }
                },
                invalidatesTags: ["userStackTag"]
            }),


            fancyPnl: build.query<fancyPnlRes, oddsPnlPayload>({
                query: (args) => {
                    return {
                        url: `/liability/get-fancy-market-liabilities/${args?.matchId}/${args?.marketId}`,
                        method: 'GET',
                    }
                },
                providesTags: ["afterBetFancyPlace"]
            }),

            fancybet: build.mutation<oddsBetApiResponse, fancyBetPayload>({
                query: (args) => {
                    return {
                        url: 'place-bet/place-fancy-bet',
                        method: 'POST',
                        body: args,
                    }
                },
                invalidatesTags: ["afterBetFancyPlace"]
            }),

            oddsbetApi: build.mutation<oddsBetApiResponse, oddsBetApiPayload>({
                query: (args) => {
                    return {
                        url: '/place-bet/place-odds-bet',
                        method: 'POST',
                        body: args,
                    }
                },
                invalidatesTags: ['afterOddsBetPlace']
            }),
            bookmarkerApi: build.mutation<oddsBetApiResponse, oddsBetApiPayload>({
                query: (args) => {
                    return {
                        url: '/place-bet/place-bookmaker-bet',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            OddsPnl: build.query<any, string>({
                query: (args) => {
                    return {
                        url: `/liability/get-market-liabilities/${args}`,
                        method: 'GET',
                    }

                },
                providesTags: ['afterOddsBetPlace']
            }),

            virtualCasino: build.query<virtualcasinoResponse, virtualcasinoPayload>({
                query: (args) => {
                    return {
                        url: `/event/get-all-active-casino-events?type=${args.type}`,
                        method: 'GET'
                    }
                }
            }),

            virtualcasinoliability: build.query<virtualcasinoliabilityResponse, virtualcasinoliabilityPayload>({
                providesTags: ["afterCasinoBetPlace"],
                query: (args) => {
                    return {
                        url: `/liability/get-casino-liabilities/${args?.eventid}/${args?.roundid}`,
                        method: 'GET',
                    }
                },
                transformResponse: (raw: virtualcasinoliabilityResponse) => {
                    const dataObj: { [x: string]: virtualcasinoliabilityData } = {}
                    if (raw) {
                        raw?.data?.forEach((item) => {
                            dataObj[item.selectionid] = item
                        })
                        raw.dataObj = dataObj
                    }
                    return raw
                },
            }),
            virtualcasinoresultinfo: build.query<casinoresultresponse, casinoresultpayload>({
                query: (args) => {
                    return {
                        url: `/casino-results/info?roundId=${args.roundid}&casinoId=${args.casinoid}`,
                        method: 'GET'
                    }
                }
            }),
            virtualcasinoteen20: build.mutation({
                query: (args) => {
                    return {
                        url: `${args.url}/virtual`,
                        method: 'POST',
                        body: args
                    }
                },
                invalidatesTags: ['afterCasinoBetPlace']
            }),

            channelId: build.query<ChannelIdRes, ChannelIdpayload>({
                query: (payload) => {
                    return {
                        url: 'https://api.betguru365.in/sports/channel-id-matchidwise',
                        method: 'POST',
                        body: payload
                    }
                }
            })
        }

    }
})

export const {
    useVirtualcasinoteen20Mutation,
    useLazyVirtualcasinoresultinfoQuery,
    useVirtualcasinoliabilityQuery,
    useVirtualCasinoQuery,
    useBookmarkerApiMutation,
    useLazyOddsPnlQuery,
    useOddsbetApiMutation,
    useOddsPnlQuery,
    useFancyPnlQuery,
    useLazyFancyPnlQuery,
    useFancybetMutation,
    useUserStackQuery,
    useUserStackUpdateValMutation,
    useLazyUnsetteledbetResQuery,
    useUnsetteledbetResQuery,
    useLazyProfitandlossapiQuery,
    useMatchNameQuery,
    useSessionBookResQuery,
    useGetClientHeaderMessageQuery,
    useLazyBethistoryApiQuery,
    useLazyCasinoResultApiQuery,
    useGetallactivecasinoApiQuery,
    useBalanceApiQuery,
    useBookmakerbetMutation,
    useLazyAccountStatementApiQuery,
    useActiveAllCasinoApiQuery,
    useActiveSportQuery,
    useLoginMutation,
    useChangepasswordMutation,
    useChannelIdQuery
} = mainApi;

export default mainApi;