import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FaTrophy } from 'react-icons/fa'
import { result } from '../../services/types'
import CardComp from './CardComp'

interface props {
    casinoResultModal: result | null
}
const Virtualcasinoamarakbarmodal = ({
    casinoResultModal,
}:
    props) => {

    const numValue = [0, 1, 2, 3, 4, 5, 6, 7, 8]
    const [trueTrue, setTrue] = useState(false)

    useEffect(() => {
        setTrue(true)
    }, [])
    return (
        <Box sx={{ mt: 3 }}>
            <Box
                p={5}
                textAlign={'center'}
                sx={{
                    transform: 'scale(1.5)',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <CardComp shown={trueTrue} card={casinoResultModal?.C1 || ''} />

            </Box>
            <Typography sx={{ marginBottom: '16px' }}>
                <Typography component={'b'} color={'#0dce84'} sx={{ fontSize: '12px', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Result: <Typography color="initial" component='p' sx={{ marginLeft: '3px', fontSize: '12px', color: '#000', fontWeight: '600' }}>
                        {casinoResultModal?.winner == 1 && 'Amar'}
                        {casinoResultModal?.winner == 2 && 'Akbar'}
                        {casinoResultModal?.winner == 3 && 'Anthony'}
                    </Typography>
                </Typography>
                <Typography component={'b'} color={'#0dce84'} sx={{ fontSize: '12px', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography color="initial" component='p' sx={{ marginLeft: '3px', fontSize: '12px', color: '#000', fontWeight: '600' }}>
                        {casinoResultModal?.detail}
                    </Typography>
                </Typography>
            </Typography>
        </Box>
    )
}

export default Virtualcasinoamarakbarmodal
