import ThirdHeader from '../../component/common/Header/ThirdHeader'
import RuleModal from '../../component/common/RuleModal'
import Sports from '../../component/common/Table/Sports'
import Layout from '../../layout/Layout'

const Home = () => {
  return (
    <>
      <ThirdHeader />
      <Sports />
    </>
  )
}

export default Home
