import { Button } from '@mui/material'
import React, { FC } from 'react'
import { Btntextstyle, CurrentBtnactive, PaginationParent } from './pagination.styled'

interface Props {
    current: number;
    total: number;
    next: () => void;
    prev: () => void;
    first: () => void;
    last: () => void;
}
const Pagination: FC<Props> = ({ current, total, next, prev, first, last }) => {

    return (
        <PaginationParent>
            <Btntextstyle disabled={current === 1} size='small' onClick={first}>
                first
            </Btntextstyle>
            <Btntextstyle disabled={current === 1} size='small' onClick={prev}>
                prev
            </Btntextstyle>
            <CurrentBtnactive disabled={current + 1 === 0} >
                {current || current + 1}
            </CurrentBtnactive>
            <Btntextstyle disabled={current >= total} size='small' onClick={next}>
                next
            </Btntextstyle>
            <Btntextstyle disabled={current >= total} size='small' onClick={last}>
                last
            </Btntextstyle>

        </PaginationParent >
    )
}

export default Pagination