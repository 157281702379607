import { Box, Button, FormControl, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useActiveSportQuery, useLazyUnsetteledbetResQuery } from '../../app/apis/mainApi/mainApiSlice'
// import { useActiveSportQuery } from '../../services/activeSports'
// import { useLazyUnsetteledbetResQuery } from '../../services/unsetteledbet'
import { CustomButton, CustomTypography, CustomTypoParent } from './unsetteledbet.styled'



const Unsetledbet = () => {

    const tableRow = [
        'no', 'nation', 'event name', 'event type', 'bet type', 'market name', 'rate', 'amount', 'place date'
    ]
    const [sportIdd, setSportIdd] = useState('4')

    const [trigger, { data: unsetteledData, isError, isLoading, isSuccess }] = useLazyUnsetteledbetResQuery()
    const { data: activeSportData, isError: err, isLoading: loading, isSuccess: success } = useActiveSportQuery()

    const handlerChange = (e: any) => {
        setSportIdd(e.target.value)
    }

    useEffect(() => {
        trigger({ isActive: true, isDeleted: false, sportId: sportIdd })
    }, [unsetteledData])


    return (
        <Box sx={{ padding: '5px' }}>
            <CustomTypoParent>
                <CustomTypography>
                    Un-Setteled Bet
                </CustomTypography>
            </CustomTypoParent>
            <FormControl sx={{ width: '100%' }} >
                <Select value={sportIdd} onChange={handlerChange} size="medium" sx={{ mt: 2, height: '38px', textAlign: 'left' }}>
                    {activeSportData?.data?.map((activeSport, index) => (
                        <MenuItem key={index} value={activeSport?.sportid} sx={{ color: '#000' }}>
                            {activeSport?.name}
                        </MenuItem>
                    ))}
                </Select>

                <CustomButton onClick={() => trigger({ isActive: true, isDeleted: false, sportId: sportIdd })}>
                    submit
                </CustomButton>

                <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '10px', borderRadius: 0 }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table" >
                            <TableHead>
                                <TableRow sx={{ borderRadius: 0 }}>
                                    {tableRow.map((headData, index) => (
                                        <TableCell key={index} sx={{ borderRight: '1px solid #ccc', backgroundColor: '#0088cc', color: '#fff', padding: '8px 10px', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                            <Typography component='span' sx={{ textTransform: 'capitalize', fontWeight: 600, fontSize: '14px' }}>
                                                {headData}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {unsetteledData?.data?.map((unsetteledBet, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={unsetteledBet?.id}>
                                        <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', whiteSpace: 'nowrap' }}>
                                            <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                {index + 1}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', whiteSpace: 'nowrap' }}>
                                            <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                {unsetteledBet?.selectionname}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', whiteSpace: 'nowrap' }}>
                                            <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                {unsetteledBet?.matchname}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', whiteSpace: 'nowrap' }}>
                                            <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                {unsetteledBet?.sportname}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', whiteSpace: 'nowrap' }}>
                                            <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                {unsetteledBet?.isback ? `back (lagai)` : unsetteledBet?.islay ? `lay (khai)` : ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', whiteSpace: 'nowrap' }}>
                                            <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                {unsetteledBet?.marketname}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', whiteSpace: 'nowrap' }}>
                                            <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                {unsetteledBet?.pricevalue}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', whiteSpace: 'nowrap' }}>
                                            <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                {unsetteledBet?.stake}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#000', padding: '8px 10px', whiteSpace: 'nowrap' }}>
                                            <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                {unsetteledBet?.placetime}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                {!(unsetteledData?.data && unsetteledData?.data?.length > 0) && <TableRow sx={{ padding: '10px', }}>
                                    <TableCell sx={{ backgroundColor: '#e4e4e4', textAlign: 'center', fontSize: '12px', padding: '6px', margin: '4px 0 0 ' }} colSpan={9}>
                                        No real-time records found
                                    </TableCell>
                                </TableRow>}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </FormControl>
        </Box >
    )
}

export default Unsetledbet