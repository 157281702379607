import { Alert, Box, Snackbar, SnackbarContent, Typography } from "@mui/material";
import React from "react";

const getOnLineStatus = () =>
    typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
        ? navigator.onLine
        : true;

const useNavigatorOnLine = () => {
    const [status, setStatus] = React.useState(getOnLineStatus());

    const setOnline = () => setStatus(true);
    const setOffline = () => setStatus(false);

    React.useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);

        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);

    return status;
};

const StatusIndicator = () => {
    const isOnline = useNavigatorOnLine();

    return <Snackbar open={!isOnline} >
        {isOnline ? <Alert severity="success" variant="filled" sx={{ display: 'flex', alignItems: 'center' }}>
            {isOnline && <Box sx={{ textAlign: 'left' }}>
                <Typography sx={{ fontSize: '12px', textTransform: 'capitalize' }}>
                    success
                </Typography>
                <Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>
                    {isOnline ? 'Back Online' : 'You seem to be offline!'}
                </Typography>
            </Box>}
        </Alert>
            :
            <Alert severity="error" variant="filled" sx={{ display: 'flex', alignItems: 'center', width: '300px' }}>
                {!isOnline && <Box sx={{ textAlign: 'left' }}>
                    <Typography sx={{ fontSize: '14px', textTransform: 'capitalize', fontWeight: 'bold' }}>
                        error
                    </Typography>
                    <Typography sx={{ fontSize: '13px', textTransform: 'capitalize' }}>
                        {isOnline ? 'Back Online' : 'You seem to be offline!'}
                    </Typography>
                </Box>}
            </Alert>}

    </Snackbar>
};

// ReactDOM.createRoot(document.getElementById('root')).render(
//     <StatusIndicator />
// );

export default StatusIndicator