import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { TransitionProps } from '@mui/material/transitions'
import Slide from '@mui/material/Slide'

import { IoClose } from 'react-icons/io5'
import { useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { result } from '../../../services/types'
import T20OpenResultModal from '../T20OpenResultModal'
import T20DTResultModal from '../DT20'
import DT20ResultModal from '../DT20ResultModal'
import T20modaldatacard from '../T20modaldatacard'
import Virtualcasinoamarakbarmodal from '../Virtualcasinoamarakbarmodal'
import Virtualcasinobollywoodmodal from '../Virtualcasinobollywoodmodal'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  overflow: 'scroll',
  // maxWidth: 500,
  margin: '0 auto',
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
    '& .MuiPaper': {
      margin: '0 !important',
    },
    '&. MuiPaper-root': {
      margin: '0 !important',
    },
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />
})

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, ...other } = props

  return (
    <DialogTitle
      component="h4"
      variant="h4"
      sx={{
        m: 0,
        p: 2,
        backgroundColor: 'dark.main',
        fontFamily: 'Roboto Condensed',
        fontSize: '18px',
        fontWeight: 600,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      {...other}
    >
      {children}
    </DialogTitle>
  )
}

interface props {
  casinoResultModal: result | null
  setCasinoResultModal: React.Dispatch<React.SetStateAction<result | null>>
  children?: React.ReactNode
  // openTeenPattiDataResult: any
  // setVopenCasinoResult: React.Dispatch<React.SetStateAction<null>>
}

const VirtualCasinoResultModal: React.FC<props> = ({
  casinoResultModal,
  setCasinoResultModal,
  // openTeenPattiDataResult, setVopenCasinoResult
}) => {
  const [search] = useSearchParams()
  const id = search.get('id')
  const handleClose = () => {
    setCasinoResultModal(null)
  }

  return (
    <div>
      {casinoResultModal && (
        // || openTeenPattiDataResult
        <BootstrapDialog
          TransitionComponent={Transition}
          onClick={handleClose}
          sx={{ width: '100%', p: 1 }}
          fullWidth
          aria-labelledby="customized-dialog-title"
          open={
            !!casinoResultModal
            // ||!!openTeenPattiDataResult
          }
        >
          <BootstrapDialogTitle id="customized-dialog-title">
            Casino Results
            <IoClose
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
              size={26}
            />
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography
              component="p"
              sx={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'right' }}>
              Round Id:{casinoResultModal?.mid}
            </Typography>
            {id == '5041' && (
              <T20modaldatacard
                casinoResultModal={casinoResultModal}
              // openTeenPattiDataResult={openTeenPattiDataResult}
              />
            )}
            {id == '5042' && (
              <T20OpenResultModal
                casinoResultModal={casinoResultModal}
              />
            )}
            {id == '5011' && (
              <T20DTResultModal
                casinoResultModal={casinoResultModal}
              />
            )}
            {id == '5031' && (
              <DT20ResultModal casinoResultModal={casinoResultModal} />
            )}
            {id == '5021' && (
              <Virtualcasinoamarakbarmodal casinoResultModal={casinoResultModal} />
            )}
            {id == '5022' && (
              <Virtualcasinobollywoodmodal casinoResultModal={casinoResultModal} />
            )}
          </DialogContent>
        </BootstrapDialog>
      )}
    </div>
  )
}

export default VirtualCasinoResultModal
