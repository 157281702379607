import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { betlistbymarketResponse, betlsitbymarketPayload, getbetlistbymatchs } from '../../../services/types'

const mdKalyanExchApi = createApi({
    reducerPath: 'mdkalyanExch',
    tagTypes: ["refetchafterbet"],
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_KALYANEXCH_URL,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token')
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    endpoints: (build) => ({
        getBetListByMarketApi: build.query<betlistbymarketResponse, betlsitbymarketPayload>({
            query: (args) => {
                return {
                    url: `bets/get-betlist-by-market?userId=${args?.userId}&marketId=${args?.marketId}`,
                    method: 'GET'
                }
            },
            providesTags: ['refetchafterbet']
        }),
        getBetListByMatchRes: build.query<getbetlistbymatchs, { eventId: number }>({
            query: (args) => {
                // const user = localStorage.getItem('user')
                const userid = localStorage.getItem('data')
                const parseData = userid && JSON.parse(userid)
                const { eventId } = args
                return {
                    url: `bets/get-betlist-by-match?userId=${parseData?.userid}&eventId=${eventId}`,
                    method: 'GET',
                }

            },
        }),
    })
})

export const {
    useGetBetListByMatchResQuery,
    useGetBetListByMarketApiQuery
} = mdKalyanExchApi

export default mdKalyanExchApi