import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FaTrophy } from 'react-icons/fa'
import { result } from '../../services/types'
import CardComp from './CardComp'

interface props {
  casinoResultModal: result | null
}
const DT20ResultModal = ({ casinoResultModal }: props) => {
  const [trueTrue, setTrue] = useState(false)

  useEffect(() => {
    setTrue(true)
  }, [])
  const numValue = [0, 1, 2, 3, 4, 5, 6, 7, 8]

  return (
    <Box sx={{ mt: 3 }}>
      <Box
        p={5}
        textAlign={'center'}
        sx={{
          transform: 'scale(1.5)',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardComp shown={trueTrue} card={casinoResultModal?.C1 || ''} />
        <p style={{ margin: 'auto 3px' }} />
        <CardComp shown={trueTrue} card={casinoResultModal?.C2 || ''} />
      </Box>
      <Typography sx={{ marginBottom: '16px' }}>
        <Typography
          component={'b'}
          color={'#0dce84'}
          sx={{
            fontSize: '12px',
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Result:{' '}
          <Typography
            color="initial"
            component="p"
            sx={{
              marginLeft: '3px',
              fontSize: '12px',
              color: '#000',
              fontWeight: '600',
            }}
          >
            {casinoResultModal?.winnerDetail}
          </Typography>
        </Typography>
        <Typography
          component={'b'}
          color={'#0dce84'}
          sx={{
            fontSize: '12px',
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Dragon:{' '}
          <Typography
            color="initial"
            component="p"
            sx={{
              marginLeft: '3px',
              fontSize: '12px',
              color: '#000',
              fontWeight: '600',
            }}
          >
            {casinoResultModal?.dragonDetail}
          </Typography>
        </Typography>
        <Typography
          component={'b'}
          color={'#0dce84'}
          sx={{
            fontSize: '12px',
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Tiger:{' '}
          <Typography
            color="initial"
            component="p"
            sx={{
              marginLeft: '3px',
              fontSize: '12px',
              color: '#000',
              fontWeight: '600',
            }}
          >
            {casinoResultModal?.tigerDetail}
          </Typography>
        </Typography>
      </Typography>
    </Box>
  )
}

export default DT20ResultModal
