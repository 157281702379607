import React from 'react'
import LiveCasino from './LIveCasino/LiveCasino'
import MatchTable from './MatchTable'
import VirtualCasino from './VirtualCasino/VirtualCasino'

const Sports = () => {
  return (
    <>
      <MatchTable />
      <LiveCasino />
      {/* <VirtualCasino /> */}
    </>
  )
}

export default Sports
