import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useLazyVirtualcasinoresultinfoQuery } from '../../app/apis/mainApi/mainApiSlice'
import { result, virtualcasinoResult, vopenresultdata } from '../../services/types'
import { LetterAndColorById } from './LetterAndColorbyid'
import VirtualCasinoHeader from './VirtualAndLiveCasino/VirtualCasinoHeader'
import VirtualCasinoResultModal from './VirtualCasinoModal/VirtualCasinoResultModal'

interface props {
  teenTwentyResult?: virtualcasinoResult
  // openTeenPattiDataResult: any
}

const Lastresult = ({
  teenTwentyResult,
}: // openTeenPattiDataResult
  props) => {
  // const [searchParams, setSearchParams] = useSearchParams()
  // const casinoId = searchParams.get('id')
  const [casinoResultModal, setCasinoResultModal] = useState<result | null>(
    null
  )
  const [vopencasinoresult, setVopenCasinoResult] = useState<any>(null)
  const handlecasinorulemodal = (res: result) => {
    setCasinoResultModal(res)
  }
  const [search] = useSearchParams()
  const id = search.get('id')
  const handlevopencasinoresult = (opencasinores: any) => {
    setVopenCasinoResult(opencasinores)
  }

  // const [trigger, { data: casinoresultData }] = useLazyVirtualcasinoresultinfoQuery({ roundid: teenTwentyResult?.[0]?.mid, casinoid: casinoId || "" })

  const navigate = useNavigate()
  return (
    <Box className="viewall">
      <VirtualCasinoResultModal
        casinoResultModal={casinoResultModal}
        // vopencasinoresult={vopencasinoresult}
        setCasinoResultModal={setCasinoResultModal}
      // setVopenCasinoResult={setVopenCasinoResult}
      />
      <VirtualCasinoHeader className="some m-5 p-0">
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Grid item xs={6}>
            <Typography
              component="p"
              sx={{ textTransform: 'capitalize' }}
              className="header-title header-title-left"
            >
              last result
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="p" className="title header-title-right">
              <Typography
                onClick={() => navigate('/casinoresult', { state: { id } })}
                component="span"
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  maxWidth: '100px',
                  justifyContent: 'flex-end',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
              >
                view all
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </VirtualCasinoHeader>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
        className="viewresult"
      >
        {teenTwentyResult?.map((data) => (
          <Typography
            component="span"
            onClick={() => handlecasinorulemodal(data)}
            sx={{
              fontSize: '12px',
              cursor: 'pointer',
              margin: ' 0px 2px 2px 2px',
              height: '30px',
              width: '30px',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: '600',
              color: '#fff',
              backgroundColor: id && LetterAndColorById[id][data.winner.toString().charAt(0)]?.color,

            }}
          >
            {id && LetterAndColorById[id][data.winner?.toString().charAt(0)]?.label}
          </Typography>
        ))}
      </Box>

    </Box>
  )
}

export default Lastresult