import { Typography } from '@mui/material'
import React from 'react'

export const postNegValue = (value?: string | number | null) =>
  value != null && (
    <Typography
      component="span"
      variant="caption"
      sx={{
        color: Number(value) < 0 ? '#ff3c3c' : '#14805e',
      }}
    >
      {Number(value)}
    </Typography>
  )

export const postNegValueFancy = (value?: string | number | null) =>
  value != null && (
    <Typography
      component="span"
      variant="caption"
      sx={{
        color: '#ff3c3c',
      }}
    >
      {Number(-value)}
    </Typography>
  )
