import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { TopTab } from './GameDetail.styled'
import Typography from '@mui/material/Typography'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { TopTabs } from './GameDetail.styled'
import { Outlet, useLocation, useParams } from 'react-router'
import GameDetail from './GameDetail'
import { Button, Paper, useMediaQuery } from '@mui/material'
import MatchedBet from './MatchedBet'
import { AiOutlineDesktop } from 'react-icons/ai'
import { useGetBetListByMatchResQuery } from '../../../app/apis/mdkalyanExchApi/mdkalyanExchApiSlice'
import Mybet from '../../../Casino_New/LiveTennPatti/Mybet/Mybet'
import { useGetCasinoMyBetQuery } from '../../../app/apis/casinoApi/casinoApiSlice'
import './style.css'
interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
  className: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {/*  minHeight: 'calc(100vh - 145px)' */}
      {value === index && (
        <Box sx={{ p: 0 }} className={`${value == 1 ? 'tabpanelheight' : ''}`}>
          <Typography sx={{ p: 0 }}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

interface GameDetailTopTabsProps {
  tableId?: string | any
  roundid?: any
  setValue: Dispatch<SetStateAction<number>>
  value: number
}

const GameDetailTopTabs: FC<GameDetailTopTabsProps> = ({
  tableId,
  roundid,
  setValue,
  value,
}) => {
  // const [scoreIframe, setScoreIframe] = useState(true)
  const [liveIframe, setLiveIfram] = useState(false)

  const handleliveifram = () => {
    setLiveIfram(!liveIframe)
  }

  const theme = useTheme()

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue)
  }
  const { id } = useParams()

  const { data: betlistmatch } = useGetBetListByMatchResQuery(
    {
      eventId: Number(id),
    },
    { pollingInterval: 2000 }
  )

  // console.log(betlistmatch, 'gamedetail')

  const { pathname } = useLocation()

  const { data: betList } = useGetCasinoMyBetQuery(
    { tableId: tableId, isGameCompleted: false, sportId: 5015 },
    { pollingInterval: 1500, refetchOnMountOrArgChange: true }
  )

  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) // Matches xs and down

  return (
    <Box sx={{ width: { md: 'xl' }, overflow: 'hidden' }}>
      <Paper
        elevation={10}
        sx={{
          bgcolor: 'tabsbgclr.main',
          height: 35,
          borderRadius: 0,
          display: 'flex',
          // alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TopTabs
          aria-activedescendant="active"
          value={value}
          onChange={handleChange}
          variant="standard"
          aria-label="full width tabs example"
          // indicatorColor="secondary"
          sx={{ width: { xs: pathname.includes('casino') ? '50%' : '100%' } }}
        >
          <TopTab
            label={
              !pathname.includes('casino')
                ? `odds`.toUpperCase()
                : `Game`.toUpperCase()
            }
            onClick={() => setValue(0)}
            sx={{
              px: { xs: pathname.includes('casino') ? 0 : 1 },
              minWidth: pathname.includes('casino') ? 'unset' : '30px',
              maxWidth: pathname.includes('casino') ? '50px' : '100px',
              fontWeight: 'bold',
            }}
          />
          {
            <TopTab
              className="top-tabsss"
              sx={{
                px: { xs: 1 },
                fontWeight: 'bold',
                // minWidth: pathname.includes('casino') ? '70px' : 'auto',
                // maxWidth: pathname.includes('casino') ? '70px' : '100px',
                maxWidth: pathname.includes('casino') ? '50px' : '140px',
                marginLeft: pathname.includes('casino') ? '5px' : '0',
              }}
              label={
                !pathname.includes('casino')
                  ? `Matched bet (${
                      betlistmatch?.data?.length
                        ? betlistmatch?.data?.length
                        : 0
                    })`.toUpperCase()
                  : `Placed bet (${
                      betList?.data?.length ? betList?.data?.length : 0
                    })`.toUpperCase()
              }
              onClick={() => setValue(1)}
            />
          }

          {pathname.includes('/gamedetail') && (
            <Button
              sx={{ marginLeft: 'auto', pb: 0 }}
              disableRipple
              onClick={handleliveifram}
            >
              <AiOutlineDesktop color="#fff" size={22} />
            </Button>
          )}
        </TopTabs>
        {pathname.includes('casino') && isSmall && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
              fontSize: '12px',
              fontWeight: 'bold',
              textWrap: 'nowrap',
              paddingRight: '2px',
            }}
            className="roundid"
          >
            Round Id: {roundid ? roundid?.split('.')[1] : 0}
          </Typography>
        )}
      </Paper>

      <TabPanel
        value={value}
        index={0}
        dir={theme.direction}
        className="tabpanell"
      >
        {!pathname.includes('casino') && <GameDetail liveIframe={liveIframe} />}
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        dir={theme.direction}
        className={`tabpanell `}
      >
        {!pathname.includes('casino') ? (
          <MatchedBet />
        ) : (
          <>{value === 1 && <Mybet tableId={tableId} />}</>
        )}
      </TabPanel>
    </Box>
  )
}

export default GameDetailTopTabs
