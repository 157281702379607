// import { createTheme } from '@mui/material/styles'

import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'swiper/css'
import 'swiper/css/navigation'
import './App.css'
import { LoginComponent } from './component/Login/LoginComponent'
import Layout from './layout/Layout'
import Home from './screens/Home/Home'
import { theme } from './theme'
import StatusIndicator from './utils/InternetConnectivity'
import { SnackbarUtilsConfigurator } from './utils/snackbar'
import { hostName } from './utils/logochangewithHostname'
import '/assets/fonticon.css'

// import { Counter } from './features/counter/Counter'
// import { useGetDocsListQuery } from './services/docs'
const App: React.FC = () => {
  const [MenuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    const currentHostname = window.location.hostname
    const websiteTitle = hostName(currentHostname)
    if (websiteTitle) {
      document.title = websiteTitle
    } else {
      document.title = window.location.hostname
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoHideDuration={2500}
      >
        <BrowserRouter>
          <main className={`App ${MenuOpen && 'scrollBlock'}`}>
            <StatusIndicator />
            <Layout MenuOpen={MenuOpen} setMenuOpen={setMenuOpen} />
          </main>
        </BrowserRouter>
        <SnackbarUtilsConfigurator />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
