import { Box, Button, TextField, Typography } from '@mui/material'
import styled from '@mui/material/styles/styled'
import { theme } from '../../theme'

export const CustomTypoParent = styled(Box)({
    backgroundColor: theme.palette.primary.main,
    padding: '8px 15px',
    textAlign: 'left',
})
export const CustomTypography = styled(Typography)({
    fontSize: '16px', color: '#fff', textTransform: 'capitalize'
})

export const Tablelabel = styled(Typography)({
    fontSize: '14px', color: theme.palette.dark.main, textTransform: 'capitalize',
    marginBottom: '0 0 8px',
    fontWeight: 600,
    textAlign: 'left'

})

export const CustomButton = styled(Button)({
    backgroundColor: theme.palette.dark.main,
    color: '#fff',
    borderRadius: 0,
    marginTop: '10px',
    width: '100%',
    fontSize: '14px',
    textTransform: 'capitalize',
    padding: '4px 40px',
    margin: '8px 0 0',
    '&:hover': {
        backgroundColor: theme.palette.dark.main
    }
})

export const CustomTextFieldLeft = styled(TextField)({
    backgroundColor: '#E9ECEF',
    width: '100%',
    // height: '38px',

    '& .MuiInputBase-root': {
        // height: '38px',
        borderRadius: 0
    }
})

export const CustomTextFieldRight = styled(TextField)({
    backgroundColor: theme.palette.info.main,
    width: '100%',
    height: '38px',
    '& .MuiInputBase-root': {
        height: '38px',
        borderRadius: 0
    },

})