import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyBhRct3K_0fsEz_EhKD-wlAgqA6Fh-dpAI",
    authDomain: "aaaa-14afe.firebaseapp.com",
    databaseURL: "https://aaaa-14afe.firebaseio.com",
    projectId: "aaaa-14afe",
    storageBucket: "aaaa-14afe.appspot.com",
    messagingSenderId: "333971305520",
    appId: "1:333971305520:web:10cdf1a6fd5407ecf0a9f5",
    measurementId: "G-X98DVQR0KM"
};

firebase.initializeApp(firebaseConfig)
export const database = firebase.database()
export default firebaseConfig