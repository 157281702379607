export const hostName = (currentHostName?: string | any) => {
    console.log(currentHostName, 'asdkfjskdfj')
    if (currentHostName == 'Khelo7.com' || currentHostName == 'khelo7.com') {
        return 'khelo7'
    } else if (
        currentHostName == 'kalyanexch.com' ||
        currentHostName == 'Kalyanexch.com'
    ) {
        return 'kalyanexch'
    } else if (currentHostName === 'localhost') {
        return 'localhost'
    }
}