import styled from '@mui/material/styles/styled'
import MuiTabs from '@mui/material/Tabs'
import MuiDivider from '@mui/material/Divider'

export const Tabs = styled(MuiTabs)({
  minHeight: '36px',
  '& .MuiTabs-scroller': {
    overflowX: 'scroll !important'
  },
  '& .MuiButtonBase-root::after': {
    height: '18px',
    width: '1px',
    content: `""`,
    backgroundColor: '#fff',
    position: 'absolute',
    right: 0,
  },
  '& .MuiButtonBase-root:last-child:after': {
    width: 0,
  },
  '& .MuiButtonBase-root': {
    opacity: 1,
    paddingTop: 0,
    fontSize: '0.6875rem',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#fff',
    height: '0px',
    top: 0,
  },
})

export const Divider = styled(MuiDivider)({
  color: '#fff',
})
