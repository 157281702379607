import styled from '@mui/material/styles/styled'
import MuiTabs from '@mui/material/Tabs'
import MuiTab, { tabClasses } from '@mui/material/Tab'
import MuiButton from '@mui/material/Button'
import Marquee from 'react-fast-marquee'

export const Tabs = styled(MuiTabs)({
  minHeight: '35px',

  '& .MuiButtonBase-root::after': {
    height: '100%',
    width: '1px',
    content: `""`,
    backgroundColor: '#0088cc',
    position: 'absolute',
    right: 0,
  },
  '& .MuiButtonBase-root:last-child:after': {
    width: 0,
  },
  '& .MuiButtonBase-root': {
    zIndex: 4,
    opacity: 1,
    paddingTop: 0,
    height: '34px',
    fontSize: '0.875rem',
    textTransform: 'capitalize',
  },

  '& .MuiTabs-indicator': {
    backgroundColor: 'none',
    height: '0px',
    top: 0,
  },

  backgroundColor: '#444444',
})

export const Tab = styled(MuiTab)({
  width: '100%',
  padding: 0,
  color: '#fff',
  '&:hover': {
    backgroundColor: `#0088cc !important`,
  },
})

export const TopTabs = styled(MuiTabs)({
  minHeight: '35px',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  // width: '100%',
  justifyContent: 'space-between',
  '& .MuiButtonBase-root::after': {
    // height: '',
    height: '20px',
    width: '1px',
    content: `""`,
    backgroundColor: '#fff',
    position: 'absolute',
    right: 0,
  },

  '& .MuiButtonBase-root:last-child:after': {
    width: 0,
  },

  '& .MuiButtonBase-root': {
    zIndex: 4,
    opacity: 1,
    paddingTop: 0,
    fontSize: '12px',
    textTransform: 'capitalize',
  },

  '& .MuiTabs-indicator': {
    backgroundColor: 'none',
    height: '1px',
    top: 0,
    color: 'info.main',
  },
})

export const TopTab = styled(MuiTab)({
  [`&.${tabClasses.selected}`]: {
    color: '#fff',
  },
  fontSize: '14px',
  color: '#fff',
  paddingBottom: 0,
  height: '30px',
  minHeight: 'unset',
  // width: 'calc(100% / 2.4)',
  width: '100%',
  textWrap: 'nowrap'
})

export const BookButton = styled(MuiButton)({
  '&:hover': {
    backgroundColor: '#2C3E50',
    color: '#fff',
  },
})

export const MatchOddsMarquee = styled(Marquee)({
  fontSize: '12px',
  fontFamily: `Roboto Condensed, sans-serif`,
  color: '#8B0000',
  display: 'flex',
  padding: '8px',
  margin: '2px',
  textTransform: 'capitalize',
})
