import React, { FC, SetStateAction, useEffect, useState } from 'react'
import { Typography, Box } from '@mui/material'
import { Grid } from '@mui/material'
// import { useTheme } from '@mui/material/styles'
import { theme } from '../../../theme'
import { fancyBetPayload, MatchOddInterface } from '../../../services/types'
import {
  useLazyOddsPnlQuery,
  useOddsPnlQuery,
} from '../../../app/apis/mainApi/mainApiSlice'
import Marquee from 'react-fast-marquee'
import { MatchOddsMarquee } from './GameDetail.styled'
import moment from 'moment'
import { useParams, useSearchParams } from 'react-router-dom'
import { postNegValue } from '../../../utils/postNegVal'

interface Props {
  marketData: MatchOddInterface
  matchId: string
  handleClickBetModalOpen: (args: fancyBetPayload) => void
  profit: any
  // setOddsPnll: any
}

const MatchOdds: FC<Props> = ({
  marketData,
  matchId,
  handleClickBetModalOpen,
  // setOddsPnll,
  profit,
}) => {
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(0)
  const sportid = searchParams.get('sportId')

  useEffect(() => {
    setMinValue(marketData?.minBet)
    setMaxValue(marketData?.maxBet)
  }, [])

  const skyLight = [
    {
      bgcolor: 'back.sky',
    },
    {
      bgcolor: 'skylightTwo.main',
    },
    {
      bgcolor: 'skylight.main',
    },
  ]

  const pinkMain = [
    {
      bgcolor: 'lay.pink',
    },
    {
      bgcolor: 'pinklightTwo.main',
    },
    {
      bgcolor: 'pinklight.main',
    },
  ]

  const itemBack = {
    borderLeft: '1px solid #999',
    height: '41px',
  }

  const itemBackOddTypography = {
    sx: {
      textTransform: 'uppercase',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    md:{
      textTransform: 'uppercase',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    color: 'dark.main',
  }

  const itemBackNumberTypography = {
    sx: {
      textTransform: 'capitalize',
      fontSize: '10px',
      // fontWeight: '600',
    },
    color: 'dark.main',
  }

  const itemLay = {
    borderLeft: '1px solid #999',
    height: '41px',
  }

  const itemLayOddTypography = {
    sx: {
      textTransform: 'uppercase',
      fontSize: '14px',
      fontWeight: '600',
    },
    md: {
      textTransform: 'uppercase',
      fontSize: '16px',
      fontWeight: '600',
    },
    color: 'dark.main',
  }

  const {
    data: oddsPnlData,
    isError,
    isSuccess,
    isLoading,
  } = useOddsPnlQuery(marketData.marketId, { pollingInterval: 2000 })

  const oddsPnlObj = {
    [oddsPnlData?.data?.[0]?.selection1]: oddsPnlData?.data?.[0].pnl1,
    [oddsPnlData?.data?.[0]?.selection2]: oddsPnlData?.data?.[0].pnl2,
    [oddsPnlData?.data?.[0]?.selection3]: oddsPnlData?.data?.[0].pnl3,
  }

  // useEffect(() => {
  //   if (oddsPnlData?.data) {
  //     setOddsPnll(oddsPnlData.data);
  //   }
  // }, [oddsPnlData]);

  return (
    <Box>
      <Box key={marketData.marketId}>
        <Box
          className="heading"
          sx={{
            backgroundColor: 'gdheadingbg.main',
            padding: '10px 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            // marginTop: '10px',
          }}
        >
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontSize: '12px',
              color: '#fff',
              fontWeight: '600',
              textAlign: 'left',
            }}
          >
            {marketData?.Name}
          </Typography>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontSize: '12px',
              color: '#fff',
              fontWeight: '600',
              textAlign: 'left',
            }}
          >
            {`bet delay -${marketData?.betDelay} sec`}
          </Typography>
        </Box>
        <Box>
          <Grid container sx={{ borderBlock: '1px solid #999' }}>
            <Grid
              md={6.7}
              xs={6.7}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '44px',
                padding: '5px',
                fontSize: '12px',
                fontWeight: '600',
                color: '#000',
              }}
            >
              Max:
              <Typography
                component="p"
                sx={{
                  fontSize: '12px',
                  fontWeight: '600',
                  fontFamily: 'Roboto Condensed!important',
                  color: 'dark.main',
                }}
              >
                {marketData?.maxBet}
              </Typography>
            </Grid>
            <Grid
              item
              md={1.3}
              xs={1.3}
              sx={{ padding: '2px', borderLeft: '1px solid #999' }}
              bgcolor={theme.palette.back.sky}
            >
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontSize: '12px',
                  fontWeight: '600',
                }}
                color={theme.palette.dark.main}
              >
                back
              </Typography>
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  fontSize: '12px',
                  fontWeight: '600',
                }}
                color={theme.palette.dark.main}
              >
                (lagai)
              </Typography>
            </Grid>
            <Grid
              item
              md={1.3}
              xs={1.3}
              sx={{
                padding: '2px',
                borderLeft: `1px solid ${theme.palette.gamedetailtablebg.main}`,
              }}
              bgcolor={theme.palette.lay.pink}
            >
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontSize: '12px',
                  fontWeight: '600',
                }}
                color={theme.palette.dark.main}
              >
                lay
              </Typography>
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  fontSize: '12px',
                  fontWeight: '600',
                }}
                color={theme.palette.dark.main}
              >
                (khai)
              </Typography>
            </Grid>
            <Grid item xs={2} 
            // xs={0}
            ></Grid>
          </Grid>
          {marketData?.runners.map((runner) => (
            <Grid container sx={{ borderBottom: '1px solid #999', flexWrap:"nowrap" }}>
              <Grid
                item
                // xs={8}
                xs={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  height: '41px',
                  padding: '2px 5px',
                  fontSize: '12px',
                  fontWeight: '600',
                  textTransform: 'capitalize',
                  color: 'dark.main',
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden"
                }}
              >
                {runner.name}
                <Typography
                  component="span"
                  sx={{
                    fontSize: '12px',
                    fontWeight: '600',
                    marginLeft: '10px',
                    color: 'red',
                  }}
                >
                  {postNegValue(oddsPnlObj[runner.selectionId])}
                </Typography>
              </Grid>
              <Grid container xs={8} 
              // xs={4} 
              position="relative">
                {marketData?.status === 'SUSPENDED' ? (
                  <Box
                    position="absolute"
                    sx={{
                      width: '100%',
                      height: '100%',
                      top: 0,
                      backgroundColor: '#00000099',
                      color: 'status.danger',
                      fontWeight: '600',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {marketData?.status}
                  </Box>
                ) : (
                  <></>
                )}
                {runner?.ex?.availableToBack
                  ?.map((backData, index) => (
                    <Grid
                      onClick={
                        index === 0
                          ? () => {
                              marketData?.matchName &&
                                sportid &&
                                id &&
                                handleClickBetModalOpen({
                                  isBetfair: false,
                                  betType: 'back',
                                  deviceInfo: {
                                    browser: 'Chrome',
                                    browser_version: '114.0.0.0',
                                    device: 'Macintosh',
                                    deviceType: 'desktop',
                                    orientation: 'landscape',
                                    os: 'Mac',
                                    os_version: 'mac-os-x-15',
                                    userAgent:
                                      'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                                    userIp: '180.151.224.106',
                                  },
                                  isBack: true,
                                  isLay: false,
                                  isbetfair: false,
                                  liability: 0,
                                  marketId: marketData?.marketId,
                                  marketName: marketData?.Name,
                                  matchId: id,
                                  matchName: marketData?.matchName,
                                  odds: backData?.price,
                                  placeTime: moment().format(
                                    'YYYY-MM-DD hh:mm:ss'
                                  ),
                                  priceValue: backData?.price,
                                  selectionId: runner?.selectionId,
                                  selectionIds: '',
                                  selectionName: runner?.name,
                                  sportId: sportid,
                                  stake: 0,
                                  type: marketData?.Name,
                                  userId: localStorage.getItem('userId') || '',
                                  runner: marketData?.runners,
                                  minValue: minValue,
                                  maxValue: maxValue,
                                  isfancy: false,
                                  oddsPnlObj,
                                })
                            }
                          : () => {}
                      }
                      item
                      // display={{
                      //   xs: index !== 0 ? 'none' : 'block',
                      //   md: 'block',
                      // }}
                      sx={{ cursor: index === 0 ? 'pointer' : 'not-allowed' }}
                      // xs={6}
                      xs={2}
                      {...itemBack}
                      {...skyLight[index]}
                    >
                      <Typography {...itemBackOddTypography}>
                        {backData?.price}
                      </Typography>
                      <Typography {...itemBackNumberTypography}>
                        {backData?.size}
                      </Typography>
                    </Grid>
                  ))
                  .reverse()}
                {runner?.ex?.availableToLay?.map((layData, index) => (
                  <Grid
                    onClick={
                      index === 0
                        ? () => {
                            id &&
                              marketData?.matchName &&
                              sportid &&
                              handleClickBetModalOpen({
                                isBetfair: false,
                                betType: 'lay',
                                deviceInfo: {
                                  browser: 'Chrome',
                                  browser_version: '114.0.0.0',
                                  device: 'Macintosh',
                                  deviceType: 'desktop',
                                  orientation: 'landscape',
                                  os: 'Mac',
                                  os_version: 'mac-os-x-15',
                                  userAgent:
                                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
                                  userIp: '180.151.224.106',
                                },
                                isBack: false,
                                isLay: true,
                                isbetfair: false,
                                liability: 0,
                                marketId: marketData?.marketId,
                                marketName: marketData?.Name,
                                matchId: id,
                                matchName: marketData?.matchName,
                                odds: layData?.price,
                                placeTime: moment().format(
                                  'YYYY-MM-DD hh:mm:ss'
                                ),
                                priceValue: layData?.price,
                                selectionId: runner?.selectionId,
                                selectionIds: '',
                                selectionName: runner?.name,
                                sportId: sportid,
                                stake: 0,
                                type: marketData?.Name,
                                userId: localStorage.getItem('userId') || '',
                                runner: marketData?.runners,
                                minValue: minValue,
                                maxValue: maxValue,
                                isfancy: false,
                                oddsPnlObj,
                              })
                          }
                        : () => {}
                    }
                    item
                    // display={{ xs: index !== 0 ? 'none' : '', md: 'block' }}
                    sx={{ cursor: index === 0 ? 'pointer' : 'not-allowed' }}
                    xs={2}
                    // xs={6}
                    {...itemLay}
                    {...pinkMain[index]}
                  >
                    <Typography {...itemLayOddTypography}>
                      {layData?.price}
                    </Typography>
                    <Typography {...itemBackNumberTypography}>
                      {layData?.size}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}

          {marketData?.display_message && (
            <MatchOddsMarquee className="displayMatch">
              {marketData?.display_message}
            </MatchOddsMarquee>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default MatchOdds
