import {
  Alert,
  CircularProgress,
  InputAdornment,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import { BsFillPersonFill } from 'react-icons/bs'
import { FaHandPointDown, FaAndroid, FaKey } from 'react-icons/fa'
import { MdOutlineLogout } from 'react-icons/md'
import './loginstyle.css'
import { Link, useNavigate } from 'react-router-dom'
import { StyledButton } from '../common/Button'
// import { useLoginMutation } from '../../services/auth'
import { useLoginMutation } from '../../app/apis/mainApi/mainApiSlice'
import React, { FC, useEffect, useState } from 'react'
import { useIpQuery } from '../../services/global'
import snackBarUtils from '../../utils/snackbar'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { login } from '../../app/features/auth/authSlilce'
import { hostName } from '../../utils/logochangewithHostname'

interface props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const LoginComponent: FC<props> = ({ setOpen }) => {
  const dispatch = useAppDispatch()

  const getmessage = (message: string, type: string) => {
    snackBarUtils.success(
      <Box sx={{ textAlign: 'left' }}>
        <Typography sx={{ fontSize: '16px' }}>{type}</Typography>
        <Typography sx={{ fontSize: '12px' }}> {message}</Typography>
      </Box>
    )
  }

  const [trigger, { isError, isLoading, isSuccess, data: loginData }] =
    useLoginMutation()

  const [error, SetIsError] = useState('')
  const navigate = useNavigate()
  const { data } = useIpQuery()
  const [loginCredentials, setLoginCredentials] = useState({
    login: '',
    password: '',
  })

  const handleChange = (e: any) => {
    if (e.target.name === 'login') {
      setLoginCredentials({ ...loginCredentials, login: e.target.value })
      if (!loginCredentials.login) {
        SetIsError('')
      }
    }

    if (!loginCredentials.login || loginCredentials.login === '') {
      SetIsError('user required')
    }

    if (!loginCredentials.password || loginCredentials.password === '') {
      SetIsError('password required')
    }

    if (e.target.name === 'password') {
      setLoginCredentials({ ...loginCredentials, password: e.target.value })
      if (!loginCredentials.password) {
        SetIsError('')
      }
    }
  }

  useEffect(() => {
    if (loginData) {
      if (loginData.responseCode === 202 || loginData.responseCode === 201) {
        // snackBarUtils.error(loginData?.type)
        snackBarUtils.error(
          <Box sx={{ textAlign: 'left' }}>
            <Typography sx={{ fontSize: '16px' }}>{loginData?.type}</Typography>
            <Typography sx={{ fontSize: '12px' }}>
              {' '}
              {loginData?.message}
            </Typography>
          </Box>
        )
        SetIsError(error)
        return
      } else if (loginData.responseCode === 200) {
        dispatch(login(loginData))
        // localStorage.setItem('data', JSON.parse(loginData || null))
        SetIsError('')
        getmessage(loginData?.message, loginData?.type)
        navigate('/')
        setOpen(true)
      }
    }
  }, [loginData])

  useEffect(() => {
    if (data) {
    }
  }, [data])

  return (
    <div className="loginparent">
      <Container
        maxWidth="xl"
        className="container"
        sx={{ zIndex: 100, position: 'relative' }}
      >
        <Typography
          component="h2"
          fontSize={30}
          sx={{ color: '#fff', textTransform: 'uppercase' }}
        >
          {hostName(window.location.hostname)}
        </Typography>

        <Box
          className="logincard"
          component={'form'}
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="Form">
            <Typography
              className="loginh3"
              component="h2"
              fontWeight={500}
              fontSize={24}
              mb={1}
            >
              LOGIN
              <FaHandPointDown />
            </Typography>

            <TextField
              className="username"
              sx={{
                boxShadow: 'none !important',
                padding: 0,
                backgroundColor: '#e7f0fe',
                borderRadius: `0!important`,
              }}
              onChange={handleChange}
              name="login"
              fullWidth
              size="small"
              variant="outlined"
              required
              placeholder="User Name"
              type="Text"
              value={loginCredentials.login}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ right: 0 }}>
                    <BsFillPersonFill size={20} color="#000" />
                  </InputAdornment>
                ),
                inputProps: {
                  outline: 'none',
                },
              }}
            />

            <TextField
              onChange={handleChange}
              size="small"
              fullWidth
              required
              value={loginCredentials.password}
              name="password"
              placeholder="Password"
              type="password"
              sx={{
                marginTop: '20px',
                backgroundColor: '#e7f0fe',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FaKey size={18} color="#000" />
                  </InputAdornment>
                ),
                inputProps: {
                  outline: 'none',
                },
              }}
            />

            {loginData?.responseCode == 202 ? (
              <Typography sx={{ fontSize: '14px', color: '#f00' }}>
                {error}
              </Typography>
            ) : (
              <></>
            )}

            <StyledButton
              type="submit"
              variant="contained"
              disableRipple={true}
              onClick={() => {
                trigger({
                  login: loginCredentials.login,
                  password: loginCredentials.password,
                  userIp: data?.ip,
                  userType: 3,
                  type: 'mobile',
                })
              }}
            >
              {isLoading ? (
                <CircularProgress
                  sx={{ marginRight: 1, position: 'absolute', left: 80 }}
                  color="secondary"
                  size={20}
                  thickness={1.6}
                />
              ) : (
                <></>
              )}
              <Typography sx={{ fontSize: '14px' }}>Login</Typography>
              <MdOutlineLogout />
            </StyledButton>
            <StyledButton
              sx={{
                '&:hover': {
                  backgroundColor: 'none',
                },
                fontSize: 14,
              }}
              disableRipple={true}
              variant="contained"
              className="downloadapk"
            >
              <FaAndroid style={{ marginRight: '10px' }} /> Download Apk
            </StyledButton>
            <Typography
              component="p"
              fontSize={9.6}
              sx={{ textAlign: 'left', mt: '10px' }}
            >
              This site is protected by reCAPTCHA and the Google
              <Link to={'#'} className="link">
                Privacy Policy
              </Link>
              and
              <Link to={'#'} className="link">
                Terms of Service
              </Link>
              apply.
            </Typography>
          </div>
        </Box>
      </Container>
    </div>
  )
}
