import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLazyAccountStatementApiQuery } from '../../app/apis/mainApi/mainApiSlice'
// import { useLazyAccountStatementApiQuery } from '../../services/accountStatement'
import { postNegValue } from '../../utils/postNegVal'
import {
  CustomButton,
  CustomTextFieldLeft,
  CustomTypography,
  CustomTypoParent,
} from './accountstatement.styled'
import AccountBetListModal from './BetListByMarket'

const AccountStatement = () => {
  const [open, setOpen] = React.useState(false)
  const [marketId, setMarketId] = useState('')
  const betlistModalOpen = (id: any) => {
    setMarketId(id)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const droplistitems = ['all', 'match', 'Opening Balance', 'balance']
  const [startDate, setstartDate] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [defaultval, setDefaultVal] = useState(droplistitems[0])

  const handlerSelectChange = (e: any) => {
    setDefaultVal(e.target.value)
  }

  const handlerStartDate = (e: any) => {
    setstartDate(e.target.value)
  }

  const handlerEndDate = (e: any) => {
    setEndDate(e.target.value)
  }

  useEffect(() => {
    trigger({
      userId: localStorage.getItem('userId') || '',
      startDate,
      endDate,
      type: defaultval,
    })
  }, [])

  const [trigger, { data: accountStatementData, isLoading, isFetching }] =
    useLazyAccountStatementApiQuery()

  return (
    <Box sx={{ padding: '5px' }}>
      {marketId && (
        <AccountBetListModal
          open={open}
          handleClose={handleClose}
          marketId={marketId}
        />
      )}
      <CustomTypoParent>
        <CustomTypography>account statement</CustomTypography>
      </CustomTypoParent>

      <Grid container sx={{ margin: '10px 0px 10px' }} gap={{ md: 2.8, xs: 1 }}>
        <Grid item xs={5.8} md={5.9}>
          <CustomTextFieldLeft
            value={startDate}
            onChange={handlerStartDate}
            type="date"
            fullWidth
            size="small"
            InputLabelProps={{ sx: { color: '#000 !important' } }}
          />
        </Grid>
        <Grid item xs={5.8} md={5.9}>
          <CustomTextFieldLeft
            value={endDate}
            onChange={handlerEndDate}
            type="date"
            fullWidth
            size="small"
            InputLabelProps={{ sx: { color: '#000 !important' } }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Select
          value={defaultval}
          onChange={handlerSelectChange}
          size="medium"
          sx={{
            width: '100%',
            height: '40px',
            textAlign: 'left',
            borderRadius: 0,
            textTransform: 'capitalize',
          }}
        >
          {droplistitems?.map((item) => (
            <MenuItem
              value={item}
              sx={{ color: '#000', textTransform: 'capitalize' }}
              key={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <CustomButton
        onClick={() =>
          trigger({
            startDate: startDate,
            endDate: endDate,
            userId: localStorage.getItem('userId') || '',
            type: defaultval,
          })
        }
      >
        submit
      </CustomButton>

      <Paper
        sx={{
          width: '100%',
          overflow: 'hidden',
          marginTop: '10px',
          borderRadius: 0,
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ borderRadius: 0 }}>
                <TableCell
                  colSpan={4}
                  sx={{
                    width: '200px',
                    borderRight: '1px solid #ccc',
                    backgroundColor: '#2c3e50',
                    color: '#fff',
                    padding: '5px 7px',
                    whiteSpace: 'nowrap',
                    textAlign: 'left',
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      fontSize: '12px',
                    }}
                  >
                    date
                  </Typography>
                </TableCell>
                <TableCell
                  colSpan={0}
                  sx={{
                    width: '200px',
                    borderRight: '1px solid #ccc',
                    backgroundColor: '#2c3e50',
                    color: '#fff',
                    padding: '5px 7px',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      fontSize: '12px',
                    }}
                  >
                    sr no
                  </Typography>
                </TableCell>

                <TableCell
                  colSpan={1}
                  sx={{
                    width: '200px',
                    borderRight: '1px solid #ccc',
                    backgroundColor: '#2c3e50',
                    color: '#fff',
                    padding: '5px 7px',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      fontSize: '12px',
                    }}
                  >
                    credit
                  </Typography>
                </TableCell>
                <TableCell
                  colSpan={1}
                  sx={{
                    width: '200px',
                    borderRight: '1px solid #ccc',
                    backgroundColor: '#2c3e50',
                    color: '#fff',
                    padding: '5px 7px',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      fontSize: '12px',
                    }}
                  >
                    debit
                  </Typography>
                </TableCell>
                <TableCell
                  colSpan={1}
                  sx={{
                    width: '200px',
                    borderRight: '1px solid #ccc',
                    backgroundColor: '#2c3e50',
                    color: '#fff',
                    padding: '5px 7px',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      fontSize: '12px',
                    }}
                  >
                    balance
                  </Typography>
                </TableCell>
                <TableCell
                  colSpan={6}
                  sx={{
                    width: '200px',
                    borderRight: '1px solid #ccc',
                    backgroundColor: '#2c3e50',
                    color: '#fff',
                    padding: '5px 7px',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      fontSize: '12px',
                    }}
                  >
                    remark
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!(isLoading || isFetching) &&
                accountStatementData?.data?.map((statementData, index) => (
                  <TableRow
                    onClick={() => betlistModalOpen(statementData?.marketid)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index + 1}
                  >
                    <TableCell
                      colSpan={4}
                      sx={{
                        borderRight: '1px solid #ccc',
                        textAlign: 'center',
                        color: '#000',
                        padding: '5px 7px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{ textTransform: 'capitalize', fontSize: '12px' }}
                      >
                        {statementData?.createdon}
                      </Typography>
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      sx={{
                        borderRight: '1px solid #ccc',
                        textAlign: 'center',
                        color: '#000',
                        padding: '5px 7px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{ textTransform: 'capitalize', fontSize: '12px' }}
                      >
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      sx={{
                        borderRight: '1px solid #ccc',
                        textAlign: 'right',
                        color: '#000',
                        padding: '5px 7px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{ textTransform: 'capitalize', fontSize: '12px' }}
                      >
                        {postNegValue(statementData?.credit)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      sx={{
                        borderRight: '1px solid #ccc',
                        textAlign: 'right',
                        color: '#000',
                        padding: '5px 7px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{ textTransform: 'capitalize', fontSize: '12px' }}
                      >
                        {postNegValue(statementData?.debit)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      sx={{
                        borderRight: '1px solid #ccc',
                        textAlign: 'right',
                        color: '#000',
                        padding: '5px 7px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{ textTransform: 'capitalize', fontSize: '12px' }}
                      >
                        {postNegValue(statementData?.closing)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      colSpan={6}
                      sx={{
                        borderRight: '1px solid #ccc',
                        textAlign: 'center',
                        color: '#000',
                        padding: '5px 7px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{ textTransform: 'capitalize', fontSize: '12px' }}
                      >
                        {statementData?.descreption}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )).reverse()}

              {!(
                accountStatementData?.data &&
                accountStatementData?.data?.length > 0
              ) && (
                <TableRow sx={{ backgroundColor: '#fff' }}>
                  <TableCell
                    sx={{ textAlign: 'center', padding: '10px' }}
                    colSpan={12}
                  >
                    <Typography
                      sx={{
                        backgroundColor: '#e4e4e4',
                        fontSize: '12px',
                        margin: '4px 0 0 ',
                      }}
                    >
                      No real-time records found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {(isLoading || isFetching) && (
            <CircularProgress
              sx={{ display: 'block', margin: '10px auto' }}
              color="primary"
              size={26}
              thickness={1.6}
            />
          )}
        </TableContainer>
      </Paper>
    </Box>
  )
}

export default AccountStatement
