import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions'
import Slide from '@mui/material/Slide'
import { ImCross } from 'react-icons/im';
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useUnsetteledbetResQuery } from '../../../app/apis/mainApi/mainApiSlice';
// import { useUnsetteledbetResQuery } from '../../../services/unsetteledbet';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        margin: '0px auto',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 500,
            width: '100%',
        },
    },
    '& .MuiDialog-container': {
        alignItems: 'flex-start',
        marginTop: 0,
        maxWidth: 564,
        margin: 'auto',
        padding: 0,
    },
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />
})

interface Props {
    handleClose: () => void
    expOpen: boolean
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const ExposureModal: React.FC<Props> = ({ expOpen, handleClose }) => {
    const tableRow = ['no', 'nation', 'event name', 'market name', 'amount']

    const { data: unsetteledExpoData } = useUnsetteledbetResQuery({ isActive: true, isDeleted: false, })


    return (
        <div>
            <BootstrapDialog
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={expOpen}
                TransitionComponent={Transition}
            >
                <DialogTitle
                    sx={{
                        maxWidth: '500px',
                        width: '100%',
                        backgroundColor: 'dark.main',
                        padding: 1.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: 'left',
                            textTransform: 'capitalize',
                            fontFamily: 'roboto condensed',
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#fff',
                        }}
                    >
                        Exposure List

                    </Typography>

                    <ImCross
                        size="16px"
                        color="#fff"
                        onClick={handleClose}
                        style={{ cursor: 'pointer' }}
                    />
                </DialogTitle>
                <DialogContent dividers sx={{ padding: '0' }}>
                    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '10px', borderRadius: 0 }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table" >
                                <TableHead>
                                    <TableRow sx={{ borderRadius: 0 }}>
                                        {tableRow.map((headData, index) => (
                                            <TableCell key={index} sx={{ borderRight: '1px solid #ccc', backgroundColor: '#fff', color: '#000', padding: '12px', textAlign: 'center' }}>
                                                <Typography component='span' sx={{ textTransform: 'capitalize', fontWeight: 600, fontSize: '12px', }}>
                                                    {headData}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {unsetteledExpoData?.data?.map((unsetteledBet, index) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={unsetteledBet?.id}>
                                            <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', }}>
                                                <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                    {index + 1}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', }}>
                                                <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                    {unsetteledBet?.selectionname}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', }}>
                                                <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                    {unsetteledBet?.matchname}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', }}>
                                                <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                    {unsetteledBet?.type}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderRight: '1px solid #ccc', textAlign: 'center', color: '#000', padding: '8px 10px', }}>
                                                <Typography component='span' sx={{ textTransform: 'capitalize', fontSize: '12px' }}>
                                                    {unsetteledBet?.stake}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    {!(unsetteledExpoData?.data && unsetteledExpoData?.data?.length > 0) && <TableRow sx={{ padding: '10px', }}>
                                        <TableCell sx={{ backgroundColor: '#e4e4e4', textAlign: 'center', fontSize: '12px', padding: '6px', margin: '4px 0 0 ' }} colSpan={9}>
                                            No real-time records found
                                        </TableCell>
                                    </TableRow>}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </DialogContent>

            </BootstrapDialog>
        </div >
    );
}

export default ExposureModal