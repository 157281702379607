export const titleById: any = {
  "51": "20-20 Teenpatti",
  "52": "20-20 DRAGON TIGER",
  "53": "LUCKY 7 - A",
  "54": "AMAR AKBAR ANTHONY",
  "55": "BOLLYWOOD TABLE",
  "57": "1 DAY TEENPATTI",
  "60": "ANDAR BAHAR",
  "61": "20-20 D T L",
};

export const vCasinotitleById: any = {
  "71": "20-20 Teenpatti",
  "72": "ANDAR BAHAR",
  "73": "20-20 DRAGON TIGER",
  "74": "AMAR AKBAR ANTHONY",
  "75": "BOLLYWOOD TABLE",
  "76": "20-20 D T L",
  "77": "LUCKY 7 - A",
};

export const CardTypeByAbrv = {
  DD: "eat",
  HH: "pan",
  CC: "cdee",
  SS: "hukum",
};

export const nameById = {
  "51": "tp20DataBig",
  "52": "dt20DataBig",
  "53": "lucky7euDataBig",
  "54": "aaaDataBig",
  "55": "btDataBig",
  "56": "opentpDataBig",
  "57": "1daytpDataBig",
  "58": "1daypDataBig",
  "59": "2020pDataBig",
  "60": "ab20DataBig",
  "61": "1daydtDataBig",
};
export const videoIdById = {
  "51": "3030",
  "52": "3035",
  "53": "3032",
  "54": "3056",
  "55": "3041",
  "56": "3049",
  "57": "3031",
  "58": "3051",
  "59": "3052",
  "60": "3053",
  "61": "3057",
};

export const tableIdtoUrl: any = {
  "51": "teen20",
  "52": "dt20",
  "53": "lucky7",
  "54": "aaa",
  "55": "btable",
  "56": "teen8",
  "57": "Teen",
  "58": "poker",
  "59": "poker20",
  "60": "ab20",
  "61": "dtl20",
  "71": 't20',
  "72": "ab20",
  "73": 'dt20',
  "74": "aaa",
  "75": "bwdtbl",
  "76": 'dtl20',
  "77": 'lucky7A',

};
export const idFromGtype = {
  "51": "teen20",
  "53": "lucky7eu",
  "54": "aaa",
  "55": "btable",
};

const bgGreenForAll = '#355e3b';
const redColorForLabel = "#ff4500";
const yellowColorForLabel = "#08c";
const clrblue = '#33c6ff';
const clrred = '#ff4500';
const clryellow = '#ffff33';
const blueColorForLabel = "black";
const whiteColorForLabel = "#fff";


export const LetterAndColorById: any = {
  "51": {
    "1": {
      color: bgGreenForAll,
      label: "A",
      clr: clrred
    },
    "2": {
      color: bgGreenForAll,
      label: "A",
      clr: clrred
    },
    "3": {
      color: bgGreenForAll,
      label: "B",
      clr: clryellow
    },
    "4": {
      color: bgGreenForAll,
      label: "B",
      clr: clryellow

    },
  },
  "52": {
    "1": {
      color: bgGreenForAll,
      label: "D",
      clr: clrred
    },
    "2": {
      color: bgGreenForAll,
      label: "T",
      clr: clryellow
    },
    "3": {
      color: bgGreenForAll,
      label: "Tie",
      clr: clrblue
    },
  },
  "53": {
    "1": {
      color: bgGreenForAll,
      label: "L",
      clr: clrred
    },
    "2": {
      color: bgGreenForAll,
      label: "H",
      clr: clryellow

    },
    "0": {
      color: bgGreenForAll,
      label: "T",
      clr: clrblue

    },
  },
  "54": {
    "1": {
      color: bgGreenForAll,
      label: "A",
      clr: clrred,
    },
    "2": {
      color: bgGreenForAll,
      label: "B",
      clr: clryellow
    },
    "3": {
      color: bgGreenForAll,
      label: "C",
      clr: clrblue
    },
  },
  "55": {
    "1": {
      color: bgGreenForAll,
      label: "A",
      clr: clryellow
    },
    "2": {
      color: bgGreenForAll,
      label: "B",
      clr: clryellow

    },
    "3": {
      color: bgGreenForAll,
      label: "C",
      clr: clryellow

    },
    "4": {
      color: bgGreenForAll,
      label: "D",
      clr: clryellow

    },
    "5": {
      color: bgGreenForAll,
      label: "E",
      clr: clryellow

    },
    "6": {
      color: bgGreenForAll,
      label: "F",
      clr: clryellow

    },
  },
  "56": {
    "0": {
      color: yellowColorForLabel,
      label: "R",
    },
  },
  "57": {
    "1": {
      color: redColorForLabel,
      label: "A",
    },
    "2": {
      color: yellowColorForLabel,
      label: "B",
    },
  },
  "58": {
    "11": {
      color: redColorForLabel,
      label: "A",
    },
    "21": {
      color: yellowColorForLabel,
      label: "B",
    },
    "0": {
      color: whiteColorForLabel,
      label: "T",
    },
  },
  "59": {
    "11": {
      color: redColorForLabel,
      label: "A",
    },
    "21": {
      color: yellowColorForLabel,
      label: "B",
    },
    "0": {
      color: whiteColorForLabel,
      label: "T",
    },
  },
  "60": {
    "0": {
      color: bgGreenForAll,
      label: "R",
      clr: clryellow
    },
  },
  "61": {
    "1": {
      color: redColorForLabel,
      label: "D",
    },
    "21": {
      color: yellowColorForLabel,
      label: "T",
    },
    "41": {
      color: blueColorForLabel,
      label: "L",
    },
  },
};