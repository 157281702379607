import { useMemo } from 'react'
import ThreeButtonContainer from './ThreeButtonContainer'
import TwoButtonContainer from '../TwoButtonContainer/TwoButtonContainer'
import BCardContainer from '../bollywoodTable/BCardContainer'
import { useParams } from 'react-router'

const Luck7B = ({
  odds,
  setBetState,
  setShowBetSection,
  setOpen,
  setUpdated,
}: any) => {
  const { id } = useParams()
  console.log(odds?.data?.t2, 'allalaalal')
  // odds?.t2 ||
  const t2 = id == '77' ? odds?.data?.t2 : odds?.t2 || []
  console.log(t2)
  const t2BySid = useMemo(() => {
    return t2?.reduce((accu: any, curr: any) => {
      accu[curr?.sid] = curr
      return accu
    })
  }, [odds])

  return (
    <>
      <ThreeButtonContainer
        setUpdated={setUpdated}
        setOpen={setOpen}
        setBetState={setBetState}
        setShowBetSection={setShowBetSection}
        noToolTip
        t2={[t2BySid, t2BySid['2']]}
      />
      <div className="fancy_aaa_container m-1 mt-2">
        <TwoButtonContainer
          toolTipshow={false}
          setUpdated={setUpdated}
          setOpen={setOpen}
          className={'d-flex'}
          setBetState={setBetState}
          setShowBetSection={setShowBetSection}
          noToolTip
          t2={[t2BySid['3'], t2BySid['4']]}
        />
        <TwoButtonContainer
          toolTipshow={false}
          setUpdated={setUpdated}
          setOpen={setOpen}
          className={'d-flex'}
          setBetState={setBetState}
          setShowBetSection={setShowBetSection}
          noToolTip
          t2={[t2BySid['5'], t2BySid['6']]}
        />
      </div>
      <div className="mt-2">
        {/* <BCardContainer  setUpdated={setUpdated}
      setOpen={setOpen}
      setBetState={setBetState} setShowBetSection={setShowBetSection}
        noToolTip={false}
        t2={t2?.filter((item) => item?.nation?.toLowerCase()?.includes("card "))}
      /> */}
      </div>
    </>
  )
}

export default Luck7B
