import styled from '@mui/material/styles/styled'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
// import TabPanel from '@mui/base/TabPanel'

export const Tabs = styled(MuiTabs)({
  minHeight: '35px',
  '& .MuiTabs-scroller': {
    overflowX: 'scroll !important'
  },
  '& .MuiButtonBase-root::after': {
    height: '100%',
    width: '1px',
    content: `""`,
    backgroundColor: '#0088cc',
    position: 'absolute',
    right: 0,
  },
  '& .MuiButtonBase-root:last-child:after': {
    width: 0,
  },
  '& .MuiButtonBase-root': {
    zIndex: 4,
    opacity: 1,
    paddingTop: 0,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
  },

  '& .MuiTabs-indicator': {
    backgroundColor: 'none',
    height: '0px',
    top: 0,
  },

  backgroundColor: '#2c3e50',
})

export const Tab = styled(MuiTab)({
  color: '#fff',
  '&:hover': {
    // backgroundColor: `#0088cc !important`,
    // backgroundColor: `#0088ccA5 !important`,
  },
})
