import { Box } from '@mui/material'
import React from 'react'
import { FaLock } from 'react-icons/fa'

const Suspendedlock = () => {
    return (

        <Box
            position="absolute"
            sx={{
                width: '100%',
                cursor: 'not-allowed !important',
                height: '100%',
                top: 0,
                left: 0,
                backgroundColor: '#00000099',
                color: 'status.danger',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '10!important'
            }
            }
        >
            <FaLock color='#fff' size={16} />
        </Box >



    )
}

export default Suspendedlock