import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'

import { IoClose } from 'react-icons/io5'
import { useSearchParams } from 'react-router-dom'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    overflow: 'scroll',
    maxWidth: 500,
    margin: '0 auto',
    '& .MuiDialog-container': {
        '& .MuiPaper': {
            // margin: '0 !important'
        },
        '&. MuiPaper-root': {
            // margin: '0 !important'
        },
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

export interface DialogTitleProps {
    id: string
    children?: React.ReactNode
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, ...other } = props

    return (
        <DialogTitle
            component="h4"
            variant="h4"
            sx={{
                m: 0,
                p: 2,
                backgroundColor: 'dark.main',
                fontFamily: 'Roboto Condensed',
                fontSize: '18px',
                fontWeight: 600,
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
            {...other}
        >
            {children}
        </DialogTitle>
    )
}

interface props {
    virtualModal: boolean
    setVirtualModal: React.Dispatch<React.SetStateAction<boolean>>
    children?: React.ReactNode
}

const VirtualCasinoModal: React.FC<props> = ({ virtualModal, setVirtualModal, children }) => {

    const handleClose = () => {
        setVirtualModal(false)
    }
    const [searchParams, setSearchParams] = useSearchParams()
    const imgid = searchParams.get('id')


    return (
        <div>
            <BootstrapDialog
                fullScreen
                onClick={handleClose}
                sx={{ maxHeight: '692px', width: '100%', p: 1 }}
                fullWidth
                aria-labelledby="customized-dialog-title"
                open={virtualModal}
            >
                <BootstrapDialogTitle id="customized-dialog-title" >
                    Casino Rules
                    <IoClose onClick={handleClose} style={{ cursor: 'pointer' }} size={26} />
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography component="span">
                        <img src={`/img/${imgid}-rules.jpeg`} alt={`${imgid}`} style={{ width: '100%' }} />
                    </Typography>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

export default VirtualCasinoModal
