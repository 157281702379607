import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
// import { TopTab } from './GameDetail.styled'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
// import { TopTabs } from './GameDetail.styled'
import { Outlet, useOutletContext, useParams } from 'react-router'
// import GameDetail from './GameDetail'
import { Button, Grid, Paper } from '@mui/material'
// import MatchedBet from './MatchedBet'
// import { AiOutlineDesktop } from 'react-icons/ai'
import {
  TopTab,
  TopTabs,
} from '../../component/common/GameDetail/GameDetail.styled'
// import MatchedBet from '../../component/common/GameDetail/MatchedBet'
import VirtualCasinoHeader from '../../component/common/VirtualAndLiveCasino/VirtualCasinoHeader'
// import VirtualMatchedBet from './VirtualMatchedBet'
import {
  CardSize,
  DragonTigerButton,
  ParentWrapper,
  Virtuacasinoltable,
} from './virtualAAA.styled'
import { FiArrowRight } from 'react-icons/fi'
import moment from 'moment'
import VirtualCasinoModal from '../../component/common/VirtualCasinoModal/VirtualCasinoModal'
import { BsFillPlayFill } from 'react-icons/bs'
import VirtualMatchedBet from '../VteenPatti20/VirtualMatchedBet'
import Suspendedlock from '../../component/common/Suspendedlock'
import { useSearchParams } from 'react-router-dom'
import { database } from '../../firebase'
import {
  T2,
  virtualcasinoResult,
  virutalcasinoData,
} from '../../services/types'
import { CustomTypography } from '../VteenPattiLuckySeven/virtualteenpattiluckyseven.styled'
import CardComp from '../../component/common/CardComp'
import Lastresult from '../../component/common/Lastresult'
import { useVirtualcasinoliabilityQuery } from '../../app/apis/mainApi/mainApiSlice'
import { useGetBetListByMatchResQuery } from '../../app/apis/mdkalyanExchApi/mdkalyanExchApiSlice'
// import { CardSize, Virtuacasinoltable } from '../../component/common/VirtualAndLiveCasino/virtualcard.styled'
// import { Virtualcasinobetgrid, VirtualcasinoButton, Virtualcasinotablegame, Virtualcasinotableheader } from '../../component/common/VirtualAndLiveCasino/VirtualCasinoGameTable'
// import { useGetBetListByMatchResQuery } from '../../../app/apis/mdkalyanExchApi/mdkalyanExchApiSlice'
interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
  className: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Box sx={{ p: 0 }}>{children}</Box>
        </Box>
      )}
    </div>
  )
}

const VteenPattiAAA = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const eventId = searchParams.get('id')
  const theme = useTheme()
  const [value, setValue] = React.useState(0)
  const [virtualModal, setVirtualModal] = useState(false)
  const [odds, setOdds] = useState<virutalcasinoData>()
  const [result, setResult] = useState<virtualcasinoResult>()
  const { data: getBetlistmatch } = useGetBetListByMatchResQuery({ eventId: Number(eventId) });

  useEffect(() => {
    const betex = database.ref('betex/aaa')
    betex.on('value', (snapshot) => {
      const odds = snapshot.val()?.data.data

      odds.t2 =
        odds.t2?.reduce((accu: { [x: string]: T2 }, curr: T2) => {
          accu[curr.sid] = curr
          return accu
        }, {}) || {}
      setOdds(odds)
      setResult(snapshot.val()?.result)
    })
    return () => {
      betex.off()
    }
  }, [])

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue)
  }
  const virtual2020teenpatti = () => {
    setVirtualModal(true)
  }

  const { data: amarakbarpnldata } = useVirtualcasinoliabilityQuery({
    eventid: eventId || '',
    roundid: odds?.t1?.[0]?.mid || '',
  })



  const { handleClickBetModalOpen
  } = useOutletContext<any>()

  return (
    <Box sx={{ width: { md: 'xl' } }}>
      <VirtualCasinoModal
        virtualModal={virtualModal}
        setVirtualModal={setVirtualModal}
      />
      <Paper
        elevation={10}
        sx={{
          bgcolor: 'tabsbgclr.main',
          height: 35,
          borderRadius: 0,
        }}
      >
        <TopTabs
          sx={{
            '& .MuiButtonBase-root': {
              width: '100%',
              maxWidth: '50%',
            },
          }}
          aria-activedescendant="active"
          value={value}
          onChange={handleChange}
          variant="standard"
          aria-label="full width tabs example"
          indicatorColor="secondary"
        >
          <TopTab
            disableRipple
            label={`game`.toUpperCase()}
            onClick={() => setValue(0)}
          />
          <TopTab
            disableRipple
            label={`Matched bet (${getBetlistmatch?.data && getBetlistmatch?.data?.length > 0 ? getBetlistmatch?.data?.length : 0})`.toUpperCase()}
            onClick={() => setValue(1)}
          />
        </TopTabs>
      </Paper>

      <TabPanel
        value={value}
        index={0}
        dir={theme.direction}
        className="tabpanell"
      >
        <ParentWrapper>
          <VirtualCasinoHeader className="some">
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="header-title header-title-left"
                >
                  AMAR AKBAR ANTHONY
                </Typography>
                <Typography component="p" className=" header-title-left">
                  <Typography
                    component="span"
                    className="pointer title"
                    onClick={virtual2020teenpatti}
                  >
                    <FiArrowRight /> Rules
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="p" className="title header-title-right">
                  Round ID: {odds?.t1?.[0]?.mid}
                </Typography>
                <Typography component="p" className="title header-title-right">
                  Time: {moment().format('hh:mm:ss A')}
                </Typography>
              </Grid>
            </Grid>
          </VirtualCasinoHeader>

          <Virtuacasinoltable sx={{ padding: '0' }}>
            <Box className="teenpattiheadertext">
              <Typography className="heading">AMAR AKBAR ANTHONY</Typography>
            </Box>

            <Grid container>
              <Grid item xs={4} md={5}></Grid>
              <Grid item xs={4} md={2} className="flex" sx={{ mt: 2 }}>
                <Grid container>
                  <Grid item xs={3} md={4}>
                    {/* <CardSize>
                                            <img src='http://admin.kalyanexch.com/images/cards/backcover.png' alt="" />
                                        </CardSize> */}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <CardSize>
                      {/* <img
                        src="http://admin.kalyanexch.com/images/cards/backcover.png"
                        alt=""
                      /> */}
                      {/* {odds?.t2['5']?.min} */}
                      <CardComp
                        shown={!!odds?.t1?.[0]?.C1}
                        card={odds?.t1?.[0]?.C1 || ''}
                      />
                    </CardSize>
                  </Grid>
                  <Grid item xs={3} md={4}>
                    {/* <CardSize>
                                            <img src='http://admin.kalyanexch.com/images/cards/backcover.png' alt="" />
                                        </CardSize> */}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{ textAlign: 'center', mt: 2 }}
                  >
                    <Button className="playerbutton" disableRipple disabled>
                      <Typography
                        component="p"
                        sx={{ fontSize: '17px', textTransform: 'uppercase' }}
                      >
                        Card
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} md={5}></Grid>
            </Grid>

            <Box sx={{ padding: '0 5px' }}>
              <Grid
                className="grid-container-for-24-card"
                container
                sx={{ border: '1px solid #fff', mt: 2 }}
              >
                <Grid item xs={6} sx={{ backgroundColor: '#faa9ba' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {['A', '1', '2', '3', '4', '5', '6'].map((item) => (
                      <Typography
                        component="span"
                        sx={{
                          fontSize: '14px',
                          color: '#000',
                          margin: 'auto 5px',
                          fontWeight: 'bold',
                        }}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={3} sx={{ backgroundColor: '#faa9ba' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {['7', '8', '9', '10'].map((item) => (
                      <Typography
                        component="span"
                        sx={{
                          fontSize: '14px',
                          color: '#000',
                          margin: 'auto 5px',
                          fontWeight: 'bold',
                        }}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={3} sx={{ backgroundColor: '#faa9ba' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {['J', 'O', 'K'].map((item) => (
                      <Typography
                        component="span"
                        sx={{
                          fontSize: '14px',
                          color: '#000',
                          margin: 'auto 5px',
                          fontWeight: 'bold',
                        }}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    padding: '5px',
                    fontWeight: 'bold',
                    borderRight: '1px solid #fff',
                    fontSize: '15px',
                    color: '#fff',
                    backgroundColor: '#1a850B80',
                  }}
                >
                  Amar
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    padding: '5px',
                    fontWeight: 'bold',
                    borderRight: '1px solid #fff',
                    fontSize: '15px',
                    color: '#fff',
                    backgroundColor: '#1a850B80',
                  }}
                >
                  Akbar
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    padding: '5px',
                    fontWeight: 'bold',
                    borderRight: '1px solid #fff',
                    fontSize: '15px',
                    color: '#fff',
                    backgroundColor: '#1a850B80',
                  }}
                >
                  Anthony
                </Grid>
              </Grid>
            </Box>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box className="_timer">
                <Typography component="p">
                  {Number(odds?.t1?.[0]?.autotime) > 0
                    ? odds?.t1?.[0]?.autotime
                    : 'GO!!'}
                </Typography>
              </Box>
            </Grid>
          </Virtuacasinoltable>

          <Box sx={{ padding: '10px' }}>
            <Grid
              container
            // sx={{ borderBlock: '1px solid #999', borderLeft: '1px solid #999' }}
            >
              <Grid item xs={8} md={6} sx={{ padding: ' 5px' }}>
                <Typography
                  component="p"
                  sx={{ textAlign: 'left', fontSize: '12px' }}
                >
                  Min: {odds?.t1?.[0]?.min} | Max:{odds?.t1?.[0]?.max}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                md={2}
              // bgcolor={theme.palette.back.sky}
              // sx={{ padding: '2px', borderLeft: '1px solid #999' }}
              >
                {/* <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: 600, textTransform: 'uppercase' }}>
                                    Back
                                </Typography> */}
              </Grid>
              <Grid
                item
                xs={4}
                md={2}
              // bgcolor={theme.palette.back.sky}
              // sx={{ padding: '2px', borderLeft: '1px solid #999' }}
              ></Grid>
              {/* <Grid item md={2} /> */}
            </Grid>
            {odds?.t2 && (
              <Box borderBottom={'1px solid #999'}>
                {[odds?.t2['1'], odds?.t2['2'], odds?.t2['3']].map(
                  (item, index) => (
                    <Grid
                      container
                      key={index + 1}
                      sx={{ borderTop: '1px solid #999' }}
                    >
                      <Grid
                        item
                        xs={6}
                        md={6}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '5px',
                          borderLeft: '1px solid #999',
                        }}
                      >
                        <Box
                          sx={{
                            textTransform: 'capitalize',
                            marginRight: '16px',
                            textAlign: 'left',
                            fontSize: '12px',
                          }}
                        >
                          <Typography
                            component="span"
                            sx={{
                              color: '#f00',
                              fontSize: '12px',
                              fontWeight: 'bold',
                              marginRight: '3px',
                            }}
                          >
                            {['A', 'B', 'C'][index]}
                          </Typography>
                          <Typography
                            component="span"
                            sx={{
                              color: '#000',
                              fontSize: '12px',
                              fontWeight: 'bold',
                            }}
                          >
                            {item?.nat}
                          </Typography>
                          <Typography
                            component="p"
                            sx={{ color: '#000', fontSize: '12px' }}
                          >
                            {amarakbarpnldata?.dataObj?.[item.sid]?.total_pnl}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        md={3}
                        bgcolor={theme.palette.back.sky}
                        sx={{
                          borderLeft: '1px solid #999',
                          cursor: 'pointer',
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                        onClick={() => {
                          if (item.gstatus === '0') {
                            return <></>
                          }
                          handleClickBetModalOpen({
                            betType: 'back',
                            deviceInfo: {
                              browser: "Chrome",
                              browser_version: "114.0.0.0",
                              device: "Macintosh",
                              deviceType: "desktop",
                              orientation: "landscape",
                              os: "Mac",
                              os_version: "mac-os-x-15",
                              userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                              userIp: "180.151.224.106"
                            },
                            isBetfair: false,
                            isBack: true,
                            isLay: false,
                            isbetfair: false,
                            liability: 0,
                            marketId: item?.mid,
                            matchId: Number(eventId),
                            marketName: odds?.t1['0']?.gtype,
                            matchName: location,
                            odds: item?.rate,
                            placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                            priceValue: item?.rate,
                            selectionId: item?.sid,
                            selectionIds: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22",
                            selectionName: item?.nat,
                            stake: 0,
                            type: odds?.t1['0']?.gtype,
                            sportId: 502,
                          })
                        }}

                      >
                        {item.gstatus === '0' && <Suspendedlock />}
                        <Typography
                          sx={{ fontSize: '14px', fontWeight: '600' }}
                        >
                          {item?.rate}
                        </Typography>
                        {/* <Typography sx={{ fontSize: '12px' }}>0</Typography> */}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        md={3}
                        bgcolor={theme.palette.lay.pink}
                        sx={{
                          borderRight: '1px solid #999',
                          borderLeft: '1px solid #999',
                          cursor: 'pointer',
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                        onClick={() => {
                          if (item.gstatus === '0') {
                            return <></>
                          }
                          handleClickBetModalOpen({
                            betType: 'lay',
                            deviceInfo: {
                              browser: "Chrome",
                              browser_version: "114.0.0.0",
                              device: "Macintosh",
                              deviceType: "desktop",
                              orientation: "landscape",
                              os: "Mac",
                              os_version: "mac-os-x-15",
                              userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                              userIp: "180.151.224.106"
                            },
                            isBetfair: false,
                            isBack: false,
                            isLay: true,
                            isbetfair: false,
                            liability: 0,
                            marketId: item?.mid,
                            matchId: Number(eventId),
                            marketName: odds?.t1['0']?.gtype,
                            matchName: location,
                            odds: item?.layrate,
                            placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                            priceValue: item?.layrate,
                            selectionId: item?.sid,
                            selectionIds: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22",
                            selectionName: item?.nat,
                            stake: 0,
                            type: odds?.t1['0']?.gtype,
                            sportId: 502,
                          })
                        }}
                      >
                        {item.gstatus === '0' && <Suspendedlock />}
                        <Typography
                          sx={{ fontSize: '12px', textTransform: 'capitalize' }}
                        >
                          {item?.layrate}
                        </Typography>
                        {/* <Typography sx={{ fontSize: '12px' }}>0</Typography> */}
                      </Grid>
                      {/* <Grid item md={2} /> */}
                    </Grid>
                  )
                )}
              </Box>
            )}
          </Box>

          {/* even odd red black */}

          <Box className="dragontiger" sx={{ m: 'auto', padding: '10px' }}>
            <Grid container sx={{ justifyContent: 'space-between' }}>
              <Grid item xs={6} sx={{ paddingRight: '5px' }}>
                <Grid
                  container
                  sx={{
                    backgroundColor: '#f2f2f2',
                    padding: '10px 20px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item xs={12}
                    onClick={() => {
                      if (odds?.t2['4']?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: "Chrome",
                          browser_version: "114.0.0.0",
                          device: "Macintosh",
                          deviceType: "desktop",
                          orientation: "landscape",
                          os: "Mac",
                          os_version: "mac-os-x-15",
                          userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                          userIp: "180.151.224.106"
                        },
                        isBetfair: false,
                        isBack: true,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2['4']?.mid,
                        matchId: Number(eventId),
                        marketName: odds?.t1['0']?.gtype,
                        matchName: location,
                        odds: odds?.t2['4']?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2['4']?.rate,
                        selectionId: odds?.t2['4']?.sid,
                        selectionIds: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22",
                        selectionName: odds?.t2['4']?.nat,
                        stake: 0,
                        type: odds?.t1['0']?.gtype,
                        sportId: 502,
                      })
                    }}
                  >
                    <CustomTypography>{odds?.t2['4']?.rate}</CustomTypography>
                    <DragonTigerButton size="large" sx={{ minHeight: '35px' }}>
                      {odds?.t2['4']?.nat}
                      {odds?.t2['4']?.gstatus === '0' && <Suspendedlock />}
                    </DragonTigerButton>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {odds?.t2?.[4]?.sid ==
                        amarakbarpnldata?.data?.[3]?.selectionid
                        ? amarakbarpnldata?.data?.[3]?.total_pnl
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    backgroundColor: '#f2f2f2',
                    padding: '10px 20px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item xs={12}
                    onClick={() => {
                      if (odds?.t2['5']?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: "Chrome",
                          browser_version: "114.0.0.0",
                          device: "Macintosh",
                          deviceType: "desktop",
                          orientation: "landscape",
                          os: "Mac",
                          os_version: "mac-os-x-15",
                          userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                          userIp: "180.151.224.106"
                        },
                        isBetfair: false,
                        isBack: true,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2['5']?.mid,
                        matchId: Number(eventId),
                        marketName: odds?.t1['0']?.gtype,
                        matchName: location,
                        odds: odds?.t2['5']?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2['5']?.rate,
                        selectionId: odds?.t2['5']?.sid,
                        selectionIds: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22",
                        selectionName: odds?.t2['5']?.nat,
                        stake: 0,
                        type: odds?.t1['0']?.gtype,
                        sportId: 502,
                      })
                    }}>
                    <CustomTypography>{odds?.t2['5']?.rate}</CustomTypography>
                    <DragonTigerButton size="large" sx={{ minHeight: '35px' }}>
                      {odds?.t2['5']?.nat}
                      {odds?.t2['5']?.gstatus === '0' && <Suspendedlock />}
                    </DragonTigerButton>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {odds?.t2?.[5]?.sid ==
                        amarakbarpnldata?.data?.[3]?.selectionid
                        ? amarakbarpnldata?.data?.[3]?.total_pnl
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    backgroundColor: '#f2f2f2',
                    padding: '10px 20px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        textAlign: 'right',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      min:
                      <Typography component="span" sx={{ fontSize: '12px' }}>
                        {odds?.t2['4']?.min}
                      </Typography>
                    </Typography>

                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        textAlign: 'right',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      max:
                      <Typography component="span" sx={{ fontSize: '12px' }}>
                        {odds?.t2['4']?.max}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sx={{ paddingLeft: '5px' }}>
                <Grid
                  container
                  sx={{
                    backgroundColor: '#f2f2f2',
                    padding: '10px 20px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item xs={12}
                    onClick={() => {
                      if (odds?.t2['6']?.gstatus === '0') {
                        return <></>
                      }
                      handleClickBetModalOpen({
                        betType: 'back',
                        deviceInfo: {
                          browser: "Chrome",
                          browser_version: "114.0.0.0",
                          device: "Macintosh",
                          deviceType: "desktop",
                          orientation: "landscape",
                          os: "Mac",
                          os_version: "mac-os-x-15",
                          userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                          userIp: "180.151.224.106"
                        },
                        isBetfair: false,
                        isBack: true,
                        isLay: false,
                        isbetfair: false,
                        liability: 0,
                        marketId: odds?.t2['6']?.mid,
                        matchId: Number(eventId),
                        marketName: odds?.t1['0']?.gtype,
                        matchName: location,
                        odds: odds?.t2['6']?.rate,
                        placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                        priceValue: odds?.t2['6']?.rate,
                        selectionId: odds?.t2['6']?.sid,
                        selectionIds: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22",
                        selectionName: odds?.t2['6']?.nat,
                        stake: 0,
                        type: odds?.t1['0']?.gtype,
                        sportId: 502,
                      })
                    }}>
                    <CustomTypography>{odds?.t2['6']?.rate}</CustomTypography>
                    <DragonTigerButton size="large" sx={{ minHeight: '35px' }}>
                      <img
                        src={`http://admin.kalyanexch.com/images/cards/ccard-1.png`}
                        alt=""
                        style={{ width: '30px' }}
                      />
                      {odds?.t2['6']?.gstatus === '0' && <Suspendedlock />}
                    </DragonTigerButton>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {odds?.t2?.[6]?.sid ==
                        amarakbarpnldata?.data?.[3]?.selectionid
                        ? amarakbarpnldata?.data?.[3]?.total_pnl
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    backgroundColor: '#f2f2f2',
                    padding: '10px 20px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item xs={12} onClick={() => {
                    if (odds?.t2['7']?.gstatus === '0') {
                      return <></>
                    }
                    handleClickBetModalOpen({
                      betType: 'back',
                      deviceInfo: {
                        browser: "Chrome",
                        browser_version: "114.0.0.0",
                        device: "Macintosh",
                        deviceType: "desktop",
                        orientation: "landscape",
                        os: "Mac",
                        os_version: "mac-os-x-15",
                        userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
                        userIp: "180.151.224.106"
                      },
                      isBetfair: false,
                      isBack: true,
                      isLay: false,
                      isbetfair: false,
                      liability: 0,
                      marketId: odds?.t2['7']?.mid,
                      matchId: Number(eventId),
                      marketName: odds?.t1['0']?.gtype,
                      matchName: location,
                      odds: odds?.t2['7']?.rate,
                      placeTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                      priceValue: odds?.t2['7']?.rate,
                      selectionId: odds?.t2['7']?.sid,
                      selectionIds: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22",
                      selectionName: odds?.t2['7']?.nat,
                      stake: 0,
                      type: odds?.t1['0']?.gtype,
                      sportId: 502,
                    })
                  }}>
                    <CustomTypography>{odds?.t2['7']?.rate}</CustomTypography>
                    <DragonTigerButton size="large" sx={{ minHeight: '35px' }}>
                      <img
                        src={`http://admin.kalyanexch.com/images/cards/ccard-2.png`}
                        alt=""
                        style={{ width: '30px' }}
                      />
                      {odds?.t2['7']?.gstatus === '0' && <Suspendedlock />}
                    </DragonTigerButton>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {odds?.t2?.[7]?.sid ==
                        amarakbarpnldata?.data?.[3]?.selectionid
                        ? amarakbarpnldata?.data?.[3]?.total_pnl
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    backgroundColor: '#f2f2f2',
                    padding: '10px 20px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        textAlign: 'right',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      min:
                      <Typography component="span" sx={{ fontSize: '12px' }}>
                        {odds?.t2['6']?.min}
                      </Typography>
                    </Typography>

                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        textAlign: 'right',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      max:
                      <Typography component="span" sx={{ fontSize: '12px' }}>
                        {odds?.t2['6']?.max}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* even odd red black */}

          <Box className="_news">
            <VirtualCasinoHeader className="some m-5 p-0">
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    component="p"
                    className="header-title header-title-left"
                  >
                    News
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    component="p"
                    className="title header-title-right"
                  >
                    <Typography
                      component="span"
                      sx={{
                        textTransform: 'capitalize',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 'auto',
                        maxWidth: '100px',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      <span>play</span>
                      <span>
                        <BsFillPlayFill
                          size={22}
                          style={{ marginTop: '5px' }}
                        />
                      </span>
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </VirtualCasinoHeader>
          </Box>

          {result && <Lastresult teenTwentyResult={result} />}
        </ParentWrapper>
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        dir={theme.direction}
        className="tabpanell"
      >
        <VirtualMatchedBet />
      </TabPanel>
    </Box>
  )
}

export default VteenPattiAAA
