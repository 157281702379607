import React, { useEffect, useState } from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import moment from 'moment'
import { CustomButton, CustomTextFieldLeft, CustomTypography, CustomTypoParent } from './profitandloss.styled'
// import { useLazyProfitandlossapiQuery } from '../../services/profitandloss'
import { postNegValue } from '../../utils/postNegVal'
import { useLazyProfitandlossapiQuery } from '../../app/apis/mainApi/mainApiSlice'

const ProfitAndLoss = () => {
    const [startDate, setstartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

    const handlerStartDate = (e: any) => {
        setstartDate(e.target.value)
    }

    const handlerEndDate = (e: any) => {
        setEndDate(e.target.value)
    }

    const [trigger, { data: pnldata }] = useLazyProfitandlossapiQuery()


    useEffect(() => {
        trigger({
            endDate,
            startDate
        })
    }, [])
    return (
        <Box sx={{ padding: '5px' }}>
            <CustomTypoParent>
                <CustomTypography>
                    profit loss
                </CustomTypography>
            </CustomTypoParent>

            <Grid container sx={{ margin: '10px 0px 10px' }} gap={{ md: 2.8, xs: 1 }} >
                <Grid item xs={5.8} md={5.9} >
                    <CustomTextFieldLeft value={startDate} onChange={handlerStartDate} type='date' fullWidth size="small" InputLabelProps={{ sx: { color: "#000 !important" } }} />
                </Grid>
                <Grid item xs={5.8} md={5.9} >
                    <CustomTextFieldLeft value={endDate} onChange={handlerEndDate} type='date' fullWidth size="small" InputLabelProps={{ sx: { color: "#000 !important" } }} />
                </Grid>
            </Grid>

            <CustomButton onClick={() => trigger({ startDate, endDate })}>
                submit
            </CustomButton>

            <Box sx={{ margin: '10px auto' }}>
                {pnldata?.data?.map((profitlossdata, index) => (
                    <Paper elevation={6} key={index} sx={{ borderRadius: 0, padding: '5px', margin: '2px auto' }}>
                        <Grid container >
                            <Grid item xs={6}>
                                <Typography component='h5' sx={{
                                    fontSize: '12px',
                                    textAlign: 'left',
                                    color: '#007bff',
                                    fontWeight: 600
                                }}>
                                    {`${profitlossdata?.matchname} - ${profitlossdata?.createdon}`}
                                </Typography>

                                <Typography component='p' sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    <Typography component='p' sx={{ fontWeight: 700, textTransform: 'capitalize', fontSize: '12px', color: '#000' }}>
                                        nation:
                                    </Typography>

                                    <Typography component='span' sx={{ fontWeight: 500, marginLeft: '5px', textTransform: 'capitalize', fontSize: '12px' }}>
                                        {`${profitlossdata?.sportname}`}
                                    </Typography>
                                </Typography>

                                <Typography component='p' sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    <Typography component='p' sx={{ fontWeight: 700, textTransform: 'capitalize', fontSize: '12px', color: '#000' }}>
                                        commission:
                                    </Typography>

                                    <Typography component='span' sx={{ fontWeight: 500, marginLeft: '5px', textTransform: 'capitalize', fontSize: '12px' }}>
                                        {postNegValue(profitlossdata?.total_commission)}
                                    </Typography>
                                </Typography>
                                <Typography component='p' sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    <Typography component='p' sx={{ fontWeight: 700, textTransform: 'capitalize', fontSize: '12px', color: '#000' }}>
                                        total:
                                    </Typography>

                                    <Typography component='span' sx={{ fontWeight: 500, marginLeft: '5px', textTransform: 'capitalize', fontSize: '12px' }}>
                                        {postNegValue(profitlossdata?.total_amount)}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-start' }}>
                                    <Typography component='p' sx={{ fontWeight: 700, textTransform: 'capitalize', fontSize: '12px', color: '#000' }}>
                                        amount
                                    </Typography>
                                    <Typography component='p' sx={{ fontWeight: 500, marginLeft: '5px', textTransform: 'capitalize', fontSize: '12px' }}>
                                        {postNegValue(profitlossdata?.total_amount)}
                                    </Typography>
                                    <Typography component='p' sx={{ fontWeight: 500, marginLeft: '5px', textTransform: 'capitalize', fontSize: '12px' }}>
                                        {profitlossdata?.createdon}
                                    </Typography>
                                </Box>

                            </Grid>
                        </Grid>
                    </Paper>
                ))}
            </Box>
        </Box>
    )
}

export default ProfitAndLoss