import styled from '@mui/material/styles/styled'

export const CustomMenu = styled('div')({
  backgroundColor: '#fff',
  color: '#323232',
  position: 'absolute',
  zIndex: '100!important',
  right: '5px',
  borderRadius: '3px',
  // boxShadow: '0 6px 12px rgba(44,62,80,.175)',
  padding: '8px 0',
  border: '1px solid rgba(0,0,0,.15)'
})
