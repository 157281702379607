import Button from '@mui/material/Button'
import { FC } from 'react'
interface props {
  stackbtn: number
  handlerOnSelect: (e: any) => void
}
const StackButton: FC<props> = ({ stackbtn, handlerOnSelect }) => {
  return (
    <>
      <Button
        defaultValue={stackbtn}
        onClick={() => handlerOnSelect(stackbtn)}
        variant="contained"
        sx={{
          width: '100%',
          height: '29px',
          padding: { md: '4px 40px', xs: '4px 20px' },
          borderRadius: 0,
        }}
      >
        {stackbtn}
      </Button>
    </>
  )
}

export default StackButton
