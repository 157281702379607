import { Button, Input, InputLabel } from '@mui/material'
import styled from '@mui/material/styles/styled'
import { theme } from '../../theme'

export const CustomInputLabel = styled(InputLabel)({
  textTransform: 'capitalize',
  width: '100%',
  textAlign: 'left',
  fontSize: '12px',
  color: theme.palette.dark.main,
  fontFamily: 'Roboto Condensed',
})

export const CustomInput = styled(Input)({
  borderBottom: '1px solid #0088cc',
  width: '100%',
  textAlign: 'left',
  color: theme.palette.inputtextcolor.main,
  fontSize: '1rem',
})

export const ChangePasswordBtn = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.dark.main,
    color: theme.palette.secondary.main,
    opacity: 0.7,
  },
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.dark.main,
  marginTop: '20px',
  borderRadius: '0',
  width: '100%',
  fontSize: '14px',
  padding: '8px 40px',
  textTransform: 'capitalize',
}))
